import { string } from 'yup';

export const MAX_REPORT_NAME_LENGTH = 254;

export const schema = string()
  .trim()
  .transform((value: string, originalValue: string) => originalValue.slice(0, MAX_REPORT_NAME_LENGTH))
  .min(1)
  .max(MAX_REPORT_NAME_LENGTH)
  .required('Survey name is required');
