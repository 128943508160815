import React, { FC, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import { Button } from 'common/components/_legacy/Button';
import { NavigationBar } from 'common/layout';
import { Answer, QuestionDefinition } from 'domain/question';
import { useQuestionsForm } from 'modules/question/hooks';

import { SurveyContent } from './components';

type Props = {
  name: string;
  questions: QuestionDefinition[];
  // TODO Populate answers to the form
  answers?: Answer[];
  onSubmit: Parameters<typeof useQuestionsForm>[0]['onSubmit'];
  isSubmitting?: boolean;
  onExit?(): void;
  isPreview?: boolean;
};

export const SurveyStepper: FC<Props> = ({ name, questions, onSubmit, onExit, isSubmitting, isPreview }) => {
  const { methods, handleSubmit } = useQuestionsForm({
    questions,
    onSubmit,
  });

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <FormProvider {...methods}>
      <NavigationBar
        title={name}
        withActionsDivider={!isPreview}
        exitLabel={isPreview ? 'Close' : 'Exit'}
        onExitClick={onExit}
        extraButtons={
          !isPreview && (
            <Button
              onClick={handleSubmit}
              disabled={activeStep !== questions.length - 1 || !methods.formState.isValid}
              isLoading={isSubmitting}
            >
              Submit
            </Button>
          )
        }
      />

      <SurveyContent
        title={name}
        activeStep={activeStep}
        questions={questions}
        onBackClick={handleBack}
        onNextClick={handleNext}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </FormProvider>
  );
};
