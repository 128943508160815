import React, { FC } from 'react';

import { Box } from '@mui/material';
import {
  QuestionDefinition,
  isMultipleChoiceMultipleSelectQuestion,
  isMultipleChoiceSingleSelectQuestion,
  isNPSQuestion,
  isRankingQuestion,
  isSliderQuestion,
  isTextQuestion,
} from 'domain/question';

import {
  MultipleChoiceMultipleSelectAnswer,
  MultipleChoiceSingleSelectAnswer,
  NPSAnswer,
  RankingAnswer,
  SliderAnswer,
  TextAnswer,
} from '../answers';
import { QuestionAnswerWrapper } from './QuestionAnswer.styled';

export const QuestionAnswer: FC<{
  name: string;
  questionDefinition: QuestionDefinition;
}> = ({ name, questionDefinition: { question, questionAssignment } }) => (
  <QuestionAnswerWrapper key={question.questionId} data-testid={`questions-form-question-${question.questionId}`}>
    <Box width={1}>
      {isTextQuestion(question) && (
        <TextAnswer name={name} question={question} isObligatory={questionAssignment.isObligatory} />
      )}
      {isMultipleChoiceSingleSelectQuestion(question) && (
        <MultipleChoiceSingleSelectAnswer
          name={name}
          isObligatory={questionAssignment.isObligatory}
          question={question}
        />
      )}
      {isMultipleChoiceMultipleSelectQuestion(question) && (
        <MultipleChoiceMultipleSelectAnswer
          name={name}
          question={question}
          isObligatory={questionAssignment.isObligatory}
        />
      )}
      {isRankingQuestion(question) && (
        <RankingAnswer name={name} question={question} isObligatory={questionAssignment.isObligatory} />
      )}
      {isNPSQuestion(question) && (
        <NPSAnswer name={name} question={question} isObligatory={questionAssignment.isObligatory} />
      )}
      {isSliderQuestion(question) && (
        <SliderAnswer name={name} question={question} isObligatory={questionAssignment.isObligatory} />
      )}
    </Box>
  </QuestionAnswerWrapper>
);
