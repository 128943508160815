import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { type Components, type Theme } from '@mui/material';

export const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    IconComponent: ExpandMoreOutlinedIcon,
  },
  styleOverrides: {
    root: ({ theme, ownerState: { displayEmpty, value } }) => ({
      maxHeight: 48,
      padding: 0,
      paddingLeft: theme.spacing(2),
      gap: theme.spacing(1),
      boxSizing: 'border-box',
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.grey[400],
      },
      '& .MuiSelect-icon': {
        color: theme.palette.grey[600],
      },
      '&.MuiInputBase-sizeSmall': {
        height: 32,
        minHeight: 32,
        '& .MuiSelect-icon': {
          width: 20,
          height: 20,
          top: 'unset',
        },
      },
      '& .MuiSelect-select': {
        paddingTop: '14px',
        paddingBottom: '14px',
        paddingLeft: 0,
        '&[aria-expanded="true"]': {
          color: theme.palette.grey[400],
        },

        ...(displayEmpty &&
          !value && {
            color: theme.palette.grey[400],
          }),
      },
    }),
  },
};
