import React, { FC } from 'react';

import { useAppDispatch, useAppSelector } from 'store';
import { addAppAlertDialog } from 'store/features/alerts';
import { eventDetailsActions, selectActiveEventId } from 'store/features/event';

import { EventRepeatButton, EventRepeatButtonProps } from './EventRepeatButton';

type Props = Pick<EventRepeatButtonProps, 'variant'>;

export const EventRepeatButtonContainer: FC<Props> = ({ variant = 'icon' }) => {
  const activeEventId = useAppSelector(selectActiveEventId);
  const dispatch = useAppDispatch();

  if (!activeEventId) return null;

  const handleRepeat = () => {
    dispatch(
      addAppAlertDialog({
        title: 'Change Your Event Setup',
        description:
          'If you repeat an Event and change the original setup, you won’t be able to create a merged Report with multiple Events, as they can only be merged if they have the same setup.',
        confirmLabel: 'Understood',
        cancelLabel: 'Close',
        showCancelButton: true,
        actionToDispatchOnConfirm: eventDetailsActions.repeatDialog.confirmButton.clicked(),
      })
    );
  };

  return <EventRepeatButton variant={variant} onRepeat={handleRepeat} />;
};
