import React, { FC } from 'react';

import type { Tag } from 'domain/Common';

import { MemberTagItem } from './MemberTag.styled';

export type MemberTagProps = {
  tag: Tag;
  onSelect(tag: Tag): void;
};

export const MemberTag: FC<MemberTagProps> = ({ tag, onSelect: handleSelect }) => (
  <MemberTagItem
    label={tag.name}
    variant={tag.isSelected ? 'filled' : 'outlined'}
    onClick={() => handleSelect(tag)}
    data-testid={`MemberTag-${tag.id}`}
  />
);
