import React, { FC } from 'react';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { Button, type ButtonProps } from '../Button';

type AddButtonProps = Pick<ButtonProps, 'onClick'>;

export const AddButton: FC<AddButtonProps> = (props) => (
  <Button
    variant="light"
    size="large"
    startIcon={<AddCircleOutlineIcon />}
    sx={{ marginTop: 1 }}
    fullWidth
    {...props}
  />
);
