import { QuestionVariants } from 'domain/question';

export const ANSWERS_LIBRARY = [
  {
    name: 'Agreement',
    values: ['Strongly agree', 'Agree', 'Neutral', 'Disagree', 'Strongly disagree'],
  },
  {
    name: 'Satisfaction',
    values: ['Very satisfied', 'Satisfied', 'Neutral', 'Dissatisfied', 'Very dissatisfied'],
  },
  {
    name: 'Engagement',
    values: ['Very engaged', 'Engaged', 'Neutral', 'Disengaged', 'Very disengaged'],
  },
  {
    name: 'Approval',
    values: ['Strongly approve', 'Approve', 'Neutral', 'Disapprove', 'Strongly disapprove'],
  },
  {
    name: 'Familiarity',
    values: ['Extremely familiar', 'Familiar', 'Somewhat familiar', 'Unfamiliar', 'Not at all familiar'],
  },
  {
    name: 'Value',
    values: ['Extremely valuable', 'Valuable', 'Somewhat valuable', 'Not so valuable', 'Not at all valuable'],
  },
  {
    name: 'Rating: 5 > 1',
    values: ['5', '4', '3', '2', '1'],
  },
  { name: 'Yes > No', values: ['Yes', 'Maybe', 'No'] },
  {
    name: 'Helpful > Not helpful',
    values: ['Extremely helpful', 'Very helpful', 'Somewhat helpful', 'Not so helpful', 'Not at all helpful'],
  },
  {
    name: 'Above average > Below average',
    values: ['Far above average', 'Above average', 'Average', 'Below average', 'Far below average'],
  },
  { name: 'Better > Worse', values: ['Much better', 'Better', 'About the same', 'Worse', 'Much worse'] },
  { name: 'Often > Rarely', values: ['Extremely often', 'Often', 'Sometimes', 'Rarely', 'Very rarely'] },
  { name: 'Always > Never', values: ['Always', 'Usually', 'Sometimes', 'Rarely', 'Never'] },
  { name: 'Daily > Yearly', values: ['Daily', 'Weekly', 'Monthly', 'Yearly', 'Never'] },
] as const;

export const QUESTION_TYPE_LABEL_MAP: Record<QuestionVariants, string> = {
  [QuestionVariants.TextQuestion]: 'Free Text',
  [QuestionVariants.RankingQuestion]: 'Ranking',
  [QuestionVariants.MultipleChoiceSingleSelectQuestion]: 'Single Choice',
  [QuestionVariants.MultipleChoiceMultipleSelectQuestion]: 'Multiple Choice',
  [QuestionVariants.NPSQuestion]: 'Net Promoter Score (NPS)',
  [QuestionVariants.SliderQuestion]: 'Slider',
} as const;

export const REQUIRED_QUESTION_MESSAGE = 'This is a required question.';
