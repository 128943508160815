import React, { useCallback, useState } from 'react';

import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Stack } from '@mui/material';
import { dataToCsvFile } from 'common/utils';
import { has } from 'lodash';

import { Button } from '../Buttons';
import { FormFieldHint } from '../Inputs';
import { UploadDropzone } from '../UploadDropzone';
import { useParseCSV } from './hooks';

export type Props<Result = unknown> = {
  onFileSelect?: (file: File) => void;
  onFileLoaded: (data: Result[]) => void;
  onFileCompressionError?: (error: unknown) => void;
  template: string;
  templateFileName: string;
  requiredFields?: Array<keyof Result>;
};

export const CsvDropzone = <Result,>({
  onFileCompressionError,
  onFileLoaded,
  onFileSelect,
  template,
  templateFileName,
  requiredFields = [],
}: Props<Result>) => {
  const [error, setError] = useState<string | null>(null);
  const filterValidFields = (row: Result) => requiredFields.every((field) => has(row, field));

  const { parseFile } = useParseCSV<Result>({
    onCompleteUpload: ({ data }) => {
      const validFields = data.filter(filterValidFields);
      onFileLoaded(validFields);

      if (data.length !== validFields.length) {
        setError('An error occurred while parsing the file');
      }
    },
    onError: (e: unknown) => {
      onFileCompressionError?.(e);
    },
  });

  const handleDownloadTemplateClick = () => {
    dataToCsvFile(template, templateFileName);
  };

  const handleFileSelect = useCallback(
    (file: File) => {
      setError(null);
      parseFile(file);
      onFileSelect?.(file);
    },
    [onFileSelect, parseFile]
  );

  return (
    <Stack gap={1}>
      <UploadDropzone type="csv" onFileSelect={handleFileSelect} />

      <Stack direction="row" justifyContent="space-between">
        {error ? <FormFieldHint error>{error}</FormFieldHint> : <div />}

        <Button
          size="small"
          variant="text"
          color="primary"
          onClick={handleDownloadTemplateClick}
          endIcon={<FileDownloadOutlinedIcon fontSize="small" />}
        >
          Download CSV template
        </Button>
      </Stack>
    </Stack>
  );
};
