import { ERROR_MESSAGES } from 'common/constants';
import { InferType, array, boolean, number, object, string } from 'yup';

export const themeSchema = object({
  isSelected: boolean(),
  theme: string(),
  themeId: number().required(),
});

export const mergeThemesFormSchema = object({
  mergedThemesName: string().required(ERROR_MESSAGES.noEmpty),
  themes: array()
    .of(themeSchema)
    .default([])
    .compact(({ isSelected }) => !isSelected)
    .min(2),
});

export type ThemeFormField = InferType<typeof themeSchema>;
export type MergeThemesFormValues = InferType<typeof mergeThemesFormSchema>;
