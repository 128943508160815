import { useGetEventDetailsQuery } from 'store/apis/event';

import { useActiveEventParams } from '../useActiveEventParams/useActiveEventParams';

/**
 * Retrieves active event details based on the parameters obtained from the URL.
 *
 * @description
 * This function should be called in scenarios where event details are needed,
 * especially for authorized users. It internally uses `useActiveEventParams` to obtain
 * the necessary parameters from the URL and makes API calls to fetch event details.
 */
export const useActiveEventDetails = (forceRefetch = false) => {
  const { id } = useActiveEventParams();
  const event = useGetEventDetailsQuery({ id: id! }, { skip: !id, refetchOnMountOrArgChange: forceRefetch });

  return { id: id!, event };
};
