import { ButtonBase, Stack, styled } from '@mui/material';
import { red } from 'common/theme/palette';
import { omitForwardedProps } from 'common/utils';

export const UploadPreviewContainer = styled(Stack, { shouldForwardProp: omitForwardedProps('color') })<{
  color?: 'error';
}>(({ color, theme: { palette, spacing } }) => ({
  backgroundColor: palette.grey[50],
  borderRadius: spacing(1.5),
  border: `1px solid ${palette.divider}`,
  color: palette.text.primary,
  padding: spacing(2),

  ...(color === 'error' && {
    backgroundColor: red[50],
    borderColor: palette.error.main,
    color: red[700],

    '.MuiIconButton-root': {
      color: red[700],
    },
  }),
}));

export const TryAgainButton = styled(ButtonBase)({
  color: red[700],
  fontWeight: 600,
});
