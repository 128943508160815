import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import { PinGuard, PinGuardProps } from 'common/guards';
import { useQueryParams } from 'common/hooks';
import { NavigationBar } from 'common/layout';
import { InsightsReportHeader } from 'pages/InsightsReport/components';
import { useValidateReportPinMutation } from 'store/apis/insightsReport';

type Props = Pick<PinGuardProps, 'children'>;

export const InsightsReportPinGuard: FC<Props> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { query } = useQueryParams<{ name: string }>();
  const { name = '' } = query;

  const [validatePin, { data: validPin, isLoading, isSuccess, reset, isUninitialized }] =
    useValidateReportPinMutation();

  const isPinInvalid = isSuccess && !validPin;

  const handleSubmit = (pin: string) => validatePin({ reportId: Number(id), pin });

  const handleChange = () => {
    if (!isUninitialized) {
      reset();
    }
  };

  return (
    <>
      {!validPin && <NavigationBar secondRowChildren={name && <InsightsReportHeader isPublic reportName={name} />} />}
      <PinGuard
        title="Enter PIN to view the Report"
        description="Enter the PIN that was shared with you in order to access this Report"
        onSubmit={handleSubmit}
        validPin={validPin}
        isSubmitting={isLoading}
        isPinInvalid={isPinInvalid}
        onChange={handleChange}
      >
        {children}
      </PinGuard>
    </>
  );
};
