import { Box, Stack, Typography, styled } from '@mui/material';
import { shadows } from 'common/theme';

export const ChartCardWrapper = styled(Stack)(({ theme }) => ({
  boxShadow: shadows.light,
  borderRadius: '4px',
  gap: theme.spacing(1),
}));
ChartCardWrapper.defaultProps = {
  className: 'ChartCardWrapper',
};

export const ChartCardHeader = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(3),
  padding: theme.spacing(3),
}));

export const ChartCardHeaderRightSectionId = 'ChartCardHeaderRightSection';
export const ChartCardHeaderRightSection = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
}));
ChartCardHeaderRightSection.defaultProps = {
  id: ChartCardHeaderRightSectionId,
};

export const ChartCardTitle = styled(Typography)({});
ChartCardTitle.defaultProps = {
  variant: 'natter-text-lg',
  noWrap: true,
};

export const ChartCardChartWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(0, 3, 3, 3),
}));
