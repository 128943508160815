import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import type { InsightsReportKeyMetric } from 'pages/InsightsReport/types';

export const surveyKeyMetrics: InsightsReportKeyMetric[] = [
  {
    key: 'participants',
    title: 'Invited Participants',
    icon: Groups2OutlinedIcon,
  },
  {
    key: 'surveysCompleted',
    title: 'Surveys Completed',
    icon: VerifiedOutlinedIcon,
  },
  {
    key: 'surveysStarted',
    title: 'Surveys Started',
    icon: ArticleOutlinedIcon,
  },
  {
    key: 'dataPoints',
    title: 'Data Points',
    icon: BarChartRoundedIcon,
  },
  {
    key: 'completionRate',
    title: 'Completion Rate',
    icon: TimelineOutlinedIcon,
    isPercentage: true,
    tooltip: '# of Surveys completed / # of Surveys started',
  },
  {
    key: 'responseRate',
    title: 'Response Rate',
    icon: QueryStatsOutlinedIcon,
    isPercentage: true,
    tooltip: '# of Surveys completed / # of Participants invited',
  },
];
