import React, { FC } from 'react';

import { Stack } from '@mui/material';
import { QuestionDefinition } from 'domain/question';

import { QuestionAnswer } from './QuestionAnswer';

export const QuestionsForm: FC<{
  questions: QuestionDefinition[];
}> = ({ questions }) => (
  <Stack gap={3}>
    {questions.map((questionDefinition, index) => (
      <QuestionAnswer
        key={questionDefinition.question.questionId}
        name={`answers.${index}`}
        questionDefinition={questionDefinition}
      />
    ))}
  </Stack>
);
