import React, { FC, useMemo } from 'react';

import { debounce } from 'lodash/fp';

import { SearchInput, SearchInputProps } from './SearchInput';

const SEARCH_INPUT_DEBOUNCE_TIME = 500;

export const SearchInputDebounced: FC<SearchInputProps> = ({ onChange, ...props }) => {
  const onChangeDebounced = useMemo(() => debounce(SEARCH_INPUT_DEBOUNCE_TIME)(onChange), [onChange]);

  return <SearchInput {...props} onChange={onChangeDebounced} />;
};
