import { makeStyles } from '@mui/styles';

export const useChartStyles = makeStyles(
  ({ palette, typography }) => {
    const chart = {
      '& .recharts-legend-item-text': {
        color: `${palette.text.primary} !important`,
        lineHeight: '16px',
        fontSize: '11px',
        fontFamily: typography.fontFamily,
      },
    };

    return {
      chart,
      verticalChart: {
        ...chart,
        '& .recharts-cartesian-grid-horizontal line:last-of-type': {
          display: 'none',
        },
      },
    };
  },
  {
    name: 'chartStyles',
  }
);
