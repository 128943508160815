import React, { FC } from 'react';

import type { EventQuestionDefinition } from 'domain/event';
import type { Answer } from 'domain/question';
import { AsyncSurvey, AsyncSurveyStates } from 'store/apis/survey';

import { SurveySkeleton } from './SurveySkeleton';
import { SurveyStatePage } from './SurveyStatePage';

type Props = {
  id: number | undefined;
  survey: AsyncSurvey | undefined;
  questions?: EventQuestionDefinition[] | undefined;
  answers?: Answer[] | undefined;
  isLoading: boolean;
  isError: boolean;
  isCompleted: boolean;
};

export const SurveyGuard: FC<Props> = ({ id, survey, isLoading, isCompleted, isError, children }) => {
  if (isLoading) return <SurveySkeleton />;

  if (!id) {
    return (
      <SurveyStatePage
        variant="error"
        title="Survey Not Found"
        description="We’re sorry, but the Survey you’re trying to access does not exist."
      />
    );
  }

  if (isError || survey?.expired) {
    return (
      <SurveyStatePage
        variant="error"
        title="Survey Unavailable"
        description="We’re sorry, but the Survey you’re trying to access is currently unavailable."
      />
    );
  }

  if (survey?.state === AsyncSurveyStates.Unpublished) {
    return (
      <SurveyStatePage
        variant="error"
        title="This Survey has been unpublished"
        description="This Survey is not available anymore as it was unpublished."
      />
    );
  }

  if (isCompleted) {
    return (
      <SurveyStatePage
        variant="success"
        title="Thank You!"
        description="Your survey has been successfully submitted. We appreciate your valuable feedback."
      />
    );
  }

  return <>{children}</>;
};
