import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { useAppSelector } from 'store';
import { useGetSurveyReportAnswersQuery } from 'store/apis/surveyReport';
import { selectDemographicFilters } from 'store/features/insightsReports';

export const useCurrentSurveyReportAnswers = () => {
  const { reportId, isInvalidReportId } = useCurrentReportId();
  const demographicFilters = useAppSelector(selectDemographicFilters);

  const { data } = useGetSurveyReportAnswersQuery(
    { reportId, communityTagIds: demographicFilters },
    { skip: isInvalidReportId }
  );

  return {
    data,
  };
};
