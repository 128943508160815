import React, { FC } from 'react';

import { TooltipProps } from 'recharts';

import { TooltipContent as TooltipContentType } from '../../types';
import { TooltipWrapper } from './TooltipContentWrapper.styled';

type HorizontalBarChartTooltipProps = TooltipProps<number, string> & {
  activeDataIndex?: number;
  TooltipContent?: TooltipContentType;
  shouldDisplay?(currentDataPointPayload: CurrentDataPointPayload): boolean;
};

type CurrentDataPointPayload = { name: string; fill: string; x0: number; x1: number };
type TooltipPayload = { fill: string; value: number; dataKey: string; payload: CurrentDataPointPayload };

export const TooltipContentWrapper: FC<HorizontalBarChartTooltipProps> = ({
  payload,
  activeDataIndex = 0,
  TooltipContent,
  shouldDisplay,
}) => {
  const {
    fill,
    value,
    dataKey,
    payload: currentDataPointPayload = {} as CurrentDataPointPayload,
  } = (payload?.[activeDataIndex] || {}) as TooltipPayload;
  const { name, fill: currentDataPointFill, x0, x1 } = currentDataPointPayload;
  const isDisplayed = shouldDisplay ? shouldDisplay(currentDataPointPayload) : true;
  const showTooltip = name && isDisplayed;

  return (
    <TooltipWrapper backgroundColor={fill ?? currentDataPointFill} visibility={showTooltip ? 'visible' : 'hidden'}>
      {TooltipContent ? <TooltipContent value={value} name={name} dataKey={dataKey} range={[x0, x1]} /> : value}
    </TooltipWrapper>
  );
};
