import { EventQuestionAssignmentType } from 'domain/event';
import { BasicInsightsReportRoutes } from 'pages/InsightsReport/constants';

import { createEventSurveyInsightsReportPage } from '../utils';

export const EventReportRoutes = [
  BasicInsightsReportRoutes.keyMetricsAndParticipantDemographics,
  {
    title: 'Entry Survey Insights',
    path: '/entry-survey-insights',
    render: createEventSurveyInsightsReportPage(EventQuestionAssignmentType.PreEvent, 'Entry Survey'),
  },
  {
    title: 'Natter Insights',
    path: '/natter-insights',
    render: createEventSurveyInsightsReportPage(EventQuestionAssignmentType.Room, 'Natter'),
  },
  {
    title: 'Post-Natter Survey Insights',
    path: '/post-natter-survey-insights',
    render: createEventSurveyInsightsReportPage(EventQuestionAssignmentType.EndOfRoom, 'Post Natter Survey'),
  },
  {
    title: 'Exit Survey Insights',
    path: '/exit-survey-insights',
    render: createEventSurveyInsightsReportPage(EventQuestionAssignmentType.EndOfEvent, 'Exit Survey'),
  },
];
