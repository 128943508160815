import React, { FC, useState } from 'react';

import { Button, ShareDialog, ShareIcon } from 'common/components';
import { generateDynamicPath } from 'common/utils';
import { InsightsReportType } from 'pages/InsightsReport/types';
import { AppRoutes } from 'router';
import { InsightsReportData } from 'store/apis/insightsReport';

const reportTypeToPublicUrlMap: Record<InsightsReportType, string> = {
  Event: AppRoutes.PublicEventReport,
  Survey: AppRoutes.PublicSurveyReport,
  Natter: '',
};

export const ShareInsightsReport: FC<{
  reportType: InsightsReportType;
  reportData: InsightsReportData;
}> = ({ reportType, reportData }) => {
  const [showModal, setShowModal] = useState(false);
  const url = generateDynamicPath(
    reportTypeToPublicUrlMap[reportType],
    { id: reportData.id },
    { name: reportData.name },
    { isAbsolute: true }
  );

  return (
    <>
      <Button startIcon={<ShareIcon />} onClick={() => setShowModal(true)}>
        Share
      </Button>
      <ShareDialog
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        title="Share Report"
        urlName="Report link"
        urlDescription="Share this unique URL link and PIN to people who you want to have access to this report."
        pinName="Report PIN"
        url={url}
        pin={reportData.pin}
      />
    </>
  );
};
