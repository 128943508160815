import React, { FC } from 'react';

import { StatusIndicator } from 'common/components/StatusIndicator';
import { EventStatus } from 'domain/event';

import { EVENT_STATE_MAP } from './constants';

type Props = { status: EventStatus };

export const EventStatusBadge: FC<Props> = ({ status }) => (
  <StatusIndicator
    value={status}
    colorMapping={{
      [EventStatus.Published]: 'new',
      [EventStatus.Started]: 'success',
      [EventStatus.Finished]: 'removed',
      [EventStatus.Cancelled]: 'default',
    }}
  >
    {EVENT_STATE_MAP[status]}
  </StatusIndicator>
);
