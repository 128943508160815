import { makeStyles } from '@mui/styles';

export const useTimeDateInputStyles = makeStyles(
  (theme) => ({
    flexContainer: {
      borderBottom: '2px solid #e8e8e8',
      boxSizing: 'border-box',
    },
    adornment: {
      color: theme.palette.primary.main,
    },
    slider: {
      color: theme.palette.primary.main,
    },
    durationLabel: {
      marginTop: 26,
    },
    dateTimeRoot: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
    },
  }),
  { name: 'timeDateInput' }
);
