import React, { FC, ReactElement } from 'react';

import { Container, Stack } from '@mui/material';
import { useBreakpoints } from 'common/hooks';
import { EventDetails, EventOwnerBase } from 'domain/event';

import { EventPreviewHeader, EventPreviewTabs } from './components';

type Props = Pick<EventDetails, 'name' | 'eventTime' | 'eventImage' | 'description' | 'sponsors' | 'status'> & {
  owner: EventOwnerBase;
  actionButton: ReactElement;
};

export const EventPreview: FC<Props> = ({ actionButton, children, ...eventDetails }) => {
  const { isSmallScreen } = useBreakpoints();

  return (
    <Container maxWidth={false} sx={{ maxWidth: 640 }} disableGutters={isSmallScreen}>
      {children}
      <Stack gap={3}>
        <EventPreviewHeader eventDetails={eventDetails} />
        {!isSmallScreen && actionButton}
        <EventPreviewTabs eventDetails={eventDetails} />
        {isSmallScreen && actionButton}
      </Stack>
    </Container>
  );
};
