import { styled } from '@mui/material';

export const KeyMetricsGrid = styled('div')(({ theme: { spacing, breakpoints } }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: spacing(2),

  [breakpoints.down('lg')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },

  [breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(1, 1fr)',
  },
}));
