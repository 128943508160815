import React, { FC } from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { AlertSeverity } from 'store/features/alerts';

export type SnackbarIconProps = {
  severity: AlertSeverity;
};

export const SnackbarIcon: FC<SnackbarIconProps> = ({ severity }) => {
  switch (severity) {
    case 'success':
      return <CheckCircleIcon fontSize="small" color="success" />;
    case 'error':
      return <ErrorIcon fontSize="small" color="error" />;
    default:
      return null;
  }
};
