import { createSelector } from '@reduxjs/toolkit';
import { TagsCategory } from 'domain/Common';
import type { RootState } from 'store';
import { selectActiveCommunityId } from 'store/features/community';

import { communityApi } from './communityApi';

const emptyArray: unknown[] = [];

const selectRootState = (state: RootState) => state;

// My Community
export const selectMyCommunities = communityApi.endpoints.getMyCommunities.select();
export const selectMyCommunity = createSelector(
  selectActiveCommunityId,
  selectMyCommunities,
  (activeCommunityId, myCommunityDataResult) =>
    activeCommunityId ? myCommunityDataResult?.data?.myCommunities.find((c) => c.id === activeCommunityId) : undefined
);

export const selectMyCommunityTagsCategories = createSelector(
  selectMyCommunity,
  (myCommunity) => myCommunity?.tagsCategories ?? (emptyArray as TagsCategory[])
);
export const selectMyCommunityBrandingColor = createSelector(
  selectMyCommunity,
  (myCommunity) => myCommunity?.brandingColor
);
export const selectMyCommunityId = createSelector(selectMyCommunity, (myCommunity) => myCommunity?.id ?? undefined);
export const selectMyCommunityHash = createSelector(selectMyCommunity, (myCommunity) => myCommunity?.hash ?? undefined);
export const selectMyCommunityName = createSelector(selectMyCommunity, (myCommunity) => myCommunity?.name ?? undefined);

export const selectHasNoCommunity = createSelector(
  selectMyCommunities,
  (myCommunityDataResult) => !!myCommunityDataResult?.data?.hasNoCommunity
);
export const selectMyCommunityIsLoading = createSelector(
  selectMyCommunities,
  (myCommunityDataResult) => !!myCommunityDataResult?.isLoading
);
export const selectMyCommunityIsError = createSelector(
  selectMyCommunities,
  (myCommunityDataResult) => !!myCommunityDataResult?.isError
);
export const selectMyCommunitiesIds = createSelector(selectMyCommunities, (myCommunityDataResult) =>
  myCommunityDataResult?.data?.myCommunities.map((c) => c.id)
);

// Community Member
export const selectCommunityMemberByCommunityId = (state: RootState, id: number | undefined) =>
  communityApi.endpoints.getCurrentCommunityMember.select({ communityId: id })(state)?.data;

export const selectCurrentCommunityMember = createSelector(selectRootState, selectMyCommunityId, (state, id) =>
  selectCommunityMemberByCommunityId(state, id)
);

export const selectCurrentCommunityMemberIsLoading = createSelector(
  selectRootState,
  selectMyCommunityId,
  (state, id) => communityApi.endpoints.getCurrentCommunityMember.select({ communityId: id })(state).isLoading
);

export const selectCurrentCommunityMemberTags = createSelector(
  selectCurrentCommunityMember,
  (member) => member?.tagsCategories ?? (emptyArray as TagsCategory[])
);
export const selectCurrentCommunityMemberSocials = createSelector(
  selectRootState,
  selectMyCommunityId,
  (state, id) => selectCommunityMemberByCommunityId(state, id)?.socials ?? {}
);
export const selectAvailableTagsCategories = (state: RootState) =>
  communityApi.endpoints.getAvailableTagsCategories.select()(state).data ?? (emptyArray as TagsCategory[]);

export const selectCommunityMemberConfigurationInfo = createSelector(
  selectMyCommunity,
  selectCurrentCommunityMember,
  selectCurrentCommunityMemberIsLoading,
  (myCommunity, communityMember, isLoading) => {
    const isRegistered = !!communityMember;
    const isCronofyConfigured = !myCommunity?.calendarPermissionsRequired || communityMember?.hasCronofyCredentials;
    const isRoleConfigured = !!communityMember?.role;
    const isTagsConfigured =
      !!communityMember?.tagsCategories?.length &&
      !communityMember.tagsCategories.some((c) => c.isSingleSelection && c.tags.length === 0);
    const isAccountConfigured = !!(
      communityMember &&
      communityMember.displayName &&
      isRoleConfigured &&
      isTagsConfigured &&
      isCronofyConfigured
    );

    return {
      isRoleConfigured,
      isTagsConfigured,
      isCronofyConfigured,
      isAccountConfigured,
      isRegistered,
      isLoading,
    };
  }
);
