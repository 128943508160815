import type { InsightsReportKeyMetric } from 'pages/InsightsReport/types';

export const getDisplayValue = (metric: InsightsReportKeyMetric) => {
  if (metric.value === undefined) return '-';
  if (metric.isPercentage) return `${Math.round(metric.value * 100)}%`;

  const displayValue = metric.value.toLocaleString('en-US', { maximumFractionDigits: 2 });

  if (metric.maxValue) {
    return [displayValue, metric.maxValue].join('/');
  }

  return displayValue;
};
