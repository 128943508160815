import { createContext, useContext } from 'react';

import { assert } from 'common/utils';

import { InsightsReportContextValue } from '../types';

export const InsightsReportContext = createContext<InsightsReportContextValue>({
  isError: false,
  isLoading: false,
  demographicData: undefined,
  reportData: undefined,
  shouldShowFilters: false,
  routes: [],
  isDeleting: false,
  deleteReport: () => {
    // do nothing
  },
  updateReportName: () => {
    // do nothing
  },
  isKeyMetricsDataLoading: false,
  keyMetricsData: [],
  reportType: 'Event',
});

export const useInsightsReportContext = () => {
  const ctx = useContext(InsightsReportContext);
  assert(ctx, 'useInsightsReportContext must be used inside InsightsReportContextProvider');
  return ctx;
};
