import React, { FC } from 'react';

import { ErrorBoundary } from 'common/components';
import { HorizontalBarChart } from 'common/components/Charts';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { ChartCardProps } from '../../ChartCard';
import {
  ChartCardChartWrapper,
  ChartCardHeader,
  ChartCardHeaderRightSection,
  ChartCardTitle,
  ChartCardWrapper,
} from '../../ChartCard.styled';
import { ChartErrorFallback } from '../ChartErrorFallback';
import { ThemesChartCardActions } from './components';

type ThemesChartCardProps = Omit<ChartCardProps, 'availableChartTypes'> & {
  themes: TextAnswerTheme[];
};

export const ThemesChartCard: FC<ThemesChartCardProps> = ({
  title,
  chartProps,
  hasError = false,
  children,
  themes,
  chartId,
}) => (
  <>
    <ChartCardWrapper>
      <ChartCardHeader>
        <ChartCardTitle title={title}>{title}</ChartCardTitle>
        <ChartCardHeaderRightSection>
          <ThemesChartCardActions chartId={chartId} themes={themes} title={title} />
        </ChartCardHeaderRightSection>
      </ChartCardHeader>
      <ErrorBoundary fallback={<ChartErrorFallback />} hasError={hasError}>
        <ChartCardChartWrapper>
          <HorizontalBarChart {...chartProps}>{children}</HorizontalBarChart>
        </ChartCardChartWrapper>
      </ErrorBoundary>
    </ChartCardWrapper>
  </>
);
