import React, { FC } from 'react';

import { Box } from '@mui/material';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import { PieChartLabel, TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT } from '../../constants';
import { useChartColors, useChartStyles } from '../../hooks';
import { BasicChartProps } from '../../types';

const ANIMATION_DURATION = 800;

export const DonutChart: FC<BasicChartProps> = ({
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
}) => {
  const classes = useChartStyles();
  const { getColor } = useChartColors();

  return (
    <Box position="relative" height={height}>
      <ResponsiveContainer debounce={300} width="100%" height={height}>
        <PieChart className={classes.chart}>
          <Pie
            activeIndex={0}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius="70%"
            outerRadius="100%"
            dataKey={dataKeys[0]}
            stroke="none"
            animationDuration={ANIMATION_DURATION}
            label={(props) => <PieChartLabel {...props} />}
            labelLine={false}
          >
            {data.map((_entry, index) => (
              <Cell key={`cell-${index}`} fill={getColor(index)} />
            ))}
          </Pie>
          <Tooltip
            cursor={false}
            content={<TooltipContentWrapper TooltipContent={TooltipContent} />}
            allowEscapeViewBox={{ x: true, y: true }}
          />
          <Legend
            verticalAlign="middle"
            align="right"
            layout="vertical"
            wrapperStyle={{
              paddingBottom: 16,
              paddingLeft: 16,
              maxWidth: '50%',
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};
