import React, { FC, useMemo, useState } from 'react';

import { MenuItemType } from 'common/components';
import { isFeatureEnabled } from 'common/utils';
import { useInsightsReportContext } from 'pages/InsightsReport/hooks';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { ChartMoreMenu } from '../../../ChartMoreMenu';
import { HideThemesDialog } from '../HideThemes';
import { MergeThemesDialog } from '../MergeThemes';
import { RenameThemesDialog } from '../RenameThemes';
import { UnmergeThemesDialog } from '../UnmergeThemes';
import { useExportThemesDataToCSV } from './useExportThemesDataToCSV';

type Props = {
  chartId: string;
  title: string;
  themes: TextAnswerTheme[];
};

export const ThemesChartCardActions: FC<Props> = ({ chartId, title, themes }) => {
  const { isPublicReport } = useInsightsReportContext();
  const [isMergeThemesOpen, setIsMergeThemesOpen] = useState(false);
  const [isUnmergeThemesOpen, setIsUnmergeThemesOpen] = useState(false);
  const [isRenameThemesOpen, setIsRenameThemesOpen] = useState(false);
  const [isHideThemesOpen, setIsHideThemesOpen] = useState(false);
  const mergedThemes = themes.filter((theme) => theme.isMerged);
  const { exportAsCSV } = useExportThemesDataToCSV(themes);

  const actions = useMemo(() => {
    if (isPublicReport) return [];

    const newActions: MenuItemType[] = [];
    if (isFeatureEnabled('ThemesCSV')) {
      newActions.push({
        text: 'Export as CSV',
        onClick: exportAsCSV,
      });
    }
    if (themes.length > 1 && !isFeatureEnabled('ThemesCSV')) {
      newActions.push({
        text: 'Merge',
        onClick: () => setIsMergeThemesOpen(true),
      });
    }
    if (mergedThemes.length > 0) {
      newActions.push({
        text: 'Unmerge',
        onClick: () => setIsUnmergeThemesOpen(true),
      });
    }
    newActions.push({
      text: 'Rename',
      onClick: () => setIsRenameThemesOpen(true),
    });
    newActions.push({
      text: 'Configure Visibility',
      onClick: () => setIsHideThemesOpen(true),
    });
    return newActions;
  }, [isPublicReport, mergedThemes.length, themes.length, exportAsCSV]);

  return (
    <>
      <ChartMoreMenu chartId={chartId} actions={actions} title={title} />
      <MergeThemesDialog
        isOpen={isMergeThemesOpen}
        onClose={() => setIsMergeThemesOpen(false)}
        onOpen={() => setIsMergeThemesOpen(true)}
        themes={themes}
      />
      <UnmergeThemesDialog
        isOpen={isUnmergeThemesOpen}
        onClose={() => setIsUnmergeThemesOpen(false)}
        themes={mergedThemes}
      />
      <RenameThemesDialog
        isOpen={isRenameThemesOpen}
        onClose={() => setIsRenameThemesOpen(false)}
        onOpen={() => setIsRenameThemesOpen(true)}
        themes={themes}
      />
      <HideThemesDialog
        isOpen={isHideThemesOpen}
        onClose={() => setIsHideThemesOpen(false)}
        onOpen={() => setIsHideThemesOpen(true)}
        themes={themes}
      />
    </>
  );
};
