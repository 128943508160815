import React, { FC } from 'react';

import { Stack } from '@mui/material';
import { Drawer } from 'common/components/Drawer';
import { grey } from 'common/theme/palette';
import { format } from 'date-fns';
import { InsightsReportType } from 'pages/InsightsReport/types';
import { InsightsReportData } from 'store/apis/insightsReport';

import { AssociatedEventDescription, AssociatedEventTitle } from './InsightsReportAssociatedEventsDrawer.styled';

export const InsightsReportAssociatedEventsDrawer: FC<{
  data: InsightsReportData | undefined;
  reportType: InsightsReportType;
  onClose(): void;
}> = ({ data, reportType, onClose }) => (
  <Drawer
    isOpen={!!data}
    onClose={onClose}
    title={data?.name}
    description={`This is the list of ${reportType}(s) used to create this report.`}
  >
    <Stack gap={2}>
      {data?.associatedItems.map(({ id, name, date, participantsCount }) => (
        <Stack key={id} sx={{ backgroundColor: grey[25], borderRadius: '4px' }} px={2} py={1} gap={0.5}>
          <AssociatedEventTitle>{name}</AssociatedEventTitle>
          <Stack direction="row" gap={0.5}>
            <AssociatedEventDescription>
              {date ? format(new Date(date), 'MMM dd, yyyy') : 'Not available'}
            </AssociatedEventDescription>
            <AssociatedEventDescription>·</AssociatedEventDescription>
            <AssociatedEventDescription>{`${participantsCount} participants`}</AssociatedEventDescription>
          </Stack>
        </Stack>
      ))}
    </Stack>
  </Drawer>
);
