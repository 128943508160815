import { InferType, array, object } from 'yup';

import { themeSchema } from '../MergeThemes/schema';

export const unmergeThemesFormSchema = object({
  themes: array()
    .of(themeSchema)
    .default([])
    .compact(({ isSelected }) => !isSelected)
    .min(1),
});

export type UnmergeThemesFormValues = InferType<typeof unmergeThemesFormSchema>;
