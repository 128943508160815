/* eslint-disable functional/no-let */
export const ASPECT_RATIO = 16 / 9;
export const GAP = 8;

export type Layout = {
  rows: number;
  cols: number;
  tileWidth: number;
  tileHeight: number;
  totalWidth: number;
  totalHeight: number;
};

/**
 * Calculates the optimal layout for a grid of tiles based on the container dimensions and the number of tiles.
 *
 * @param containerWidth - The width of the container.
 * @param containerHeight - The height of the container.
 * @param tileCount - The number of tiles in the grid.
 * @returns The layout object containing information about the number of rows, columns, tile dimensions, and total dimensions.
 */
export const calculateOptimalLayout = (containerWidth: number, containerHeight: number, tileCount: number): Layout => {
  const screenProportions = containerHeight / (containerWidth / ASPECT_RATIO);
  const roundedSqrtWithProportions = Math.round(Math.sqrt(tileCount * screenProportions));
  const rows = Math.min(Math.max(roundedSqrtWithProportions, 1), tileCount);
  const cols = Math.ceil(tileCount / rows);

  const maxTileWidth = (containerWidth - (cols - 1) * GAP) / cols;
  const maxTileHeight = (containerHeight - (rows - 1) * GAP) / rows;

  let tileWidth = maxTileWidth;
  let tileHeight = tileWidth / ASPECT_RATIO;

  if (tileHeight > maxTileHeight) {
    tileHeight = maxTileHeight;
    tileWidth = tileHeight * ASPECT_RATIO;
  }

  const totalWidth = tileWidth * cols + (cols - 1) * GAP;
  const totalHeight = tileHeight * rows + (rows - 1) * GAP;

  return {
    cols,
    rows,
    tileHeight,
    tileWidth,
    totalHeight,
    totalWidth,
  };
};
