import { Toolbar, styled } from '@mui/material';

export const DemographicFiltersToolbarRoot = styled(Toolbar)(({ theme: { spacing, palette, breakpoints } }) => ({
  [breakpoints.up('sm')]: {
    padding: spacing(2, 2, 2, 38),
  },

  borderBottom: '1px solid',
  borderColor: palette.divider,
}));
