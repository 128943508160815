import type { DateISOString } from 'domain/Common';

import { CreateEventQuestionRequest } from '../event';

export const ReminderIntervalTimeUnits = {
  Days: 'DAYS',
  Weeks: 'WEEKS',
  Months: 'MONTHS',
  Years: 'YEARS',
} as const;

export type ReminderIntervalTimeUnit = (typeof ReminderIntervalTimeUnits)[keyof typeof ReminderIntervalTimeUnits];

export type AsyncSurveyListSortField = 'NAME' | 'CREATED_AT' | 'STATE';

export const AsyncSurveyStates = {
  Published: 'PUBLISHED',
  Unpublished: 'UNPUBLISHED',
  Completed: 'COMPLETED',
} as const;

export type AsyncSurveyState = (typeof AsyncSurveyStates)[keyof typeof AsyncSurveyStates];

export type AsyncSurveyResponse = {
  id: number;
  name: string;
  state: AsyncSurveyState;
  createdAt: DateISOString;
  publishedAt: DateISOString;
  dueBy: DateISOString;
  ownerId: number;
  expired: boolean;
  reminderIntervalAmount: number;
  reminderIntervalTimeUnit: ReminderIntervalTimeUnit;
  communityId: number;
  welcomeMessage: string;
  invitedUsers: number | undefined;
  responsesReceived: number | undefined;
};

export type AsyncSurvey = AsyncSurveyResponse;

export type AsyncSurveyPublic = AsyncSurvey;

export type AsyncSurveyListItemResponse = AsyncSurvey;

export type AsyncSurveyListItem = AsyncSurveyListItemResponse;

export type WithSurveyId<T extends Record<string, unknown> = {}> = Pick<AsyncSurvey, 'id'> & T;

export type CreateSurveyPayload = {
  communityId: number;
  name: string;
  dueBy: DateISOString;
  welcomeMessage: string;
  reminderIntervalAmount: number;
  reminderIntervalTimeUnit: ReminderIntervalTimeUnit;
};

export type CreateSurveyQuestionsPayload = WithSurveyId<{
  questions: CreateEventQuestionRequest[];
}>;

export type CreateSurveyUsersPayload = WithSurveyId<{
  emails: string[];
}>;

export type UpdateSurveyPayload = Pick<
  AsyncSurvey,
  'id' | 'name' | 'dueBy' | 'reminderIntervalAmount' | 'reminderIntervalTimeUnit' | 'welcomeMessage'
>;
