import React, { FC } from 'react';

import { useBreakpoints } from 'common/hooks';
import { EventDetails, EventOwnerBase } from 'domain/event';
import { DEFAULT_EVENT_IMAGE_SMALL } from 'event/management/constants';

import { EventScheduledTime } from '../../../EventScheduledTime';
import { EventStatusBadge } from '../../../EventStatusBadge';
import {
  EventImage,
  EventTimeWrapper,
  EventTitle,
  HeaderBottomSection,
  HeaderBottomSectionContent,
  HeaderWrapper,
  OrganizatorLabel,
} from './EventPreviewHeader.styled';

type Props = {
  eventDetails: Pick<EventDetails, 'name' | 'eventTime' | 'eventImage' | 'status'> & { owner: EventOwnerBase };
};

export const EventPreviewHeader: FC<Props> = ({ eventDetails }) => {
  const { isSmallScreen } = useBreakpoints();
  const { name, eventImage = DEFAULT_EVENT_IMAGE_SMALL, eventTime, status, owner } = eventDetails;

  return (
    <HeaderWrapper>
      {isSmallScreen && (
        <>
          <EventStatusBadge status={status} />
          <EventImage src={eventImage.src} alt={name} />
          <EventTitle title={name}>{name}</EventTitle>
        </>
      )}
      <HeaderBottomSection>
        <HeaderBottomSectionContent>
          {!isSmallScreen && <EventTitle title={name}>{name}</EventTitle>}
          <EventTimeWrapper>
            <EventScheduledTime startDate={eventTime.startTime} duration={eventTime.durationInMinutes} />
            {!isSmallScreen && <EventStatusBadge status={status} />}
          </EventTimeWrapper>
        </HeaderBottomSectionContent>
        {!isSmallScreen && <EventImage src={eventImage.src} alt={name} />}
      </HeaderBottomSection>
      <OrganizatorLabel>Organized by: {owner.fullName}</OrganizatorLabel>
    </HeaderWrapper>
  );
};
