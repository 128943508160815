import type { Components, Theme } from '@mui/material';

export const MuiInputLabel: Components<Theme>['MuiInputLabel'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette, typography } }) => ({
      zIndex: 'unset',
      ...typography['natter-text-sm'],

      color: palette.grey[600],

      '&.Mui-disabled': {
        color: palette.grey[300],
      },
    }),
    outlined: {
      transform: 'translate(16px, 14px) scale(1)',
      '&.MuiInputLabel-sizeSmall': {
        transform: 'translate(16px, 6px) scale(1)',
      },

      '&.MuiInputLabel-shrink': {
        transform: 'translate(14px, -7px) scale(0.75)',
      },
    },
    asterisk: ({ theme: { palette } }) => ({
      color: palette.error.main,
    }),
  },
};
