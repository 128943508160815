import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ButtonBase, Stack, styled } from '@mui/material';
import { IconButton } from 'common/components/Buttons';
import { shadows } from 'common/theme';

export const FilterBaseButtonWrapper = styled(ButtonBase)<{
  isValueSelected: boolean;
  isOpen: boolean;
  fullWidth: boolean;
}>(({ theme: { spacing, palette, typography }, isValueSelected, isOpen, fullWidth }) => ({
  border: '1px solid',
  borderRadius: '8px',
  padding: spacing(0, 1),
  height: 32,
  ...typography['natter-text-sm'],
  fontWeight: 500,
  width: fullWidth ? '100%' : undefined,
  ...(isValueSelected
    ? {
        borderColor: palette.extendedPrimary[50],
        backgroundColor: palette.extendedPrimary[50],
        color: palette.extendedPrimary[600],

        '&:hover:not(:has(button:hover))': isOpen
          ? undefined
          : {
              borderColor: palette.extendedPrimary[100],
              backgroundColor: palette.extendedPrimary[100],
              color: palette.extendedPrimary[700],
            },

        ...(isOpen && {
          borderColor: palette.extendedPrimary[200],
          backgroundColor: palette.extendedPrimary[200],
          color: palette.extendedPrimary[800],
        }),
      }
    : {
        borderColor: palette.grey[200],
        backgroundColor: palette.common.white,
        color: palette.grey[700],

        '&:hover': {
          backgroundColor: isOpen ? 'undefined' : palette.grey[50],
        },

        ...(isOpen && {
          backgroundColor: palette.grey[200],
        }),
      }),
}));

export const FilterBaseButtonContent = styled(Stack)(() => ({
  flexDirection: 'row',
  gap: 1,
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
}));

export const FilterBaseArrowIcon = styled(ArrowDropDownIcon)<{ isOpen: boolean }>(({ isOpen }) => ({
  fontSize: '20px',
  transform: isOpen ? 'rotate(-180deg)' : '',
  transition: 'transform 200ms',
}));

export const FilterBaseWrapper = styled(Stack)(({ theme: { spacing, palette } }) => ({
  boxShadow: shadows.medium,
  background: palette.common.white,
  padding: spacing(2, 0),
  gap: spacing(2),
  width: 357,
}));

export const FilterBaseHeader = styled(Stack)(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: spacing(0, 2),
}));

export const FilterBaseActions = styled(Stack)(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  gap: spacing(2),
  justifyContent: 'end',
  padding: spacing(0, 2),
}));

export const FilterBaseContentContent = styled(Stack)(() => ({
  maxHeight: '50vh',
  overflow: 'auto',
}));

export const FilterBaseClearButton = styled(IconButton)(({ theme: { spacing } }) => ({
  color: 'inherit',
  marginRight: spacing(-1),
  borderRadius: '50%',
}));
