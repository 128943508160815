import React, { FC, Fragment } from 'react';

import { Box, Divider } from '@mui/material';
import { Alert, AlertVariant, Loadable } from 'common/components';
import { AnswerStatistics } from 'store/apis/insightsReport';

import { AnswerStatisticsCard } from '../AnswerStatisticsCard';
import { ReportSection } from '../layout';

export const SurveyInsights: FC<{
  surveyName: string;
  data: AnswerStatistics[] | undefined;
}> = ({ surveyName, data }) => (
  <ReportSection title={`${surveyName} Insights`}>
    <Loadable isLoading={!data} sx={{ minHeight: 100 }}>
      {data?.length === 0 && (
        <Box p={3}>
          <Alert variant={AlertVariant.InfoGray} description={`No questions were created for ${surveyName}.`} />
        </Box>
      )}

      {data?.map((answerStatistics, index) => (
        <Fragment key={index}>
          <AnswerStatisticsCard answerStatistics={answerStatistics} />
          <Divider />
        </Fragment>
      ))}
    </Loadable>
  </ReportSection>
);
