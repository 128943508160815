import { useCallback } from 'react';

import { BasicChartData } from 'common/components/Charts';
import { dataToCsvFile } from 'common/utils';

const nonDataKeys = ['id', 'name'];

export const useExportChartDataToCSV = (data: BasicChartData) => {
  const exportAsCSV = useCallback(() => {
    if (data.length === 0) return;

    const dataKeys = Object.keys(data[0]).filter((key) => !nonDataKeys.includes(key));
    const rows: (string | number)[][] = [['', ...dataKeys]];

    rows.push(...data.map((dataPoint) => [dataPoint.name, ...dataKeys.map((key) => dataPoint[key] || 0)]));

    const csvString = rows.map((row) => row.join(',')).join('\n');
    dataToCsvFile(csvString, 'chartData.csv');
  }, [data]);

  return { exportAsCSV };
};
