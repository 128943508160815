import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';
import { Button } from 'common/components';
import { Checkbox } from 'common/components/ReactHookForm';

import { MergeThemesFormValues, ThemeFormField } from './schema';

export const MergeThemesFormStep1: FC<{
  themes: ThemeFormField[];
  onNext(): void;
  onClose(): void;
}> = ({ themes, onNext, onClose }) => {
  const { trigger } = useFormContext();
  const { fieldState } = useController<MergeThemesFormValues>({ name: 'themes' });
  const isNextDisabled = fieldState.invalid || !fieldState.isTouched;

  const handleCheckboxChange = () => {
    trigger('themes');
  };

  return (
    <Stack gap={3}>
      <Stack gap={2} py={1}>
        {themes.map(({ theme, themeId }, index) => (
          <Checkbox name={`themes.${index}.isSelected`} label={theme} key={themeId} onChange={handleCheckboxChange} />
        ))}
      </Stack>
      <Stack direction="row" gap={1} justifyContent="end">
        <Button variant="text" onClick={onClose}>
          Close
        </Button>
        <Button disabled={isNextDisabled} onClick={onNext}>
          Next
        </Button>
      </Stack>
    </Stack>
  );
};
