/* istanbul ignore file */
import React, { StrictMode } from 'react';
import 'react-date-range/dist/styles.css';
// main css file
import 'react-date-range/dist/theme/default.css';
// theme css file
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import { logEnabledFeatures } from 'common/utils';
import { reduxHistory } from 'reduxHistory';
import { store } from 'store';
import { KeycloakProvider } from 'store/context/KeycloakContext';

import { initGtm } from './gtm';
import './index.css';
import { PreApp } from './preApp';
import { initSentry } from './sentry';

initSentry();
initGtm();
logEnabledFeatures();

// forwardRef with generic types
declare module 'react' {
  // eslint-disable-next-line no-unused-vars
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

// eslint-disable-next-line no-console
console.log('*** Natter version:', process.env.REACT_APP_TAG, `(${process.env.REACT_APP_COMMIT ?? ''}) ***`);

ReactDOM.render(
  <StrictMode>
    <KeycloakProvider>
      <Provider store={store}>
        <IntercomProvider appId={window._env_.INTERCOM_ID}>
          <Router history={reduxHistory}>
            <PreApp />
          </Router>
        </IntercomProvider>
      </Provider>
    </KeycloakProvider>
  </StrictMode>,
  document.getElementById('root')
);
