import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import type { InsightsReportKeyMetric } from 'pages/InsightsReport/types';

const MAX_AVERAGE_NATTER_RATING = 5;

export const eventKeyMetrics: InsightsReportKeyMetric[] = [
  {
    key: 'participants',
    title: 'Participants',
    icon: Groups2OutlinedIcon,
  },
  {
    key: 'natters',
    title: 'Natters',
    icon: QuestionAnswerOutlinedIcon,
  },
  {
    key: 'qualitativeStatements',
    title: 'Qualitative Statements',
    icon: ArticleOutlinedIcon,
  },
  {
    key: 'newConnections',
    title: 'New connections',
    icon: PersonAddAlt1OutlinedIcon,
  },
  {
    key: 'averageNatterRating',
    title: 'Average Natter Rating',
    icon: InsertEmoticonOutlinedIcon,
    maxValue: MAX_AVERAGE_NATTER_RATING,
  },
  {
    key: 'dataPoints',
    title: 'Data Points',
    icon: BarChartRoundedIcon,
  },
];
