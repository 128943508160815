import React, { FC } from 'react';

import { Divider } from '@mui/material';

import { KeyMetricsContainer } from '../KeyMetrics';
import { ParticipantDemographics } from '../ParticipantDemographics';
import { ReportCard, ReportSection } from '../layout';

export const KeyMetricsAndParticipantDemographics: FC = () => (
  <ReportSection title="Key Metrics & Participant Demographics">
    <ReportCard title="Key Metrics">
      <KeyMetricsContainer />
    </ReportCard>

    <Divider />

    <ReportCard title="Demographics">
      <ParticipantDemographics />
    </ReportCard>
  </ReportSection>
);
