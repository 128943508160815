import React, { FC, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Link, Stack, Typography } from '@mui/material';
import { LinkifyText, TabPanel, Tabs } from 'common/components';
import { EventDetails } from 'domain/event';

type Props = {
  eventDetails: Pick<EventDetails, 'description' | 'sponsors'>;
};

export const EventPreviewTabs: FC<Props> = ({ eventDetails: { description, sponsors } }) => {
  const tabs = [description.length && 'Description', sponsors.length && 'Sponsors'].filter(Boolean) as string[];
  const [selectedTab, setSelectedTab] = useState(description.length ? 0 : 1);

  if (tabs.length === 0) {
    return null;
  }

  return (
    <Stack gap={2} pt={1}>
      <Tabs tabs={tabs} value={selectedTab} onChange={(_, newTab) => setSelectedTab(newTab)} />

      <TabPanel index={0} value={selectedTab}>
        <Typography
          component="section"
          variant="natter-text-sm"
          color="grey.700"
          sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
        >
          <LinkifyText>{description}</LinkifyText>
        </Typography>
      </TabPanel>
      <TabPanel index={1} value={selectedTab}>
        <Stack gap={3}>
          {sponsors.map((sponsor) => (
            <Stack gap={2} key={sponsor.id}>
              <LazyLoadImage src={sponsor.image.src} alt={sponsor.image.name} width="100%" />
              {sponsor.url && (
                <Link href={sponsor.url} component="a" target="_blank">
                  Visit sponsor's website
                </Link>
              )}
            </Stack>
          ))}
        </Stack>
      </TabPanel>
    </Stack>
  );
};
