import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ChromePicker } from 'react-color';
import { useFormContext, useWatch } from 'react-hook-form';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Box, Popover, Stack, ThemeProvider, Typography, styled } from '@mui/material';
import { theme } from 'theme';

import { Button } from '../../Button';
import { InputWrapper } from '../InputWrapper';
import { useColorInputStyles } from './ColorInput.styles';

export type ColorInputProps = {
  value?: string;
  format?: string;
  disableAlpha?: boolean;
  pickerProps?: object;
  name: string;
  hint: string;
  label: string;
};

const LinkPreview = styled('span')(({ theme: { palette } }) => ({
  color: palette.primary.main,
}));

export const ColorInput: FC<ColorInputProps> = ({
  format = 'hex',
  disableAlpha = true,
  pickerProps = {},
  name,
  hint,
  label,
  ...props
}) => {
  const classes = useColorInputStyles();
  const methods = useFormContext();
  const [colorPickerAnchor, setColorPickerAnchor] = useState(null);
  const color = useWatch({ name, control: methods.control })?.[format];
  const colorTheme = useMemo(() => theme(color), [color]);

  useEffect(() => {
    methods.register(name);
  }, [methods, name]);

  const handleClick = useCallback((event) => {
    setColorPickerAnchor(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setColorPickerAnchor(null);
  }, []);

  const onChangeColor = useCallback(
    (pickerValue) => {
      methods.setValue(name, pickerValue);
    },
    [methods, name]
  );

  return (
    <ThemeProvider theme={colorTheme}>
      <InputWrapper label={label} hint={hint} name={name} {...props} autoWidth>
        <Stack gap={4}>
          <Stack direction="row" alignItems="center">
            <Button
              variant="contained"
              sx={({ spacing }) => ({
                borderRadius: spacing(0.5, 0, 0, 0.5),
                height: spacing(7),
                width: spacing(7),
                minWidth: spacing(7),
                padding: 0,
              })}
              onClick={handleClick}
              data-testid="colorInput-button"
            >
              <EditOutlinedIcon color="inherit" />
            </Button>
            <Typography className={classes.label}>{color}</Typography>
          </Stack>
          <Stack gap={1}>
            <Typography>Preview</Typography>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              borderRadius={0.5}
              border={1}
              borderColor="divider"
              overflow="hidden"
            >
              <Stack p={4} bgcolor="common.white" gap={2} flex={1}>
                <Typography className={classes.textPreviewWhite}>
                  Example <LinkPreview>Text link</LinkPreview>
                </Typography>
                <Box>
                  <Button variant="contained">Button</Button>
                </Box>
              </Stack>
              <Stack p={4} bgcolor="common.black" gap={2} flex={1}>
                <Typography className={classes.textPreviewBlack}>
                  Example <LinkPreview>Text link</LinkPreview>
                </Typography>
                <Box>
                  <Button variant="contained">Button</Button>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Popover
          open={Boolean(colorPickerAnchor)}
          anchorEl={colorPickerAnchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <ChromePicker disableAlpha={disableAlpha} onChange={onChangeColor} color={color} {...pickerProps} />
        </Popover>
      </InputWrapper>
    </ThemeProvider>
  );
};
