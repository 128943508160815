import React, { FC, useMemo } from 'react';

import { MenuItemType, RadioGroupOption, SingleSelectFilter } from 'common/components';
import { BasicChartData } from 'common/components/Charts';
import { isFeatureEnabled } from 'common/utils';
import { useInsightsReportContext } from 'pages/InsightsReport/hooks';

import { ChartMoreMenu } from '../../ChartMoreMenu';
import { useExportChartDataToCSV } from '../../ChartMoreMenu/hooks';
import { ChartSelect, ChartType } from '../../ChartSelect';

export const AVAILABLE_CHART_TYPES = [ChartType.Donut, ChartType.Pie, ChartType.HorizontalBar, ChartType.Bar];

type Props = {
  chartId: string;
  title: string;
  data: BasicChartData;
  selectedChartType: ChartType;
  breakDownBy: string | undefined;
  onBreakDownByChange(value: string): void;
  onSelectedChartTypeChange(chartType: ChartType): void;
};

export const SingleChoiceQuestionChartCardActions: FC<Props> = ({
  chartId,
  title,
  data,
  selectedChartType,
  breakDownBy,
  onBreakDownByChange,
  onSelectedChartTypeChange,
}) => {
  const { exportAsCSV } = useExportChartDataToCSV(data);
  const { demographicData } = useInsightsReportContext();

  const options: RadioGroupOption[] = useMemo(
    () =>
      demographicData?.map<RadioGroupOption>(({ tagCategoryType, categoryId }) => ({
        label: tagCategoryType,
        value: categoryId,
      })) ?? [],
    [demographicData]
  );

  const actions: MenuItemType[] = useMemo(
    () => [
      {
        text: 'Export as CSV',
        onClick: exportAsCSV,
      },
    ],
    [exportAsCSV]
  );

  return (
    <>
      {isFeatureEnabled('ChartBreakDown') && (
        <SingleSelectFilter
          label="Break down by Tag category"
          onChange={onBreakDownByChange}
          value={breakDownBy}
          options={options}
          placement="bottom-end"
        />
      )}
      {!breakDownBy && (
        <ChartSelect
          availableChartTypes={AVAILABLE_CHART_TYPES}
          value={selectedChartType}
          onChange={onSelectedChartTypeChange}
        />
      )}
      <ChartMoreMenu chartId={chartId} actions={actions} title={title} />
    </>
  );
};
