import { Dispatch, MiddlewareAPI } from '@reduxjs/toolkit';
import {
  BroadcastWSAction,
  BroadcastWSActions,
  ChatWSAction,
  ChatWSActions,
  MeetingWSAction,
  MeetingWSActions,
  RoomWSAction,
  RoomWSActions,
} from 'common/constants';
import { extractPathVariables, generateDynamicPath } from 'common/utils';
import omit from 'lodash/omit';
import { AppDispatch, RootState } from 'store';
import { SocketSendParams } from 'store/features/socket';
import { websocketSend } from 'store/features/socket/actions';

type MeetingWSActionPath = MeetingWSAction | BroadcastWSAction | ChatWSAction | RoomWSAction;

/**
 * Factory function for creating Redux action creators for **Meetings** WebSocket actions.
 * @TODO Make it generic to handle other WS endpoints
 *
 * @template T - The type of data that will be sent with the WebSocket action.
 *
 * @param {MeetingWSAction} path - The path for the WebSocket action, which can contain variables indicated by a colon (e.g., '/meeting/:eventId/join').
 *
 * @param {T} data - The data to be sent with the WebSocket action.
 *
 * @example
 * ```ts
 * //before
 *
 * export const meetingJoin =
 * ({ eventId, pin }: { eventId: string; pin: string }) =>
 * (dispatch: AppDispatch) => {
 *   dispatch(
 *     websocketSend({
 *       path: `/meeting/${eventId}/join`,
 *       data: { pin: pin || undefined },
 *     })
 *   );
 * };
 *
 * // after
 * export const meetingJoin = createWebSocketAction<MeetingWSParams<MeetingJoinPayload>>('/meeting/:eventId/join');
 *
 * // usage in component or hook
 * const dispatch = useAppDispatch();
 * dispatch(meetingJoin({ eventId, pin: eventPinValue }));
 * ```
 *  */
export const createMeetingWSAction =
  <T extends Record<string, unknown>>(
    path: MeetingWSActionPath,
    callback?: (dispatch: Dispatch, data?: T) => void,
    config?: Partial<SocketSendParams>
  ) =>
  (data?: T) =>
  (dispatch: Dispatch, getState: MiddlewareAPI<AppDispatch, RootState>['getState']): void => {
    // TODO Create type guard isMeetingAction
    if (
      ![
        ...Object.values(MeetingWSActions),
        ...Object.values(ChatWSActions),
        ...Object.values(BroadcastWSActions),
        ...Object.values(RoomWSActions),
      ].includes(path)
    )
      throw Error(`Action '${path}' is not supported by Meeting WS`);

    const { event } = getState();
    if (!event.activeEventId) return;

    const payload = omit(data, extractPathVariables(path));
    dispatch(
      websocketSend({
        path: generateDynamicPath(path, { eventId: event.activeEventId, ...data }),
        data: Object.keys(payload).length ? payload : undefined,
        plainBody: Object.keys(payload).length ? config?.plainBody ?? true : undefined,
      })
    );
    if (typeof callback === 'function') {
      callback(dispatch, data);
    }
  };
