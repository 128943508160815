import React, { FC, memo, useMemo } from 'react';

import { CircularProgress } from '@mui/material';
import { UserPrivilegeName } from 'domain/UserProfile';

import { useAuthorization } from '../../hooks';

export const ProtectedComponent: FC<{
  requiredPrivileges?: (keyof typeof UserPrivilegeName)[];
}> = memo(({ requiredPrivileges, children }) => {
  const { isLoading, hasPrivilege } = useAuthorization();
  const isAllowed = useMemo(
    () => (requiredPrivileges ? hasPrivilege(requiredPrivileges.map((p) => UserPrivilegeName[p])) : false),
    [hasPrivilege, requiredPrivileges]
  );

  if (isLoading) {
    return <CircularProgress style={{ margin: 'auto' }} size={50} />;
  }
  return isAllowed ? <>{children}</> : null;
});
