import { KeyMetricsAndParticipantDemographics } from '../components';
import { InsightsReportRoute } from '../types';

export const BasicInsightsReportRoutes = {
  keyMetricsAndParticipantDemographics: {
    title: 'Key Metrics & Participant Demographics',
    path: '/key-metrics-participant-demographics',
    render: KeyMetricsAndParticipantDemographics,
  } as InsightsReportRoute,
} as const;
