import React, { FC } from 'react';

import { Dialog } from 'common/components';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { HideThemesForm } from './HideThemesForm';
import { useHideThemes } from './useHideThemes';

export const HideThemesDialog: FC<{
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
  themes: TextAnswerTheme[];
}> = ({ isOpen, onClose, onOpen, themes }) => {
  const { handleClose, handleSubmit, RenameFailureModal, isLoading } = useHideThemes({
    onClose,
    onOpen,
  });

  return (
    <>
      <Dialog
        description="You can hide and unhide Dominant Themes from this Report. One Theme always needs to be visible therefore you are not able to hide them all."
        isOpen={isOpen}
        onClose={handleClose}
        title="Configure visibility"
        showCancelButton={false}
        maxWidth="sm"
        fullWidth
      >
        <HideThemesForm themes={themes} onClose={handleClose} isLoading={isLoading} onSubmit={handleSubmit} />
      </Dialog>
      {RenameFailureModal}
    </>
  );
};
