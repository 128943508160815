import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { ButtonBase } from '@mui/material';
import { useAppSelector } from 'store';
import { selectIsDarkTheme } from 'store/features/app';

import { SnackbarWrapper } from './Snackbar.styled';
import { SnackbarIcon, SnackbarIconProps } from './SnackbarIcon';

type Props = SnackbarIconProps & {
  onClose?(): void;
};

export const Snackbar: FC<Props> = ({ severity, children, onClose }) => {
  const isDarkTheme = useAppSelector(selectIsDarkTheme);

  return (
    <SnackbarWrapper isDarkTheme={isDarkTheme}>
      <SnackbarIcon severity={severity} />
      {children}
      {onClose && (
        <ButtonBase onClick={onClose}>
          <CloseIcon fontSize="small" />
        </ButtonBase>
      )}
    </SnackbarWrapper>
  );
};
