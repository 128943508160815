import type { Components, Theme } from '@mui/material';

export const MuiAppBar: Components<Theme>['MuiAppBar'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: ({ theme: { palette } }) => ({
      border: 'none',
      borderBottom: 'solid 1px',
      borderColor: palette.divider,
    }),
  },
};
