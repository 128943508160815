import { Color, darken, getLuminance, lighten } from '@mui/material';

/**
 * Generates a color palette based on the provided color adjusted to blue from Design System.
 * @param color - The base color used to generate the palette.
 * @returns An object representing the color palette.
 *
 * @link https://www.figma.com/design/En2gOztv0xR9rIjYFtdut5/Design-system?node-id=211-3521&t=hHNXrHQTnHBD34kK-4
 */
export const generateColor = (color: string): Partial<Color> => ({
  25: lighten(color, 0.9), // Adjusted to match #F5FAFF from #1570EF
  50: lighten(color, 0.88), // Adjusted to match #EFF8FF from #1570EF
  100: lighten(color, 0.76), // Adjusted to match #D1E9FF from #1570EF
  200: lighten(color, 0.64), // Adjusted to match #B2DDFF from #1570EF
  300: lighten(color, 0.52), // Adjusted to match #84CAFF from #1570EF
  400: lighten(color, 0.4), // Adjusted to match #53B1FD from #1570EF
  500: lighten(color, 0.28), // Adjusted to match #2E90FA from #1570EF
  600: color, // Base color #1570EF
  700: darken(color, 0.1), // Adjusted to match #175CD3 from #1570EF
  800: darken(color, 0.2), // Adjusted to match #1849A9 from #1570EF
  900: darken(color, 0.3), // Adjusted to match #194185 from #1570EF
});

export const generateContrastColor = (color: string): string =>
  getLuminance(color) > 0.5 ? darken(color, 0.6) : lighten(color, 0.9);
