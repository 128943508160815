import { useMemo } from 'react';

import { mapEventQuestionsFromApi } from 'mappers/event';
import { useAppSelector } from 'store';
import { useGetSurveyAnswersQuery, useGetSurveyQuery, useGetSurveyQuestionsQuery } from 'store/apis/survey';
import { selectIdFromCurrentRoute } from 'store/features/router';

export const useSurveyData = () => {
  const id = useAppSelector(selectIdFromCurrentRoute);

  const {
    data: survey,
    isLoading: isSurveyLoading,
    isError: isSurveyError,
  } = useGetSurveyQuery({ id: id! }, { skip: !id });
  const {
    data: questions,
    isLoading: isQuestionsLoading,
    isError: isQuestionsError,
  } = useGetSurveyQuestionsQuery({ id: id! }, { skip: !id });
  const {
    data: answers,
    isLoading: isAnswersLoading,
    isError: isAnswersError,
  } = useGetSurveyAnswersQuery({ id: id! }, { skip: !id });

  const mappedQuestions = useMemo(
    () => (questions ? mapEventQuestionsFromApi(questions, true) : undefined),
    [questions]
  );

  return {
    id,
    survey,
    questions: mappedQuestions,
    answers,
    isLoading: isSurveyLoading || isQuestionsLoading || isAnswersLoading,
    isError: isSurveyError || isQuestionsError || isAnswersError,
    isCompleted: !!answers?.length,
  };
};
