import React, { FC, useState } from 'react';
import { FieldError } from 'react-hook-form';

import { Box, Stack } from '@mui/material';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';
import { SliderInput, type SliderInputProps } from 'common/components/_legacy/Form';
import type { SliderQuestion } from 'domain/question';

import { AnswerItem, AnswerLabel } from '../../components';

export const SliderAnswer: FC<{
  name: string;
  question: SliderQuestion;
  isObligatory: boolean;
}> = ({ question, isObligatory, name }) => {
  const { title } = question;
  const { minimumValue, maximumValue, minimumValueLabel, maximumValueLabel, incrementValue } = question;
  const showOptionalLabels = !!(minimumValueLabel || maximumValueLabel);
  const marks: SliderInputProps['marks'] = [
    { value: minimumValue, label: minimumValue },
    { value: maximumValue, label: maximumValue },
  ];
  const [error, setError] = useState<FieldError>();

  return (
    <AnswerItem title={title} isObligatory={isObligatory}>
      <Stack gap={2}>
        <Box px={1}>
          <SliderInput
            name={`${name}.sliderValue`}
            marks={marks}
            min={minimumValue}
            max={maximumValue}
            step={incrementValue}
            onErrorChange={setError}
          />
        </Box>
        {showOptionalLabels && (
          <Stack direction="row" justifyContent="space-between">
            <AnswerLabel data-testId="SliderAnswer-minimum">{minimumValueLabel?.toUpperCase()}</AnswerLabel>
            <AnswerLabel data-testId="SliderAnswer-maximum">{maximumValueLabel?.toUpperCase()}</AnswerLabel>
          </Stack>
        )}
        <FormFieldHint error>{error?.message}</FormFieldHint>
      </Stack>
    </AnswerItem>
  );
};
