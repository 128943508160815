import type { Components, Theme } from '@mui/material';

export const MuiOutlinedInput: Components<Theme>['MuiOutlinedInput'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme: { palette, spacing, typography } }) => ({
      ...typography['natter-text-sm'],

      minHeight: 48,
      padding: spacing(1.75, 2),

      '.MuiOutlinedInput-notchedOutline': {
        borderColor: palette.grey[300],
      },

      '&:hover': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey[600],
        },
      },

      '&.Mui-focused': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.primary.main,
          borderWidth: 1,
        },
      },

      '&.Mui-error': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.error.main,
        },
      },

      '&.Mui-disabled': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: palette.grey[300],
        },

        '.MuiTypography-root': {
          color: palette.grey[300],
        },

        '.MuiInputAdornment-root': {
          color: palette.grey[300],
        },
      },
    }),
    input: ({ theme: { palette } }) => ({
      '&:not(.MuiSelect-select)': {
        padding: 0,
        caretColor: palette.primary.main,
      },
      '&.Mui-disabled': {
        color: palette.grey[400],
        WebkitTextFillColor: palette.grey[400],
      },
    }),
    sizeSmall: ({ theme: { spacing } }) => ({
      minHeight: 32,
      padding: spacing(0.75, 1),
    }),
  },
};
