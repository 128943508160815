import React, { FC } from 'react';

import { faBug } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Stack, useTheme } from '@mui/material';

import { FullPageContainer } from '../FullPageContainer';

export const ErrorHandler: FC<{
  title?: string;
  description?: string;
}> = ({ title = 'Oops, something went wrong...', description = 'Looks like there was some unexpected error.' }) => {
  const theme = useTheme();

  return (
    <FullPageContainer>
      <Dialog open>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Stack alignItems="center" gap={2}>
            <FontAwesomeIcon icon={faBug} color={theme.palette.error.main} size="4x" />
            <DialogContentText component="div">
              <div>{description}</div>
              <div>
                Refresh the page or contact the Support Team{' '}
                <a href="https://intercom.help/natter/en/" target="_blank" rel="noreferrer">
                  here
                </a>
                .
              </div>
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
    </FullPageContainer>
  );
};
