import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { toPercentageString } from 'common/utils';

export const ChartTooltipContent: FC<{
  name: string;
  value: number;
  totalCount: number;
}> = ({ name, value, totalCount }) => (
  <Stack gap={1}>
    <Typography>{name}</Typography>
    <Typography fontWeight="bold">{`${value} (${toPercentageString(value, totalCount)})`}</Typography>
  </Stack>
);
