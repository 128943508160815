import React, { FC, ReactNode } from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, Tooltip, Typography } from '@mui/material';

type FieldLabelProps = {
  title: ReactNode;
  description?: ReactNode;
  explanation?: string;
  required?: boolean;
} & Partial<Pick<HTMLLabelElement, 'htmlFor'>>;

export const FieldLabel: FC<FieldLabelProps> = ({ title, description, explanation, required, htmlFor }) => (
  <Stack component="label" gap={1} htmlFor={htmlFor}>
    <Typography component="span" variant="natter-text-sm" fontWeight={600} sx={{ wordBreak: 'break-word' }}>
      {title}
      {required && (
        <Typography component="span" variant="inherit" color="error.main">
          *
        </Typography>
      )}
    </Typography>
    {description && (
      <Typography variant="natter-text-sm" color="text.secondary">
        {description}{' '}
        {explanation && (
          <Tooltip title={explanation} placement="bottom">
            <InfoOutlinedIcon
              sx={({ palette }) => ({ color: palette.grey[600], height: 'auto', width: 16, verticalAlign: 'text-top' })}
            />
          </Tooltip>
        )}
      </Typography>
    )}
  </Stack>
);
