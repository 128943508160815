import React, { FC, useMemo } from 'react';
import { useController } from 'react-hook-form';

import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';
import { MAX_NUMBER_OF_TAGS, TagsCategoryFormValues } from 'community/components/CommunityTags/schema';
import { isArray } from 'lodash';
import { prop, uniqBy } from 'lodash/fp';

const fieldId = 'name' as const;

type Props = {
  name: string;
};

export const TagInput: FC<Props> = ({ name }) => {
  const {
    field: { value: tags, onChange },
    fieldState: { error },
  } = useController<{ [key: string]: TagsCategoryFormValues['tags'] }>({ name, defaultValue: [] });

  const handleChange = (_: unknown, values: Array<string | { name: string }>) => {
    const newValue = values.map((item) => (typeof item === 'string' ? { [fieldId]: item.trim() } : item));

    onChange(uniqBy(fieldId, newValue));
  };

  const errorMessage = useMemo(() => {
    if (error?.message) return error.message;

    if (Array.isArray(error)) {
      return error
        .map((tag: { [key: string]: { message: string } }) => Object.values(tag)[0].message)
        .find((msg) => msg);
    }
  }, [error]);

  return (
    <Autocomplete
      fullWidth
      value={tags}
      onChange={handleChange}
      limitTags={MAX_NUMBER_OF_TAGS}
      multiple
      getOptionLabel={prop(fieldId)}
      freeSolo
      options={[]}
      selectOnFocus
      handleHomeEndKeys
      clearIcon={<CancelIcon color="action" />}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!errorMessage}
          helperText={<FormFieldHint error>{errorMessage}</FormFieldHint>}
          label="Category Tags"
          placeholder="Add Category Tags"
          inputProps={{
            style: { minWidth: 130 },
            ...params.inputProps,
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option[fieldId] === value[fieldId]}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={option[fieldId]}
            size="small"
            color={isArray(error) && error[index] ? 'error' : undefined}
            {...getTagProps({ index })}
          />
        ))
      }
    />
  );
};
