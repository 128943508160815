import { useCallback, useState } from 'react';

export const useTooltip = ({
  initialActiveIndex,
}: {
  initialActiveIndex?: number;
} = {}) => {
  const [activeIndex, setActiveBarIndex] = useState<number | undefined>(initialActiveIndex);

  const handleMouseEnter = useCallback((barIndex: number) => () => setActiveBarIndex(barIndex), []);
  const hideTooltip = useCallback(() => setActiveBarIndex(-1), []);

  return {
    handleMouseEnter,
    hideTooltip,
    activeIndex,
  };
};
