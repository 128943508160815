import { useParams } from 'react-router-dom';

import isNaN from 'lodash/isNaN';
import { EventRouteParams } from 'router';

/**
 * Retrieves active event parameters from the URL.
 *
 * @description
 * This function should be called in scenarios where event details and API calls are not needed,
 * and only parameters from the URL are required. For example, in public pages.
 */
export const useActiveEventParams = () => {
  const { id } = useParams<EventRouteParams>();
  const eventId = isNaN(Number(id)) ? undefined : Number(id);

  return { id: eventId || undefined };
};
