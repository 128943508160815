import React, { FC } from 'react';

import { useInsightsReportContext } from 'pages/InsightsReport/hooks';

import { InsightsReportMoreMenuLayout } from './InsightsReportMoreMenuLayout';

export const InsightsReportMoreMenu: FC<{}> = () => {
  const { isPublicReport, deleteReport, isDeleting } = useInsightsReportContext();

  return isPublicReport ? null : <InsightsReportMoreMenuLayout isLoading={isDeleting} onDelete={deleteReport} />;
};
