import React, { FC } from 'react';

import { SurveyInsights } from 'pages/InsightsReport';
import { useCurrentSurveyReportAnswers } from 'pages/SurveyReport/hooks';

export const ResultsAnalysis: FC = () => {
  const { data } = useCurrentSurveyReportAnswers();

  return <SurveyInsights data={data} surveyName="Results Analysis" />;
};
