import React, { FC, ReactElement } from 'react';
import { useController } from 'react-hook-form';

import { Stack } from '@mui/material';
import { FormFieldHint, ColorPicker as NatterColorPicker } from 'common/components/Inputs';

type Props = {
  name: string;
  action?: ReactElement;
};

export const ColorPicker: FC<Props> = ({ name, action }) => {
  const {
    field: { ref, value, onChange },
    fieldState: { error },
  } = useController({ name });

  return (
    <Stack>
      <NatterColorPicker ref={ref} value={value} onChange={onChange} suffix={action} />
      {error && <FormFieldHint error>{error.message}</FormFieldHint>}
    </Stack>
  );
};
