import React, { FC, useCallback } from 'react';

import { ButtonMenu, MenuItemType } from 'common/components';
import { useExportAsPNG } from 'common/hooks';

import { ChartCardHeaderRightSectionId } from '../../ChartCard.styled';

type Props = {
  chartId: string;
  title: string;
  actions?: MenuItemType[];
};

export const ChartMoreMenu: FC<Props> = ({ chartId, title, actions = [] }) => {
  const onClone = useCallback((_document: Document, element: HTMLElement) => {
    const chartCardWrapper = element.querySelector('.ChartCardWrapper');
    if (chartCardWrapper) {
      (chartCardWrapper as HTMLElement).style.boxShadow = 'none';
    }
    const chartCardHeaderRightSection = element.querySelector(`#${ChartCardHeaderRightSectionId}`);
    if (chartCardHeaderRightSection) {
      chartCardHeaderRightSection.remove();
    }
  }, []);

  const { exportAsPNG, isLoading } = useExportAsPNG({
    elementId: chartId,
    fileName: title.replace(/\s/g, '_'),
    onClone,
  });

  return (
    <ButtonMenu
      variant="text"
      color="text"
      isLoading={isLoading}
      size="medium"
      items={[
        {
          text: 'Export as PNG',
          onClick: exportAsPNG,
        },

        ...actions,
      ]}
    />
  );
};
