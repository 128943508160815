import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Box, Skeleton } from '@mui/material';
import { ErrorState } from 'common/components';
import { NavigationBar } from 'common/layout';
import { AppRoutes } from 'router';
import { useAppDispatch } from 'store';
import { clearDemographicFilters } from 'store/features/insightsReports';

import { ContentWrapper, RouterContent, RouterWrapper, SidePanelWrapper } from './InsightReport.styled';
import { InsightReportRouter } from './InsightReportRouter';
import { DemographicFilters, InsightsReportMoreMenu, ReportNameContainer } from './components';
import { SideNav, Summary } from './components/layout/';
import { useInsightsReportContext } from './hooks';

export const InsightsReportPage: FC = () => {
  const history = useHistory();
  const { isLoading, isError, isPublicReport } = useInsightsReportContext();
  const dispatch = useAppDispatch();

  const onExit = () => {
    // TODO check if there are any unsaved changes: https://natterco.atlassian.net/browse/N2-846
    history.push(AppRoutes.InsightsReports);
  };

  useEffect(
    () => () => {
      dispatch(clearDemographicFilters());
    },
    [dispatch]
  );

  if (isError) {
    return (
      <>
        <NavigationBar withCommunityLogo />
        <ErrorState
          title="Report Unavailable"
          description="We’re sorry, but the Report you’re trying to access is currently unavailable."
          buttonLabel="Back to Reports List"
          href={AppRoutes.InsightsReports}
        />
      </>
    );
  }

  return (
    <>
      <NavigationBar
        title={isLoading ? <Skeleton width="50%" /> : <ReportNameContainer />}
        onExitClick={isPublicReport ? undefined : onExit}
        withCommunityLogo={!isPublicReport}
        withActionsDivider={!isPublicReport}
        extraButtons={<InsightsReportMoreMenu />}
      />

      <DemographicFilters />
      <Box overflow="auto" height={1} bgcolor="#FCFCFD">
        <ContentWrapper as="main">
          <SidePanelWrapper>
            <Summary />
            <SideNav />
          </SidePanelWrapper>

          <RouterWrapper>
            <RouterContent>
              <InsightReportRouter />
            </RouterContent>
          </RouterWrapper>
        </ContentWrapper>
      </Box>
    </>
  );
};
