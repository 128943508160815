import React, { FC, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';

import { MergeThemesFormStep1 } from './MergeThemesFormStep1';
import { MergeThemesFormStep2 } from './MergeThemesFormStep2';
import { MergeThemesFormValues, ThemeFormField, mergeThemesFormSchema } from './schema';

export const MergeThemesForm: FC<{
  themes: ThemeFormField[];
  onClose(): void;
  onSubmit(values: MergeThemesFormValues): void;
  isLoading: boolean;
}> = ({ themes, onClose, onSubmit, isLoading }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const methods = useForm<MergeThemesFormValues>({
    defaultValues: {
      themes,
    },
    resolver: yupResolver(mergeThemesFormSchema),
    mode: 'onChange',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit(values);
  });

  return (
    <FormProvider {...methods}>
      {currentStep === 1 && <MergeThemesFormStep1 themes={themes} onClose={onClose} onNext={() => setCurrentStep(2)} />}
      {currentStep === 2 && (
        <MergeThemesFormStep2 onBack={() => setCurrentStep(1)} onMerge={handleSubmit} isLoading={isLoading} />
      )}
    </FormProvider>
  );
};
