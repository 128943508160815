import React, { forwardRef } from 'react';

import Add from '@mui/icons-material/Add';
import { Button, ButtonProps } from 'common/components';
import { withPrivilegeDependency } from 'modules/authorization/hocs';

export const AddButton = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => (
  <Button {...props} startIcon={<Add />} ref={ref}>
    {children}
  </Button>
));

export const AddButtonWithPrivilege = withPrivilegeDependency(AddButton);
