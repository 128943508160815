import { addMinutes } from 'date-fns';
import { format } from 'date-fns-tz';
import { DateISOString } from 'domain/Common';

/**
 * Format scheduled time to current timezone based on specified format
 *
 * @param {Object} options - Options object containing start and end time formats.
 * @param {string} options.startTimeFormat - The format string for the start time (e.g., 'HH:mm').
 * @param {string} options.endTimeFormat - The format string for the end time (e.g., 'HH:mm').
 * @returns {Function} A function that accepts a start time and duration and returns the formatted time range string.
 */
export const formatScheduleTime =
  ({ startTimeFormat, endTimeFormat }: { startTimeFormat: string; endTimeFormat: string }) =>
  /**
   * Generates a formatted time range string based on start time and duration.
   *
   * @param {DateISOString} startTime - The start time.
   * @param {number} duration - The duration in minutes.
   * @returns {string} The formatted time range string.
   */
  (startTime: DateISOString | Date, duration: number) => {
    const startDate = new Date(startTime);
    const endDate = addMinutes(startDate, duration);

    return [format(startDate, startTimeFormat), format(endDate, endTimeFormat)].join(' - ');
  };
