import { TextAnswerTheme } from 'store/apis/insightsReport';

import { useExportChartDataToCSV } from '../../../ChartMoreMenu/hooks';

export const useExportThemesDataToCSV = (themes: TextAnswerTheme[]) => {
  const { exportAsCSV } = useExportChartDataToCSV(
    themes.map((theme) => ({
      name: '',
      'Theme Name': theme.theme,
      'Theme Description': theme.description ?? 'Description missing',
      Count: theme.count,
    }))
  );

  return { exportAsCSV };
};
