import { Typography, styled } from '@mui/material';
import type { TypographyWithComponent } from 'common/theme/typography';

export const ReportSectionTitle = styled(Typography)<TypographyWithComponent>(({ theme: { spacing, typography } }) => ({
  padding: spacing(2, 3),

  ...typography['natter-text-xl'],
  fontWeight: 500,
}));

ReportSectionTitle.defaultProps = {
  component: 'h2',
};
