import { createSelector } from '@reduxjs/toolkit';
import type { MediaDevice } from 'domain/event';
import { filter } from 'lodash/fp';
import type { RootState } from 'store';

import { findVideoInputDevices } from './utils';

const selectAllUserState = (state: RootState) => state.user;
const selectUserSettings = createSelector(selectAllUserState, (state) => state.userSettings);

export const selectIsAudioEnabled = createSelector(selectUserSettings, (state) => state.isAudioEnabled);
export const selectIsVideoEnabled = createSelector(selectUserSettings, (state) => state.isVideoEnabled);

export const selectMediaSettings = (state: RootState) => state.user.mediaSettings;
export const selectIsMediaSettingsOpen = createSelector(selectAllUserState, (state) => state.isMediaSettingsOpen);
export const selectIsMutedOnSystemLevel = createSelector(selectAllUserState, (state) => state.isMutedOnSystemLevel);

export const selectMediaDevices = createSelector(selectAllUserState, (state) => state.mediaDevices);

export const selectIsThereAnyDevice = createSelector(selectMediaDevices, (devices) => devices.length > 0);
export const selectHasMediaAccess = createSelector(selectAllUserState, (state) => state.hasMediaAccess);

export const selectDevicesByKind = createSelector(selectMediaDevices, (devices) => ({
  videoInputDevices: findVideoInputDevices(devices),
  audioInputDevices: filter<MediaDevice>(['kind', 'audioinput'])(devices),
  audioOutputDevices: filter<MediaDevice>(['kind', 'audiooutput'])(devices),
}));

export const selectIsLoadingMediaDevices = createSelector(selectAllUserState, (state) => state.isLoadingMediaDevices);

const selectAudioInputDevices = createSelector(selectDevicesByKind, ({ audioInputDevices }) => audioInputDevices);
const selectAudioOutputDevices = createSelector(selectDevicesByKind, ({ audioOutputDevices }) => audioOutputDevices);
const selectVideoDevices = createSelector(selectDevicesByKind, ({ videoInputDevices }) => videoInputDevices);

const activeOrFirstDevice = (activeDeviceId: string) => (devices: MediaDevice[]) =>
  devices.length ? devices.find(({ deviceId }) => deviceId === activeDeviceId) || devices[0] : undefined;

export const selectActiveAudioInputDevice = createSelector(
  [selectMediaSettings, selectAudioInputDevices],
  ({ audioInputDeviceId }, devices) => activeOrFirstDevice(audioInputDeviceId)(devices)
);

export const selectActiveAudioOutputDevice = createSelector(
  [selectMediaSettings, selectAudioOutputDevices],
  ({ audioOutputDeviceId }, devices) => activeOrFirstDevice(audioOutputDeviceId)(devices)
);

export const selectActiveVideoDevice = createSelector(
  [selectMediaSettings, selectVideoDevices],
  ({ videoDeviceId }, devices) => activeOrFirstDevice(videoDeviceId)(devices)
);

export const selectIsBlurEnabled = createSelector(selectAllUserState, (state) => state.mediaSettings.blurLevel > 0);

export const selectAudioIssueDetected = createSelector(selectActiveAudioInputDevice, (device) => !device?.deviceId);

export const selectVideoIssueDetected = createSelector(selectActiveVideoDevice, (device) => !device?.deviceId);

export const selectDeviceIssueDetected = createSelector(
  selectAudioIssueDetected,
  selectVideoIssueDetected,
  (audioIssue, videoIssue) => audioIssue || videoIssue
);
