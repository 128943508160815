export const getTimeLabel = (hours: number, minutes: number): string => {
  const hoursLabel = String(hours).length > 1 ? String(hours) : `0${hours}`;
  const MinutesLabel = String(minutes).length > 1 ? String(minutes) : `0${minutes}`;
  return `${hoursLabel}:${MinutesLabel}`;
};

interface Option {
  label: string;
  value: string;
}

export const getTimeOptions = (interval: number): Option[] =>
  Array.from({ length: (24 * 60) / interval }, (_, index) => {
    const totalMinutes = index * interval;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const time = getTimeLabel(hours, minutes);

    return {
      label: time,
      value: time,
    };
  });
