import React, { FC } from 'react';

import { NavigationBar } from 'common/layout';

import { SurveyWelcomePage } from '../components';
import { SurveyGuard } from '../components/SurveyGuard';
import { useSurveyData } from '../hooks/useSurveyData';

export const SurveyDetails: FC = () => {
  const { id, survey, isLoading, isError, isCompleted } = useSurveyData();

  return (
    <SurveyGuard id={id} survey={survey} isLoading={isLoading} isError={isError} isCompleted={isCompleted}>
      {id && survey && (
        <>
          <NavigationBar title={survey.name} />
          <SurveyWelcomePage id={id} name={survey.name} welcomeMessage={survey.welcomeMessage} />
        </>
      )}
    </SurveyGuard>
  );
};
