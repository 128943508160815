import React, { FC } from 'react';

import { InlineEditorInactive } from 'common/components';
import { ActionBar } from 'common/layout';

import { ReportNameContainer } from '../ReportName';

export const InsightsReportHeader: FC<{
  reportName: string;
  isPublic: boolean;
}> = ({ reportName, isPublic }) => (
  <ActionBar
    title={
      isPublic ? (
        <InlineEditorInactive disabled component="div">
          {reportName}
        </InlineEditorInactive>
      ) : (
        <ReportNameContainer />
      )
    }
  />
);
