import React, { forwardRef } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import { OptionItem } from 'common/components';
import { DraggableBox } from 'common/components/DragAndDrop';
import { Select } from 'common/components/ReactHookForm';
import { RankingAnswer } from 'domain/question';

type Props = {
  item: RankingAnswer['choices'][number];
  isPreview?: boolean;
  isDragging?: boolean;
  name?: string;
  options?: OptionItem<string>[];
  onSelectRank?: (rank: OptionItem<string>['value']) => void;
};

export const RankingAnswerItem = forwardRef<HTMLDivElement, Props>(
  ({ item, name, options, isPreview = false, isDragging, onSelectRank }, ref) => (
    <DraggableBox isPreview={isPreview} isDragging={isDragging} indicatorPosition="start" ref={ref}>
      <Stack direction="row" gap={1.5} alignItems="center">
        <Box width={88}>
          {!isPreview && name && options ? (
            <Select
              name={name}
              onChange={onSelectRank}
              placeholder="Rank"
              options={options}
              size="small"
              shouldShowError={false}
              displayEmpty
            />
          ) : (
            <Box height={32} border={1} borderRadius={1} borderColor="grey.400" />
          )}
        </Box>

        <Typography variant="natter-text-sm" flex={1}>
          {item.text}
        </Typography>
      </Stack>
    </DraggableBox>
  )
);
