import { NavLink } from 'react-router-dom';

import { styled } from '@mui/material';

export const LinkList = styled('nav')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',

  gap: spacing(2),
}));

export const LinkItem = styled(NavLink)(({ theme: { spacing, palette, typography } }) => ({
  position: 'relative',
  paddingLeft: spacing(2),
  textDecoration: 'none',
  userSelect: 'none',

  ...typography['natter-text-sm'],
  fontWeight: 500,

  color: palette.text.secondary,

  '&:link': {
    color: palette.text.secondary,
  },
  '&:visited': {
    color: palette.text.secondary,
  },
  '&:hover': {
    textDecoration: 'underline',
  },

  '&.active': {
    color: palette.primary.main,

    '&::before': {
      content: '""',
      width: 2,
      height: '60%',
      maxHeight: 20,
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      background: palette.primary.main,
    },
  },
}));
