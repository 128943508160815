import React, { ComponentProps, forwardRef } from 'react';

import { ColorTextField } from './ColorPicker.styled';

type Props = Omit<ComponentProps<typeof ColorTextField>, 'value'> & { value: string };

export const ColorPicker = forwardRef<HTMLInputElement, Props>(({ value, onChange, suffix }, ref) => (
  <ColorTextField
    placeholder="Pick a color"
    type="text"
    value={value}
    InputProps={{ readOnly: true }}
    suffix={suffix}
    prefix={<input ref={ref} type="color" aria-label="color" value={value} onChange={onChange} />}
  />
));
