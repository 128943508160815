import React, { FC } from 'react';

import { Dialog } from 'common/components';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { MergeThemesForm } from './MergeThemesForm';
import { useMergeThemes } from './useMergeThemes';

export const MergeThemesDialog: FC<{
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
  themes: TextAnswerTheme[];
}> = ({ isOpen, onClose, onOpen, themes }) => {
  const { handleClose, handleSubmit, RenameFailureModal, isLoading } = useMergeThemes({ onClose, onOpen });

  return (
    <>
      <Dialog
        description="You can merge two or more Dominant Themes together at the same time."
        isOpen={isOpen}
        onClose={handleClose}
        title="Merge Themes"
        showCancelButton={false}
        maxWidth="sm"
        fullWidth
      >
        <MergeThemesForm themes={themes} onClose={handleClose} isLoading={isLoading} onSubmit={handleSubmit} />
      </Dialog>
      {RenameFailureModal}
    </>
  );
};
