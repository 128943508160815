import React, { FC } from 'react';

import { Container, Skeleton, Stack, Typography } from '@mui/material';
import { NavigationBar } from 'common/layout';

export const SurveySkeleton: FC = () => (
  <>
    <NavigationBar />
    <Container maxWidth="hug" sx={{ paddingTop: '10vh' }}>
      <Stack gap={4} py={6} px={2}>
        <Typography variant="natter-display-sm">
          <Skeleton />
        </Typography>
        <Typography variant="natter-text-sm">
          {Array.from({ length: 4 }).map((_, index) => (
            <Skeleton key={index} />
          ))}
        </Typography>

        <Skeleton height={40} />
      </Stack>
    </Container>
  </>
);
