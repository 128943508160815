import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import { Stack } from '@mui/material';
import { Button } from 'common/components';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { MergeThemesFormValues } from '../MergeThemes/schema';
import { MergedThemeItem } from './MergedThemeItem';

export const UnmergeThemesFormStep1: FC<{
  themes: TextAnswerTheme[];
  isLoading: boolean;
  onSubmit(): void;
  onClose(): void;
}> = ({ themes, isLoading, onSubmit, onClose }) => {
  const { fieldState } = useController<MergeThemesFormValues>({ name: 'themes' });
  const isUnmergeDisabled = fieldState.invalid || !fieldState.isTouched || isLoading;

  return (
    <Stack gap={3}>
      <Stack gap={2} py={1}>
        {themes.map((theme, index) => (
          <MergedThemeItem key={theme.themeId} theme={theme} index={index} />
        ))}
      </Stack>
      <Stack direction="row" gap={1} justifyContent="end">
        <Button variant="text" onClick={onClose} disabled={isLoading}>
          Close
        </Button>
        <Button disabled={isUnmergeDisabled} onClick={onSubmit} isLoading={isLoading}>
          Unmerge
        </Button>
      </Stack>
    </Stack>
  );
};
