import React, { FC, ReactElement, isValidElement } from 'react';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { EmptyState } from 'common/components';

import { BaseGuardContainer } from './BaseGuard.styled';

export type BaseGuardProps = {
  title: string;
  description: string;
  shouldDisplay: boolean;
  buttonLabel?: string;
  onClick?(): void;
  href?: string;
  icon?: IconProp | ReactElement;
};

export const BaseGuard: FC<BaseGuardProps> = ({
  title,
  icon,
  description,
  shouldDisplay,
  buttonLabel,
  onClick,
  href,
  children,
}) => {
  if (shouldDisplay) {
    return <>{children}</>;
  }

  return (
    <BaseGuardContainer>
      <EmptyState
        title={title}
        description={description}
        icon={
          icon && (
            <Box color="primary.main">
              {isValidElement(icon) ? (
                <Box width={100} height={100}>
                  {icon}
                </Box>
              ) : (
                <FontAwesomeIcon icon={icon as IconProp} size="4x" />
              )}
            </Box>
          )
        }
        buttonLabel={buttonLabel}
        onClick={onClick}
        href={href}
      />
    </BaseGuardContainer>
  );
};
