import { makeStyles } from '@mui/styles';

export const useColorInputStyles = makeStyles(
  (theme) => ({
    button: {
      '&': {
        borderRadius: '4px 0 0 4px',
        height: 56,
        width: 56,
        minWidth: 56,
        padding: '0 !important',
      },
    },
    label: {
      borderRadius: '0 4px 4px 0',
      border: `1px solid ${theme.palette.custom.lightGrey}`,
      padding: '15px 78px 15px 24px',
      lineHeight: '24px',
      fontSize: '14px',
    },
    textPreviewWhite: {
      color: theme.palette.getContrastText(theme.palette.common.white),
      fontSize: '14px',
    },
    textPreviewBlack: {
      color: theme.palette.getContrastText(theme.palette.common.black),
      fontSize: '14px',
    },
  }),
  { name: 'colorInput' }
);
