import React, { FC, ReactNode } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, ButtonBase, Drawer as MuiDrawer, Stack, Typography } from '@mui/material';

export const Drawer: FC<{
  isOpen: boolean;
  onClose(): void;
  title?: ReactNode;
  description?: ReactNode;
}> = ({ title, description, isOpen, children, onClose }) => (
  <MuiDrawer anchor="right" open={isOpen} onClose={onClose}>
    <Stack direction="row" sx={{ width: 480 }}>
      <Box px={2} py={3}>
        <ButtonBase sx={{ width: 32, height: 32 }} onClick={onClose}>
          <CloseIcon />
        </ButtonBase>
      </Box>
      <Stack pt={3} pr={2} gap={3}>
        <Stack>
          {!!title && (
            <Typography variant="natter-display-xs" pb={0.5}>
              {title}
            </Typography>
          )}
          {!!description && (
            <Typography variant="natter-text-sm" color="grey.700">
              {description}
            </Typography>
          )}
        </Stack>
        {children}
      </Stack>
    </Stack>
  </MuiDrawer>
);
