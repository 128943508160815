import { useParams } from 'react-router-dom';

import { InsightsReportRouteParams } from 'router';

export const useCurrentReportId = () => {
  const { id } = useParams<InsightsReportRouteParams>();
  const reportId = Number(id);
  const isInvalidReportId = isNaN(reportId);

  return {
    reportId,
    isInvalidReportId,
  };
};
