import { Typography, styled } from '@mui/material';

export const ReportCardTitle = styled(Typography)(({ theme: { spacing, palette, typography } }) => ({
  ...typography['natter-text-lg'],

  fontWeight: 600,

  padding: spacing(1),
  borderRadius: spacing(0.5),
  textAlign: 'center',
  wordBreak: 'break-word',
  marginBottom: spacing(2),

  color: palette.common.white,
  background: palette.grey[600],
}));
