import { ERROR_MESSAGES } from 'common/constants';
import { logger } from 'common/services';
import { ApiErrors, assert, generateDynamicPath, isApiErrors } from 'common/utils';
import { ImageType } from 'domain/event';
import { union } from 'lodash';
import { find, flow, prop } from 'lodash/fp';
import {
  conformFormImages,
  filterOutEmptyAndAlreadyUploadedImages,
  mapAllEventWizardQuestionsToApi,
  mapImageToApi,
} from 'mappers/event';
import { goBack, push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { Effect } from 'store';
import { fetchAttachmentAsFile } from 'store/apis/attachments/utils';
import { selectMyCommunityId } from 'store/apis/community';
import { eventApi, selectActiveEventDetails } from 'store/apis/event';
import {
  addAppAlertDialog,
  addAppBackendErrorMessage,
  addAppErrorMessage,
  addAppSuccessMessage,
} from 'store/features/alerts';
import { handleQueryError } from 'store/utils';

import { eventWizardActions } from './eventWizard.actions';
import { selectEditEventId } from './eventWizard.selectors';

export const createEventEffect: Effect = ({ payload }, { dispatch, getState }) => {
  const communityId = selectMyCommunityId(getState());

  if (!communityId) {
    dispatch(addAppErrorMessage('Error occurred while creating an Event'));
    logger.error('Community ID missing while creating an Event');
    return;
  }

  dispatch(eventApi.endpoints.createEvent.initiate({ ...payload, communityId }));
};

export const createEventEntitiesEffect: Effect = async ({ meta, payload: event }, { dispatch }) => {
  const {
    arg: {
      originalArgs: { entrySurvey, nattersSetup, exitSurvey, eventImage, sponsors, defaultEventImage },
    },
  } = meta;

  const isThereAnyQuestion = !!(
    entrySurvey.questions.length ||
    nattersSetup.topics.length ||
    nattersSetup.questions.length ||
    exitSurvey.questions.length
  );

  try {
    const apiCalls = [];
    if (isThereAnyQuestion) {
      apiCalls.push(
        dispatch(
          eventApi.endpoints.createEventQuestions.initiate({
            id: event.id,
            questions: mapAllEventWizardQuestionsToApi({ entrySurvey, nattersSetup, exitSurvey }),
          })
        ).unwrap()
      );
    }

    const selectedImages = conformFormImages({ eventImage, sponsors });
    const imagesToUpload = filterOutEmptyAndAlreadyUploadedImages(selectedImages);

    if (!find({ imageType: ImageType.EventImage })(imagesToUpload) && defaultEventImage) {
      apiCalls.push(
        dispatch(
          eventApi.endpoints.postEventImage.initiate({
            id: event.id,
            imageType: ImageType.EventImage,
            image: await fetchAttachmentAsFile(defaultEventImage),
          })
        ).unwrap()
      );
    }

    apiCalls.push(
      ...imagesToUpload.map((imageObj) =>
        dispatch(
          eventApi.endpoints.postEventImage.initiate({
            id: event.id,
            ...mapImageToApi(imageObj),
          })
        ).unwrap()
      )
    );

    await Promise.all(apiCalls);

    dispatch(eventWizardActions.eventCreated());
  } catch (error) {
    dispatch(eventApi.endpoints.deleteEvent.initiate({ id: event.id }));

    if (isApiErrors(error)) {
      error.errors.forEach(({ message }) => {
        dispatch(addAppErrorMessage(message));
      });
    }
  }
};

const isMonthlyEventLimitReached = ({ errors }: ApiErrors) =>
  errors.some((error) => error.message.includes('Events per month limit'));

export const createEventFailedEffect: Effect = ({ payload }, { dispatch }) => {
  if (isApiErrors(payload.data)) {
    if (isMonthlyEventLimitReached(payload.data)) {
      dispatch(
        addAppAlertDialog({
          title: 'Monthly Event Limit Reached',
          description:
            'You have reached your monthly limit for creating Events. To create a new Event this month, you must either cancel one of your existing Events or wait until next month.',
          showCancelButton: true,
          cancelLabel: 'Close',
          confirmLabel: 'Go to My Events',
          actionToDispatchOnConfirm: eventWizardActions.eventLimitReachedModal.confirmedButton.clicked(),
        })
      );
    } else {
      (payload.data as ApiErrors).errors.forEach(({ message }) => {
        dispatch(addAppErrorMessage(message));
      });
    }
  } else {
    dispatch(addAppBackendErrorMessage(ERROR_MESSAGES.unknownServerError));
  }
};

export const goToMyEventsEffect: Effect = (_, { dispatch }) => {
  dispatch(push(AppRoutes.MyEvents));
};

export const updateEventEffect: Effect = async ({ payload }, { dispatch, getState }) => {
  const state = getState();
  const { eventImage, sponsors, entrySurvey, nattersSetup, exitSurvey, defaultEventImage } = payload;
  const eventId = selectEditEventId(state);
  const originalEvent = selectActiveEventDetails(state);

  try {
    assert(eventId, 'Event ID is not present');
    assert(originalEvent, 'Active Event details are not present');

    const selectedImages = conformFormImages({ eventImage, sponsors });
    const imagesToUpload = filterOutEmptyAndAlreadyUploadedImages(selectedImages);

    const apiCalls = [];

    if (!find({ imageType: ImageType.EventImage })(imagesToUpload) && defaultEventImage) {
      apiCalls.push(
        dispatch(
          eventApi.endpoints.postEventImage.initiate({
            id: eventId,
            imageType: ImageType.EventImage,
            image: await fetchAttachmentAsFile(defaultEventImage),
          })
        ).unwrap()
      );
    }

    imagesToUpload.forEach((imageObj) => {
      apiCalls.push(
        dispatch(eventApi.endpoints.postEventImage.initiate({ id: eventId, ...mapImageToApi(imageObj) })).unwrap()
      );
    });

    const uploadedImages = await Promise.all(apiCalls);

    const alreadyUploadedImageIds = selectedImages
      .map(flow(prop('image'), prop('id')))
      .filter(Boolean) as Array<number>;
    const newImageIds = uploadedImages.map(prop('id')) as Array<number>;
    const relatedImageIds = union(alreadyUploadedImageIds, newImageIds);

    const isThereAnyQuestion = !!(
      entrySurvey.questions.length ||
      nattersSetup.topics.length ||
      nattersSetup.questions.length ||
      exitSurvey.questions.length
    );
    if (isThereAnyQuestion) {
      dispatch(
        eventApi.endpoints.createEventQuestions.initiate({
          id: eventId,
          questions: mapAllEventWizardQuestionsToApi(payload),
        })
      );
    }

    await dispatch(eventApi.endpoints.updateEvent.initiate({ id: eventId, relatedImageIds, ...payload }));
  } catch (error) {
    handleQueryError(
      error,
      dispatch,
      'eventWizardListener.updateEventButton.clicked',
      'Error occurred while updating the Event'
    );
  }
};

export const updateEventFailedEffect: Effect = (_, { dispatch }) => {
  dispatch(addAppBackendErrorMessage(ERROR_MESSAGES.unknownServerError));
};

export const eventUpdated: Effect = (action, { dispatch, getState }) => {
  if (getState().router.location?.key) {
    dispatch(goBack());
  } else {
    dispatch(push(generateDynamicPath(AppRoutes.EventDetails, { id: action.meta.arg.originalArgs.id })));
  }

  dispatch(addAppSuccessMessage('Event successfully edited'));
};

export const eventVideoUploadFailedEffect: Effect = ({ payload: errorMessage }, { dispatch }) => {
  dispatch(addAppErrorMessage(errorMessage));
};

export const eventImageUploadFailedEffect: Effect = (_action, { dispatch }) => {
  dispatch(addAppErrorMessage('Error occurred while uploading some of the Event images'));
};

export const eventQuestionCreationFailedEffect: Effect = (_action, { dispatch }) => {
  dispatch(addAppErrorMessage('Error occurred while uploading some of the Event questions'));
};
