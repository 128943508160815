import React from 'react';

import { usePinContext } from 'common/guards/PinGuard/PinGuardContext';
import { EventQuestionAssignmentType } from 'domain/event';
import { SurveyInsights } from 'pages/InsightsReport';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { useAppSelector } from 'store';
import { useGetPublicEventsReportAnswersQuery } from 'store/apis/publicEventsReport';
import { selectDemographicFilters } from 'store/features/insightsReports';

export const createPublicEventSurveyInsightsReportPage =
  (assignmentType: EventQuestionAssignmentType, surveyName: string) => () => {
    const { reportId } = useCurrentReportId();
    const demographicFilters = useAppSelector(selectDemographicFilters);
    const pin = usePinContext();
    const shouldSkip = !pin;

    const { data } = useGetPublicEventsReportAnswersQuery(
      { reportId, communityTagIds: demographicFilters, assignmentType, pin },
      { skip: shouldSkip }
    );

    return <SurveyInsights surveyName={surveyName} data={data} />;
  };
