import { Container, Paper, Stack, styled } from '@mui/material';

export const ContentWrapper = styled(Container)(({ theme: { spacing } }) => ({
  display: 'flex',
  gap: spacing(4),
  padding: spacing(3, 11, 0, 4),
}));

export const SidePanelWrapper = styled('aside')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'sticky',
  top: spacing(3),
  height: '100%',
  flexShrink: 0,

  width: spacing(30),

  gap: spacing(4),
}));

export const RouterWrapper = styled(Stack)(() => ({
  width: '100%',
}));

export const RouterContent = styled(Paper)(({ theme: { spacing } }) => ({
  borderRadius: spacing(1),
}));
