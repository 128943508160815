import React, { FC, useCallback, useMemo } from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';

import { InsightsReportContext, InsightsReportContextValue } from 'pages/InsightsReport';
import { BasicInsightsReportRoutes } from 'pages/InsightsReport/constants';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import {
  useDeleteEventsReportMutation,
  useExportEventsReportMutation,
  useGetEventsReportByIdQuery,
  useGetEventsReportDemographicsQuery,
  useGetEventsReportKeyMetricsQuery,
  useUpdateEventsReportMutation,
} from 'store/apis/eventsReport';

import { EventReportRoutes, eventKeyMetrics } from './constants';

export const EventReportProvider: FC = ({ children }) => {
  const { reportId, isInvalidReportId } = useCurrentReportId();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();

  const {
    data: reportData,
    isError,
    isLoading,
  } = useGetEventsReportByIdQuery({ reportId }, { skip: isInvalidReportId });

  const { data: keyMetricsApiData, isLoading: isKeyMetricsDataLoading } = useGetEventsReportKeyMetricsQuery(
    { reportId },
    { skip: isInvalidReportId }
  );

  const { data: demographicData } = useGetEventsReportDemographicsQuery({ reportId }, { skip: isInvalidReportId });

  const [callDeleteReport, { isLoading: isDeleting }] = useDeleteEventsReportMutation();

  const [callExportReport, { isUninitialized: isExportedReportUninitialized }] = useExportEventsReportMutation();

  const deleteReport = useCallback(() => {
    if (!reportData) return;

    callDeleteReport(reportData);
  }, [callDeleteReport, reportData]);

  const [updateInsightReport] = useUpdateEventsReportMutation();

  const updateReportName = useCallback(
    (newName: string) => {
      if (!reportId) return;

      updateInsightReport({ id: reportId, name: newName });
    },
    [reportId, updateInsightReport]
  );

  const exportReport = useCallback(() => {
    callExportReport({ reportId });
  }, [callExportReport, reportId]);

  const shouldShowFilters = useMemo(
    () => !matchPath(pathname, path + BasicInsightsReportRoutes.keyMetricsAndParticipantDemographics.path),
    [pathname, path]
  );

  const keyMetricsData = useMemo(
    () =>
      eventKeyMetrics.map((metric) => ({
        ...metric,
        value: keyMetricsApiData?.[metric.key],
      })),
    [keyMetricsApiData]
  );
  const value: InsightsReportContextValue = useMemo(
    () => ({
      demographicData,
      isError: isInvalidReportId || isError,
      isLoading,
      reportData,
      routes: EventReportRoutes,
      shouldShowFilters,
      deleteReport,
      updateReportName,
      isDeleting,
      exportReport,
      isReportExported: !isExportedReportUninitialized,
      keyMetricsData,
      isKeyMetricsDataLoading,
      reportType: 'Event',
    }),
    [
      isError,
      isInvalidReportId,
      isLoading,
      demographicData,
      reportData,
      shouldShowFilters,
      deleteReport,
      updateReportName,
      isDeleting,
      exportReport,
      isExportedReportUninitialized,
      keyMetricsData,
      isKeyMetricsDataLoading,
    ]
  );

  return <InsightsReportContext.Provider value={value}>{children}</InsightsReportContext.Provider>;
};
