import { Paper, styled } from '@mui/material';

export const SummaryCard = styled(Paper)(({ theme: { spacing, typography } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(2),
  borderRadius: spacing(1),

  ...typography['natter-text-sm'],

  padding: spacing(2),
}));
