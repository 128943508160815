import React, { FC } from 'react';

import { Divider, Stack } from '@mui/material';

import { ReportSectionTitle } from './ReportSection.styled';

type Props = {
  title: string;
};

export const ReportSection: FC<Props> = ({ title, children }) => (
  <Stack component="section">
    <ReportSectionTitle>{title}</ReportSectionTitle>
    <Divider flexItem />
    {children}
  </Stack>
);
