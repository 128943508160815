import React, { PropsWithChildren, ReactNode, RefCallback, useCallback } from 'react';

import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectProps,
} from '@mui/material';
import { grey } from 'common/theme/palette';

import { FormFieldHint } from '../FormFieldHint';

export interface OptionItem<T = string | number> {
  label: string;
  value: T;
  disabled?: boolean;
  Icon?: ReactNode;
}

type SelectInputProps<T> = Pick<
  SelectProps<T>,
  'label' | 'placeholder' | 'displayEmpty' | 'disabled' | 'size' | 'onChange' | 'startAdornment'
> & {
  name?: string;
  options: OptionItem<T>[];
  value: T;
  shouldShowError?: boolean;
  'data-testid'?: string;
  error?: { message?: string };
  selectRef?: RefCallback<HTMLSelectElement>;
};

export const Select = <T = string,>({
  name,
  label,
  options,
  placeholder,
  displayEmpty,
  error,
  selectRef,
  value,
  onChange,
  disabled,
  size,
  shouldShowError = true,
  startAdornment,
  'data-testid': dataTestId,
}: PropsWithChildren<SelectInputProps<T>>) => {
  const selectedLabel = useCallback(
    (selected) => options.find((option) => option.value === selected)?.label,
    [options]
  );
  const selectedValueIcon = options.find((option) => option.value === value)?.Icon;

  return (
    <FormControl error={!!error} fullWidth>
      {!displayEmpty && (
        <InputLabel
          id={`${name}-label`}
          size={size === 'small' ? 'small' : 'normal'}
          sx={{
            '&.Mui-error': {
              color: grey[400],
            },
          }}
        >
          {label}
        </InputLabel>
      )}
      <MuiSelect
        label={displayEmpty ? undefined : label}
        startAdornment={startAdornment ?? selectedValueIcon}
        data-testid={dataTestId}
        fullWidth
        displayEmpty={displayEmpty}
        labelId={`${name}-label`}
        ref={selectRef}
        value={value}
        renderValue={(selected) =>
          typeof selected === 'string' && selected?.length === 0 ? placeholder : selectedLabel(selected)
        }
        onChange={onChange}
        error={!!error}
        disabled={disabled}
        size={size}
        placeholder={placeholder}
      >
        {options.map((option: OptionItem<T>) => (
          <MenuItem
            key={`${option.value}${option.label}`}
            value={option.value as string}
            disabled={option.value === undefined || option.value === null || option.disabled}
          >
            {option?.Icon && <ListItemIcon sx={{ color: 'inherit' }}>{option?.Icon}</ListItemIcon>}
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </MuiSelect>
      {shouldShowError && <FormFieldHint error>{error?.message}</FormFieldHint>}
    </FormControl>
  );
};
