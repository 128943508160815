import React, { FC, useEffect, useMemo, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { Alert, lighten } from '@mui/material';
import { ERROR_MESSAGES } from 'common/constants';
import { isApiErrors, isApiResponse } from 'common/utils';
import { hasData, hasValidationErrors } from 'modules/api/utils';

export const ErrorBox: FC<{
  error?: unknown | undefined;
  disposable?: boolean;
  fullWidth?: boolean;
}> = ({ error, disposable = false, fullWidth = false, children }) => {
  const [showAlert, setShowAlert] = useState(false);

  const errorMessage = useMemo(() => {
    if (!error) return;
    const newErrorMessage = hasData(error) ? error.data : error;
    if (typeof newErrorMessage === 'string') {
      return newErrorMessage;
    }
    if (hasValidationErrors(newErrorMessage)) {
      return Object.values(newErrorMessage.validationErrors).join(' ');
    }
    if (isApiResponse(error) && isApiErrors(error.data)) {
      return error.data.errors.map(({ message }) => message).join('\n');
    }
    return ERROR_MESSAGES.unknownError;
  }, [error]);

  const handleClose = disposable ? () => setShowAlert(false) : undefined;

  useEffect(() => {
    setShowAlert(!!(error || children));
  }, [error, children]);

  return showAlert ? (
    <Alert
      severity="error"
      sx={({ palette }) => ({
        width: fullWidth ? '100%' : undefined,
        border: '1px solid',
        borderColor: palette.error.main,
        background: lighten(palette.error.main, 0.9),
        borderRadius: '4px',
        color: palette.common.black,
        fontSize: '14px',
        '& .MuiSvgIcon-root': {
          width: 18,
        },
      })}
      icon={<CancelIcon />}
      onClose={handleClose}
    >
      {errorMessage || children}
    </Alert>
  ) : null;
};

export default ErrorBox;
