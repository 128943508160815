import React from 'react';

import { Box, Typography, TypographyProps, styled } from '@mui/material';

export const SocialMediaSwitchContainer = styled(Box, { name: 'SocialMediaSwitchContainer' })(
  ({ theme: { spacing } }) => ({
    width: spacing(18.75),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
  })
);

export const SocialMediaSwitchLabel = styled(
  ({ children, ...props }: TypographyProps) => (
    <Typography variant="bodySmall" {...props}>
      {children}
    </Typography>
  ),
  { name: 'SocialMediaSwitchLabel' }
)<TypographyProps>(({ theme: { spacing } }) => ({
  marginLeft: spacing(1),
  textTransform: 'capitalize',
}));
