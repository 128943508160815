import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { type Components, type Theme } from '@mui/material';

import { blue, green, red } from '../palette';

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  defaultProps: {
    variant: 'standard',
    iconMapping: {
      success: <CheckCircleIcon fontSize="inherit" />,
      error: <ErrorIcon fontSize="inherit" />,
      warning: <WarningIcon fontSize="inherit" />,
      info: <InfoIcon fontSize="inherit" />,
    },
  },
  styleOverrides: {
    root: ({ theme: { spacing, typography, palette } }) => ({
      border: 'none',
      borderRadius: 0,
      padding: spacing(2),
      color: palette.text.primary,

      ...typography['natter-text-sm'],

      '& .MuiAlertTitle-root': {
        fontWeight: 600,
      },
    }),
    icon: ({ theme: { spacing } }) => ({
      fontSize: 20,
      marginRight: spacing(1),
      padding: 0,
      justifyContent: 'center',
    }),
    action: {
      paddingTop: 0,
      marginRight: 0,
    },
    message: {
      padding: 0,
    },
    standardSuccess: {
      backgroundColor: green[50],
      color: 'inherit',

      '& .MuiAlert-icon': {
        color: green[600],
      },
    },
    standardError: {
      backgroundColor: red[50],
      color: 'inherit',

      '& .MuiAlert-icon': {
        color: red[600],
      },
    },
    standardInfo: {
      backgroundColor: blue[50],

      '.MuiAlert-icon': {
        color: blue[600],
      },
    },
  },
};
