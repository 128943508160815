import { createApi, retry } from '@reduxjs/toolkit/query/react';
import type { ImageFile } from 'common/components';
import { assert, getImageFormData } from 'common/utils';
import type { Community, CommunityFormValues, UpdateCommunityData } from 'domain/Community';
import { mapCommunityToApi } from 'mappers/community';

import axiosBaseQuery from '../_axiosBaseQuery';
import type { GetManagedOrganizationApiPayload, WithOrganizationId } from './types';

export const organizationIdErrorMessage = 'Organization ID required';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  tagTypes: ['COMMUNITIES', 'COMMUNITY', 'COMMUNITY_MEMBERS', 'ORGANIZATION_MEMBERS'],
  baseQuery: retry(axiosBaseQuery(), { maxRetries: 0 }),
  endpoints: (builder) => ({
    getManagedOrganizations: builder.query<GetManagedOrganizationApiPayload, void>({
      query: () => ({
        url: '/organization/managed',
      }),
    }),
    joinOrganization: builder.mutation<void, { organizationId: number | undefined }>({
      query: ({ organizationId }) => {
        assert(organizationId, organizationIdErrorMessage);
        return {
          url: `/organization/${organizationId}/join`,
          method: 'POST',
        };
      },
    }),
    createCommunity: builder.mutation<Community, { values: CommunityFormValues } & WithOrganizationId>({
      invalidatesTags: ['COMMUNITIES'],
      query: ({ organizationId, values }) => {
        assert(organizationId, organizationIdErrorMessage);
        // TODO remove this when BE stop support P2P insights in settings
        const communitySettings = {
          ...values.communitySettings,
          p2pInsightsPerMonthLimit: 0,
          usersPerP2PInsightLimit: 0,
        };
        return {
          url: `/organization/${organizationId}/community`,
          method: 'POST',
          data: mapCommunityToApi({ ...values, communitySettings }),
        };
      },
    }),
    updateCommunity: builder.mutation<Community, UpdateCommunityData>({
      invalidatesTags: (_result, _error, arg) => [
        { type: 'COMMUNITIES', id: arg.id },
        { type: 'COMMUNITY', id: arg.id },
      ],
      query: (data) => ({
        url: `/community/${data.id}`,
        method: 'PUT',
        data: mapCommunityToApi(data),
      }),
    }),
    uploadCommunityLogo: builder.mutation<
      Pick<Community, 'logoImageLink' | 'logoImageLinkSmall'>,
      { logo: ImageFile; communityId: number }
    >({
      invalidatesTags: (_result, _error, { communityId }) =>
        _error
          ? []
          : [
              { type: 'COMMUNITIES', id: communityId },
              { type: 'COMMUNITY', id: communityId },
            ],
      query: ({ communityId, logo }) => ({
        url: `/community/${communityId}/uploadLogo`,
        method: 'POST',
        data: getImageFormData(logo),
      }),
    }),
  }),
});

export const {
  useCreateCommunityMutation,
  useGetManagedOrganizationsQuery,
  useJoinOrganizationMutation,
  useUpdateCommunityMutation,
  useUploadCommunityLogoMutation,
} = organizationApi;
