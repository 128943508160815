import { InferType, mixed, number, object, string } from 'yup';

import type { Status } from '../UploadPreview/types';

export const imageFile = object({
  id: number().optional(),
  data: string(),
  name: string(),
  size: number(),
  url: string(),
  type: string(),
  status: mixed<Status>(),
  progress: number(),
}).default(undefined);

export type ImageFile = InferType<typeof imageFile>;

export const isSelectedImageFile = (file: ImageFile): boolean => !!file.data && file.data.startsWith('data:');

export const isRemoteImageFile = (file: ImageFile): boolean => !!file.data && string().url().isValidSync(file.data);
