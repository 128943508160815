export enum AnswerStatisticsType {
  TextAnswerStatistics = 'TextAnswerStatistics',
  MultipleChoiceSingleSelectAnswerStatistics = 'MultipleChoiceSingleSelectAnswerStatistics',
  MultipleChoiceMultipleSelectAnswerStatistics = 'MultipleChoiceMultipleSelectAnswerStatistics',
  RankingAnswerStatistics = 'RankingAnswerStatistics',
  SliderAnswerStatistics = 'SliderAnswerStatistics',
  NPSAnswerStatistics = 'NPSAnswerStatistics',
}

export type BasicAnswerStatistics = {
  questionIds: number[];
  title: string;
  type: AnswerStatisticsType;
};

export type TextAnswerTheme = {
  themeId: number;
  theme: string;
  // TODO remove elvis operator after BE implements description: https://natterco.atlassian.net/browse/N2-2241
  description?: string;
  count: number;
  isMerged: boolean;
  isHidden: boolean;
  mergedThemes: {
    id: number;
    name: string;
  }[];
};

export type TextAnswerStatistics = {
  themes: TextAnswerTheme[];
} & BasicAnswerStatistics;

export type MultipleChoiceSingleSelectAnswerStatistics = {
  answersPerChoice: Record<string, number>;
} & BasicAnswerStatistics;

export type MultipleChoiceMultipleSelectAnswerStatistics = {
  answersPerChoice: Record<string, number>;
} & BasicAnswerStatistics;

export type RankingAnswerStatistics = {
  answersPerChoice: Record<string, Record<number, number>>;
} & BasicAnswerStatistics;

export type SliderAnswerStatistics = {
  answersPerValue: Record<number, number>;
} & BasicAnswerStatistics;

export type NPSAnswerStatistics = {
  title: string;
  answersPerValue: Record<number, number>;
} & BasicAnswerStatistics;

export type AnswerStatistics =
  | TextAnswerStatistics
  | MultipleChoiceSingleSelectAnswerStatistics
  | MultipleChoiceMultipleSelectAnswerStatistics
  | RankingAnswerStatistics
  | SliderAnswerStatistics
  | NPSAnswerStatistics;

export const isTextAnswerStatistics = (answerStatistics: AnswerStatistics): answerStatistics is TextAnswerStatistics =>
  answerStatistics.type === AnswerStatisticsType.TextAnswerStatistics;

export const isMultipleChoiceSingleSelectAnswerStatistics = (
  answerStatistics: AnswerStatistics
): answerStatistics is MultipleChoiceSingleSelectAnswerStatistics =>
  answerStatistics.type === AnswerStatisticsType.MultipleChoiceSingleSelectAnswerStatistics;

export const isMultipleChoiceMultipleSelectAnswerStatistics = (
  answerStatistics: AnswerStatistics
): answerStatistics is MultipleChoiceMultipleSelectAnswerStatistics =>
  answerStatistics.type === AnswerStatisticsType.MultipleChoiceMultipleSelectAnswerStatistics;

export const isRankingAnswerStatistics = (
  answerStatistics: AnswerStatistics
): answerStatistics is RankingAnswerStatistics =>
  answerStatistics.type === AnswerStatisticsType.RankingAnswerStatistics;

export const isSliderAnswerStatistics = (
  answerStatistics: AnswerStatistics
): answerStatistics is SliderAnswerStatistics => answerStatistics.type === AnswerStatisticsType.SliderAnswerStatistics;

export const isNPSAnswerStatistics = (answerStatistics: AnswerStatistics): answerStatistics is NPSAnswerStatistics =>
  answerStatistics.type === AnswerStatisticsType.NPSAnswerStatistics;
