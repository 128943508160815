import { useEffect } from 'react';

import { useConfirmationModal } from 'common/hooks';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { UpdateThemesVisibilityPayload, useUpdateThemesVisibilityMutation } from 'store/apis/insightsReport';

import { HideThemesFormValues } from './schema';

export const useHideThemes = ({ onClose, onOpen }: { onClose(): void; onOpen(): void }) => {
  const { reportId } = useCurrentReportId();
  const [updateThemesVisibility, { isLoading, isSuccess, isError, reset }] = useUpdateThemesVisibilityMutation();
  const [openRenameFailureModal, RenameFailureModal] = useConfirmationModal(
    {
      cancelLabel: 'Back to Report',
      confirmLabel: 'Try again',
      title: 'Hiding unsuccessful',
      description: `The Theme you have selected couldn’t be hidden. Try again or contact support@natter.co if the issue persists.`,
      severity: 'danger',
      variant: 'confirmation',
      maxWidth: 'xs',
    },
    () => {
      onOpen();
    }
  );

  const handleSubmit = (values: HideThemesFormValues) => {
    updateThemesVisibility({
      reportId,
      themeIdsToHideStatus: values.themes.reduce(
        (themeIdsToHideStatusMap, theme) => {
          themeIdsToHideStatusMap[theme.themeId] = theme.isHidden;
          return themeIdsToHideStatusMap;
        },
        {} as UpdateThemesVisibilityPayload['themeIdsToHideStatus']
      ),
    });
  };

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  useEffect(() => {
    if (!isSuccess) return;

    onClose();
    reset();
  }, [isSuccess, onClose, reset]);

  useEffect(() => {
    if (!isError) return;

    onClose();
    openRenameFailureModal();
    reset();
  }, [isError, onClose, openRenameFailureModal, reset]);

  return {
    handleClose,
    handleSubmit,
    RenameFailureModal,
    isLoading,
  };
};
