import React, { FC, useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';
import { Button, DragProvider, SortableItem } from 'common/components';
import { MobileDragLayer } from 'common/components/DragAndDrop';
import { KnownTagsCategoryTypes, type TagsCategory as TagsCategoryType } from 'domain/Common';

import { TagsCategory } from '.';
import { TagsCategoriesFormValues } from '../schema';
import { TagsCategoryPreview } from './TagsCategoryPreview';

const MAX_CATEGORIES = 5;

type TagsCategoryListProps = {
  optionalCategory: TagsCategoryType | undefined;
};

export const TagsCategoryList: FC<TagsCategoryListProps> = ({ optionalCategory }) => {
  const methods = useFormContext<TagsCategoriesFormValues>();

  const {
    fields: categories,
    move,
    append,
    remove,
  } = useFieldArray({
    name: 'tagsCategories',
    control: methods.control,
  });

  const mandatoryCategories = useMemo(() => categories.filter(({ isOptional }) => !isOptional), [categories]);

  const showAddOptionalCategoryButton = categories.length < MAX_CATEGORIES;

  const addOptionalCategory = () => {
    append({
      ...(optionalCategory || {}),
      type: KnownTagsCategoryTypes.Additional,
      isSingleSelection: false,
      isOptional: true,
      name: '',
      tags: [],
    });
  };

  const handleRemoveOptionalCategory = () => {
    remove(categories.length - 1);
  };

  return (
    <DragProvider>
      <Stack gap={3}>
        <Stack gap={3}>
          {mandatoryCategories.map(({ id }, i) => (
            <SortableItem
              key={id}
              droppableType="TagsCategory"
              index={i}
              moveItem={move}
              collectItemData={() => methods.getValues(`tagsCategories.${i}`)}
            >
              {({ handleRef }) => <TagsCategory name={`tagsCategories.${i}`} index={i} handleRef={handleRef} />}
            </SortableItem>
          ))}
        </Stack>

        {showAddOptionalCategoryButton ? (
          <Button
            variant="light"
            size="large"
            data-testid="CommunityWizard-add-optional-category"
            onClick={addOptionalCategory}
          >
            + Add Optional Category
          </Button>
        ) : (
          <TagsCategory
            name={`tagsCategories.${categories.length - 1}`}
            onRemoveCategory={handleRemoveOptionalCategory}
          />
        )}
      </Stack>
      <MobileDragLayer<TagsCategoryType> droppableType="TagsCategory" Preview={TagsCategoryPreview} />
    </DragProvider>
  );
};
