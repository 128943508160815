import { BasicChartData } from '../../../types';
import { distributeFrequency, initFrequencyDistributionData } from '../../../utils';

export const calculateDensityPlotData = ({
  data,
  dataKeys = ['value'],
}: {
  data: BasicChartData;
  dataKeys?: string[];
}): BasicChartData => {
  const xAxisValues = data.map((d) => Number(d.name)).filter((value) => !isNaN(value));
  const xAxisDomain: [number, number] = [Math.min(...xAxisValues), Math.max(...xAxisValues)];

  const { histogramData } = initFrequencyDistributionData({
    dataKeys,
    xAxisDomain,
    calculateTickName: (tick, nextTick) => Math.round(tick + (nextTick - tick) / 2),
  });

  distributeFrequency({
    initialData: data,
    histogramData,
    dataKeys,
  });

  histogramData.pop();
  return [{ name: xAxisDomain[0], value: 0 }, ...histogramData, { name: xAxisDomain[1], value: 0, x0: xAxisDomain[1] }];
};
