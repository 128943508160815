import { BasicChartData } from '../../../types';
import { distributeFrequency, initFrequencyDistributionData } from '../../../utils';

export const calculateHistogramData = ({
  data,
  dataKeys = ['value'],
}: {
  data: BasicChartData;
  dataKeys?: string[];
}): BasicChartData => {
  const xAxisValues = data.map((d) => Number(d.name)).filter((value) => !isNaN(value));
  const xAxisDomain: [number, number] = [Math.min(...xAxisValues), Math.max(...xAxisValues)];

  const { histogramData } = initFrequencyDistributionData({
    dataKeys,
    xAxisDomain,
    calculateTickName: (tick) => tick,
  });

  distributeFrequency({
    initialData: data,
    histogramData,
    dataKeys,
  });

  return histogramData;
};
