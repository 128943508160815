import { BasicInsightsReportRoutes } from 'pages/InsightsReport/constants';

import { ActionableInsights, ResultsAnalysis } from '../components';

export const PublicSurveyReportRoutes = [
  BasicInsightsReportRoutes.keyMetricsAndParticipantDemographics,
  {
    title: 'Results Analysis',
    path: '/results-analysis',
    render: ResultsAnalysis,
  },
  {
    title: 'Actionable Insights',
    path: '/actionable-insights',
    render: ActionableInsights,
  },
];
