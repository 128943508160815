import React, { FC } from 'react';

import { CopyButton } from '../CopyButton';
import { TextField } from '../Inputs';

export const CopyTextField: FC<{
  text: string;
}> = ({ text }) => (
  <TextField
    value={text}
    InputProps={{
      title: text,
      readOnly: true,
      endAdornment: <CopyButton value={text} />,
      sx: ({ spacing }) => ({ padding: spacing(1, 2) }),
    }}
    fullWidth
  />
);
