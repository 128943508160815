import { useCallback, useEffect } from 'react';

import { useConfirmationModal } from 'common/components';
import { useAppDispatch } from 'store';
import { useDeleteEventMutation } from 'store/apis/event';
import { addAppAlertDialog } from 'store/features/alerts';

export const useEventDelete = ({ eventId, onSuccess }: { eventId: number | undefined; onSuccess?(): void }) => {
  const dispatch = useAppDispatch();
  const [deleteEvent, { isSuccess }] = useDeleteEventMutation();

  const handleDelete = useCallback(() => {
    if (!eventId) return;

    deleteEvent({ id: eventId });
  }, [eventId, deleteEvent]);

  const [openDeleteEventConfirmationModal, DeleteEventConfirmationModal] = useConfirmationModal(
    {
      title: 'Delete Event',
      content: 'Are you sure you want to delete this Event?',
      confirmColor: 'error',
      confirmLabel: 'Delete',
    },
    handleDelete
  );

  useEffect(() => {
    if (!isSuccess) return;

    dispatch(
      addAppAlertDialog({
        title: 'Event Successfully Deleted',
        description: 'This Event was successfully deleted and removed from your Events list.',
      })
    );
    onSuccess?.();
  }, [dispatch, onSuccess, isSuccess]);

  return {
    openDeleteEventConfirmationModal,
    DeleteEventConfirmationModal,
  };
};
