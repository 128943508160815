import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack, styled } from '@mui/material';

export const GuestItemContainer = styled(Stack, { name: 'GuestItemContainer' })(({ theme: { palette, spacing } }) => ({
  gap: spacing(2),
  flexDirection: 'row',
  alignItems: 'center',
  height: 56,
  borderBottom: 'solid 1px',
  borderColor: palette.custom.lightGrey,
}));

export const RemoveGuestIcon = styled(FontAwesomeIcon, { name: 'RemoveGuestIcon' })(
  ({ theme: { palette, spacing } }) => ({
    color: palette.secondary.main,
    marginRight: spacing(1.5),
    cursor: 'pointer',
  })
);
