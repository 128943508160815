import React, { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { InputAdornment, Slider, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import _get from 'lodash/get';

import { InputWrapper } from '../InputWrapper';
import { OptionItem } from '../SelectInput/SelectInput';
import { useTimeDateInputStyles } from './TimeDateInput.styles';
import { getTimeOptions } from './timeOptions';

export type TimeDateInputProps = {
  name: string;
  hint: string;
  label: string;
  autoWidth?: boolean;
};

const options = getTimeOptions(15);

const dateWatchDefaultValue = '2022-01-01T00:00:00+00:00';
const timeWatchDefaultValue = '00:00';
const durationWatchDefaultValue = 20;

export const TimeDateInput: FC<TimeDateInputProps> = ({ name, hint, label, ...props }) => {
  const methods = useFormContext();
  const classes = useTimeDateInputStyles();
  const dateWatch = methods.watch(`${name}.date`);
  const timeWatch = methods.watch(`${name}.time`);
  const durationWatch = methods.watch(`${name}.duration`);

  useEffect(() => {
    methods.register(`${name}.date`);
    methods.register(`${name}.time`);
    methods.register(`${name}.duration`);
  }, [methods, name]);

  const setDateTime = useCallback(
    (date: string | number | Date, time: string) => {
      const dateToSet = new Date(date);
      const [hours, minutes] = time.split(':');

      dateToSet.setHours(Number(hours));
      dateToSet.setMinutes(Number(minutes));

      methods.setValue(`${name}.date`, dateToSet);
      methods.setValue(`${name}.time`, time);
    },
    [name, methods]
  );

  return (
    <InputWrapper label={label} hint={hint} name={name} {...props}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className={classes.dateTimeRoot}>
          <DatePicker
            disablePast
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FontAwesomeIcon className={classes.adornment} size="lg" icon={faCalendarAlt} />
                </InputAdornment>
              ),
            }}
            inputFormat="EEE dd MMM yyyy"
            value={dateWatch || dateWatchDefaultValue}
            onChange={(value: string | number | Date): void => {
              setDateTime(value, timeWatch);
            }}
            renderInput={(params: Record<string, unknown>) => (
              <TextField style={{ marginRight: 10 }} data-testid="datePicker" {...params} />
            )}
          />
          <InputWrapper name={`${name}.time`}>
            <TextField
              select
              variant="outlined"
              error={!!_get(methods.formState.errors, `${name}.time`)}
              onChange={(event): void => {
                setDateTime(dateWatch, event.target.value);

                if (_get(methods.formState.errors, `${name}.time`)) {
                  methods.clearErrors(name);
                }
              }}
              fullWidth
              value={timeWatch || timeWatchDefaultValue}
              inputProps={{
                'data-testid': 'timePicker',
              }}
            >
              {options.map((option: OptionItem) => (
                <MenuItem key={option.value ?? ''} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </InputWrapper>
        </div>
      </LocalizationProvider>
      <Typography variant="body1" className={classes.durationLabel} data-testid="duration">
        Duration: {durationWatch} minutes
      </Typography>
      <Slider
        min={20}
        max={120}
        step={5}
        marks
        value={durationWatch || durationWatchDefaultValue}
        className={classes.slider}
        onChange={(event, newValue): void => {
          if (durationWatch !== newValue) {
            methods.setValue(`${name}.duration`, newValue);
          }
        }}
      />
    </InputWrapper>
  );
};
