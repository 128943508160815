import React, { FC } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import { Box, Checkbox, Stack, Typography } from '@mui/material';
import { ErrorBox } from 'common/components';
import { type OptionItem, SelectInput } from 'common/components/_legacy/Form';
import { UserPrivilege, UserRoleName, userPrivilegesList, userRolesList } from 'domain/UserProfile';
import { xorWith } from 'lodash';

export const roleOptions: OptionItem<UserRoleName>[] = userRolesList.map((role) => ({
  value: role.key,
  label: role.label,
}));

const privilegeOptions: OptionItem<UserPrivilege>[] = userPrivilegesList.map(({ id, key: name, label, disabled }) => ({
  value: { id, name },
  label,
  disabled,
}));

export const MemberPrivileges: FC = () => {
  const { setValue } = useFormContext();
  const privileges: UserPrivilege[] = useWatch({ name: 'privileges' });
  const communityRole: UserRoleName = useWatch({ name: 'communityRole' });
  const { errors } = useFormState({ name: 'privileges' });

  const handleChange = (privilege: UserPrivilege) => () => {
    const selectedPrivileges = xorWith(privileges, [privilege], (a, b) => a.id === b.id);
    setValue('privileges', selectedPrivileges, { shouldValidate: true });
  };

  return (
    <>
      <Box mt={1}>
        <SelectInput
          name="communityRole"
          label="Role"
          placeholder="Role"
          options={roleOptions}
          centered={false}
          autoWidth
        />
      </Box>
      {errors.privileges && <ErrorBox sx={{ mt: 4 }}>{errors.privileges.message}</ErrorBox>}
      <Stack mt={4}>
        <Typography variant="h4Bold">Actions Access</Typography>
        <Stack>
          {privilegeOptions.map(({ value: privilege, label, disabled }, index) => {
            const isDisabled = communityRole !== UserRoleName.Custom || disabled;

            return (
              <Stack
                direction="row"
                key={privilege.name}
                alignItems="center"
                justifyContent="space-between"
                sx={({ palette }) => ({
                  borderBottom: index < privilegeOptions.length - 1 ? '1px solid' : 'none',
                  borderColor: palette.custom.lightGrey,
                  cursor: isDisabled ? 'default' : 'pointer',
                })}
                onClick={isDisabled ? undefined : handleChange(privilege)}
              >
                <Typography color={isDisabled ? 'text.disabled' : undefined}>{label}</Typography>
                <Checkbox
                  disabled={isDisabled}
                  checked={privileges.some(({ id }) => id === privilege.id)}
                  onChange={handleChange(privilege)}
                />
              </Stack>
            );
          })}
        </Stack>
      </Stack>
    </>
  );
};
