import { KnownTagsCategoryTypes, TagsCategory } from 'domain/Common';

import { TagsCategoriesFormValues } from './schema';

export const mapTagsCategoriesToDefaultValues = (
  tagsCategories: TagsCategory[]
): TagsCategoriesFormValues['tagsCategories'] =>
  tagsCategories
    .filter(({ tags, type }) => !(type === KnownTagsCategoryTypes.Additional && tags.length === 0))
    .map(({ type, ...rest }) => {
      const isOptional = type === KnownTagsCategoryTypes.Additional;
      return {
        ...rest,
        type,
        isOptional,
      };
    });
