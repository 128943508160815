import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { faFacebookSquare, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { Stack } from '@mui/material';
import { socialMediaColors } from 'theme/palette';

import { InputWrapper } from '../InputWrapper';
import { SocialMediaSwitch } from './components';

export type SocialMediaToggleInputProps = {
  name: string;
  hint: string;
  label: string;
  autoWidth?: boolean;
};

const socialMedias = [
  { name: 'facebook', icon: faFacebookSquare, color: socialMediaColors.facebook },
  { name: 'twitter', icon: faTwitter, color: socialMediaColors.twitter },
  { name: 'instagram', icon: faInstagram, color: socialMediaColors.instagram },
  { name: 'linkedin', icon: faLinkedin, color: socialMediaColors.linkedin },
];

export const SocialMediaToggleInput: FC<SocialMediaToggleInputProps> = ({ name, hint, label, ...props }) => {
  const { register } = useFormContext();

  useEffect(() => {
    register(`${name}.facebook`);
    register(`${name}.twitter`);
    register(`${name}.instagram`);
    register(`${name}.linkedin`);
  }, [register, name]);

  return (
    <InputWrapper label={label} hint={hint} name={name} {...props}>
      <Stack direction="row" flexWrap="wrap" data-testid="social-media-switch-container">
        {socialMedias.map((socialMedia, index) => (
          <SocialMediaSwitch key={index} fieldName={name} icon={socialMedia} />
        ))}
      </Stack>
    </InputWrapper>
  );
};
