import { DEFAULT_TIME_STEPS_IN_MINUTES } from 'common/components/Inputs/TimePicker/TimePicker';

export const DEFAULT_EVENT_DESCRIPTION =
  "Welcome. If you're a first-time user, register an account on the platform ahead of the scheduled start time. As you join the Event, ensure you allow Natter access to your camera and microphone when prompted to do so by your browser.";

export const EVENT_MIN_DURATION_IN_MINUTES = 5;

export const EVENT_DEFAULT_DURATION_IN_MINUTES = DEFAULT_TIME_STEPS_IN_MINUTES;

export enum STEPS {
  EventDetails,
  EntrySurvey,
  NattersSetup,
  ExitSurvey,
  Summary,
}
