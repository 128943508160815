import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { selectUserAccountId } from 'store/apis/user';

import { roomExit_WS } from '../event';
import { answerUpdated_WS } from './actions';
import { clearAnswers, clearUpdatedByUserId } from './answersSlice';
import { clearQuestionAssignment } from './questionAssignmentSlice';

export const CLEAR_UPDATED_BY_USER_ID_TIMEOUT = 5000;
export const questionsListener = createListenerMiddleware<RootState>();

questionsListener.startListening({
  actionCreator: roomExit_WS,
  effect: (_, { dispatch }) => {
    dispatch(clearAnswers());
    dispatch(clearQuestionAssignment());
  },
});

const clearEditingLabelDelayedEffects = new Map<string, NodeJS.Timeout>();
questionsListener.startListening({
  actionCreator: answerUpdated_WS,
  effect: async ({ payload: { answer } }, { dispatch, getState }) => {
    const state = getState();
    const { uuid: answerId, updatedByUserId } = answer;
    const userId = selectUserAccountId(state);
    const isUpdatedByCurrentUser = userId === updatedByUserId;

    const existingEffect = clearEditingLabelDelayedEffects.get(answerId);
    if (existingEffect) {
      clearTimeout(existingEffect);
      clearEditingLabelDelayedEffects.delete(answerId);
    }

    if (isUpdatedByCurrentUser) return;

    const newEffect = setTimeout(() => {
      dispatch(clearUpdatedByUserId(answerId));
      clearEditingLabelDelayedEffects.delete(answerId);
    }, CLEAR_UPDATED_BY_USER_ID_TIMEOUT);

    clearEditingLabelDelayedEffects.set(answerId, newEffect);
  },
});
