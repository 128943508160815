import { createContext, useContext } from 'react';

import { assert } from 'common/utils';

export const PinGuardContext = createContext<string | undefined>(undefined);

export const usePinContext = () => {
  const ctx = useContext(PinGuardContext);
  assert(ctx, 'usePinContext must be used inside PinGuard');
  return ctx;
};
