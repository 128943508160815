import React, { CSSProperties, ChangeEvent, FC } from 'react';

import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, SxProps, TextField, TextFieldProps } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

type Props = Pick<TextFieldProps, 'size'> & {
  placeholder?: string;
  onSearchDebounced(searchedTerm: string): void;
  backgroundColor?: CSSProperties['backgroundColor'];
  sx?: SxProps;
};

export const SearchBox: FC<Props> = ({ onSearchDebounced, placeholder = 'Search', size, backgroundColor, sx }) => {
  const action = AwesomeDebouncePromise((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchedTerm: string = event.target.value.trim();
    onSearchDebounced(searchedTerm);
  }, 500);

  return (
    <TextField
      size={size}
      variant="outlined"
      onChange={action}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize={size} />
          </InputAdornment>
        ),
        sx: ({ spacing, palette }) => ({
          backgroundColor,
          paddingLeft: spacing(1),
          '& > .MuiInputAdornment-root': {
            paddingRight: spacing(0.5),
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.grey[300],
            borderRadius: '5px',
          },
          '&.MuiInputBase-sizeSmall': {
            height: 32,
          },
        }),
      }}
      inputProps={{ 'aria-label': 'Search' }}
      placeholder={placeholder}
      sx={sx}
      data-testid="MyEvents-search-input"
    />
  );
};
