import React, { FC, useMemo } from 'react';

import { useElementSize } from 'common/hooks';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import { CHART_BAR_MAX_WIDTH } from '../../constants';
import { useChartAxis, useChartColors, useChartStyles, useDynamicChartDimensions, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateTicks, mapToStackedChartValues } from '../../utils';

export const HorizontalStackedBarChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height,
  TooltipContent,
}) => {
  const classes = useChartStyles();
  const { getColor } = useChartColors();
  const { tickStyles, renderAxisYLabel, renderAxisXLabel, axisLineStyles, tickLineStyles } = useChartAxis();
  const { activeIndex, handleMouseEnter, hideTooltip } = useTooltip({ initialActiveIndex: -1 });
  const stackedValues = useMemo(() => mapToStackedChartValues({ data, dataKeys }), [data, dataKeys]);
  const ticks = useMemo(() => calculateTicks({ data: stackedValues }), [stackedValues]);
  const [ref, size] = useElementSize();
  const { yAxisWidth, chartHeight } = useDynamicChartDimensions({
    data,
    chartWidth: size.width,
    chartHeight: height,
  });

  return (
    <ResponsiveContainer debounce={300} width="100%" height={chartHeight} ref={ref}>
      <BarChart
        data={data}
        margin={{ right: 50, left: 50, bottom: 40 }}
        className={classes.verticalChart}
        layout="vertical"
      >
        <CartesianGrid strokeDasharray="1 1" horizontal={false} />
        <YAxis
          dataKey="name"
          type="category"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} />}
          interval={0}
          tickMargin={10}
          width={yAxisWidth}
          label={renderAxisYLabel(labelNames)}
        />
        <XAxis
          tick={tickStyles}
          tickLine={tickLineStyles}
          tickSize={10}
          axisLine={axisLineStyles}
          tickMargin={16}
          interval={0}
          type="number"
          ticks={ticks}
          label={renderAxisXLabel(labelValues)}
        />
        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataIndex={activeIndex} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && <Legend verticalAlign="top" align="right" />}

        {dataKeys.map((key, index) => {
          const color = getColor(index);

          return (
            <Bar
              key={key}
              stackId="stack1"
              dataKey={key}
              maxBarSize={CHART_BAR_MAX_WIDTH}
              fill={color}
              onMouseEnter={handleMouseEnter(index)}
              onMouseLeave={hideTooltip}
            >
              <LabelList dataKey={key} fill={color} content={BarChartLabel} />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
