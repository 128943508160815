import { ERROR_MESSAGES } from 'common/constants';
import { InferType, array, number, object, string } from 'yup';

const themeSchema = object({
  theme: string().trim().required(ERROR_MESSAGES.noEmpty),
  themeId: number().required(),
});

export const renameThemesFormSchema = object({
  themes: array().of(themeSchema).default([]),
});

export type ThemeFormField = InferType<typeof themeSchema>;
export type RenameThemesFormValues = InferType<typeof renameThemesFormSchema>;
