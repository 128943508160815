import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';
import type { NPSQuestion } from 'domain/question';

import { AnswerItem, AnswerLabel } from '../../components';

export const MAX_NPS_RATING = 10;

export const NPSAnswer: FC<{
  name: string;
  question: NPSQuestion;
  isObligatory: boolean;
}> = ({ name, question, isObligatory }) => (
  <AnswerItem title={question.title} isObligatory={isObligatory}>
    <Stack gap={1}>
      <Stack direction="row" justifyContent="space-between">
        <AnswerLabel>NOT LIKELY AT ALL</AnswerLabel>
        <AnswerLabel>EXTREMELY LIKELY</AnswerLabel>
      </Stack>
      <Controller
        name={`${name}.npsValue`}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl error={!!error}>
            <ToggleButtonGroup
              exclusive
              value={value}
              onChange={(_, newValue) => {
                if (newValue !== null) {
                  onChange(newValue);
                }
              }}
            >
              {Array.from({ length: MAX_NPS_RATING + 1 }).map((_, i) => (
                <ToggleButton key={i} value={i}>
                  {i}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
            <FormFieldHint error>{error?.message}</FormFieldHint>
          </FormControl>
        )}
      />
    </Stack>
  </AnswerItem>
);
