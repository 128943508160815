import React, { FC, useState } from 'react';

import { ErrorBoundary } from 'common/components';
import { BasicChartProps } from 'common/components/Charts';

import { chartsMap } from '../../ChartCard';
import {
  ChartCardChartWrapper,
  ChartCardHeader,
  ChartCardHeaderRightSection,
  ChartCardTitle,
  ChartCardWrapper,
} from '../../ChartCard.styled';
import { ChartErrorFallback } from '../ChartErrorFallback';
import { ChartType } from '../ChartSelect';
import { AVAILABLE_CHART_TYPES, SingleChoiceQuestionChartCardActions } from './components';

export type ChartCardProps = {
  title: string;
  chartProps: BasicChartProps;
  chartId: string;
  hasError?: boolean;
};

export const SingleChoiceQuestionChartCard: FC<ChartCardProps> = ({
  title,
  chartProps,
  chartId,
  hasError = false,
  children,
}) => {
  const [selectedChartType, setSelectedChartType] = useState(AVAILABLE_CHART_TYPES[0]);
  const SelectedChartComponent = chartsMap[selectedChartType];
  const [breakDownBy, setBreakDownBy] = useState<string>();

  const handleBreakDownByChange = (value: string) => {
    setBreakDownBy(value);
    setSelectedChartType(value ? ChartType.HorizontalStackedBar : AVAILABLE_CHART_TYPES[0]);
  };

  return (
    <ChartCardWrapper>
      <ChartCardHeader>
        <ChartCardTitle title={title}>{title}</ChartCardTitle>
        <ChartCardHeaderRightSection>
          <SingleChoiceQuestionChartCardActions
            chartId={chartId}
            title={title}
            data={chartProps.data}
            selectedChartType={selectedChartType}
            breakDownBy={breakDownBy}
            onBreakDownByChange={handleBreakDownByChange}
            onSelectedChartTypeChange={setSelectedChartType}
          />
        </ChartCardHeaderRightSection>
      </ChartCardHeader>
      <ErrorBoundary fallback={<ChartErrorFallback />} hasError={hasError}>
        <ChartCardChartWrapper>
          <SelectedChartComponent {...chartProps}>{children}</SelectedChartComponent>
        </ChartCardChartWrapper>
      </ErrorBoundary>
    </ChartCardWrapper>
  );
};
