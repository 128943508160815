import React, { Children, FC, PropsWithChildren } from 'react';

import { Alert, AlertTitle, LinearProgress, Stack, Typography } from '@mui/material';
import { Page } from 'common/layout';

import { Button } from '../Buttons';

type Props = PropsWithChildren<{
  title: string;
  activeStep: number;
  onNextClick?(): void;
  onBackClick?(): void;
  nextButtonLabel?: string;
  isLoading?: boolean;
}>;

/**
 * A component that displays a progress stepper with multiple steps.
 *
 * @component
 * @example
 * ```tsx
 * <ProgressStepper
 *   title="My Progress Stepper"
 *   activeStep={2}
 *   nextButtonLabel="Next"
 *   onNextClick={handleNextClick}
 *   onBackClick={handleBackClick}
 * >
 *   <Step1 />
 *   <Step2 />
 *   <Step3 />
 * </ProgressStepper>
 * ```
 */
export const ProgressStepper: FC<Props> = ({
  title,
  activeStep = 0,
  nextButtonLabel = 'Next',
  onNextClick,
  onBackClick,
  isLoading,
  children,
}) => {
  const maxSteps = Children.count(children);

  return (
    <Page title={title} onBackClick={activeStep ? onBackClick : undefined} maxWidth="sm">
      {activeStep >= maxSteps ? (
        <Alert severity="error">
          <AlertTitle>Invalid active step</AlertTitle>
          This progress stepper has {maxSteps} steps and the selected step is {activeStep + 1}
        </Alert>
      ) : (
        <Stack gap={4}>
          <Stack direction="row" gap={1} alignItems="center">
            <LinearProgress variant="determinate" value={((activeStep + 1) / maxSteps) * 100} sx={{ flex: 1 }} />
            <Typography variant="natter-text-sm" fontWeight={500}>
              {activeStep + 1}/{maxSteps}
            </Typography>
          </Stack>

          {Children.map(children, (child, index) => (index === activeStep ? child : null))}

          <Button onClick={onNextClick} fullWidth size="large" isLoading={isLoading}>
            {nextButtonLabel}
          </Button>
        </Stack>
      )}
    </Page>
  );
};
