import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { addAppSuccessMessage } from 'store/features/alerts';
import { setActiveCommunityId } from 'store/features/community';
import { handleQueryError } from 'store/utils';

import { communityApi } from '../community/communityApi';
import { userApi } from '../user/userApi';
import { organizationApi } from './organizationApi';

export const organizationApiListener = createListenerMiddleware<RootState>();

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.updateCommunity.matchFulfilled,
  effect: (_args, { dispatch }) => {
    dispatch(communityApi.util.invalidateTags(['MY_COMMUNITIES']));
  },
});

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.joinOrganization.matchFulfilled,
  effect: (_args, { dispatch }) => {
    dispatch(addAppSuccessMessage('Successfully joined organization'));
    dispatch(userApi.util.invalidateTags(['ACCOUNT']));
  },
});

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.joinOrganization.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, 'Unable to join organization');
  },
});

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.createCommunity.matchFulfilled,
  effect: ({ payload: { id } }, { dispatch }) => {
    dispatch(communityApi.util.invalidateTags(['MY_COMMUNITIES']));
    dispatch(setActiveCommunityId(id));
  },
});

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.createCommunity.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, 'Unable to create Community');
  },
});

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.updateCommunity.matchFulfilled,
  effect: (_args, { dispatch }) => {
    dispatch(addAppSuccessMessage('Community details updated successfully'));
  },
});

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.updateCommunity.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, 'Unable to update Community');
  },
});

organizationApiListener.startListening({
  matcher: organizationApi.endpoints.uploadCommunityLogo.matchRejected,
  effect: ({ error, type }, { dispatch }) => {
    handleQueryError(error, dispatch, type, 'Unable to upload Community logo');
  },
});
