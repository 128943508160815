import React, { ReactNode } from 'react';
import { FieldPath, FieldValues, useController } from 'react-hook-form';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Tooltip, Typography } from '@mui/material';

import { HideThemeItemWrapper, StyledIconButton } from './HideThemeItem.styled';

export type TextFieldProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: ReactNode;
  checkDisabled: boolean;
};

export const HideThemeItem = <T extends FieldValues>({ name, label, checkDisabled }: TextFieldProps<T>) => {
  const { field } = useController<T>({ name });
  const isSelected = !!field.value;
  const isDisabled = !isSelected && checkDisabled;
  const tooltipTitle = isDisabled
    ? 'One Theme always needs to be visible therefore you are not able to hide them all.'
    : '';

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    if (isDisabled) return;
    field.onChange({ ...event, target: { ...event.target, value: !isSelected } });
  };

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <HideThemeItemWrapper onClick={handleClick} disabled={isDisabled}>
        <Typography>{label}</Typography>
        <StyledIconButton isSelected={isSelected} disabled={isDisabled} variant="text" size="large">
          {isSelected ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </StyledIconButton>
      </HideThemeItemWrapper>
    </Tooltip>
  );
};
