import React, { FC } from 'react';

import { Button, Container, Stack, Typography } from '@mui/material';
import { useBreakpoints } from 'common/hooks';
import { generateDynamicPath } from 'common/utils';
import { AppRoutes } from 'router';
import { AsyncSurvey } from 'store/apis/survey';

type Props = Pick<AsyncSurvey, 'id' | 'name' | 'welcomeMessage'>;

export const SurveyWelcomePage: FC<Props> = ({ id, name, welcomeMessage }) => {
  const { isMobile } = useBreakpoints();

  return (
    <Container maxWidth="hug" sx={{ paddingTop: '10vh' }} disableGutters={!isMobile}>
      <Stack gap={4} py={6} px={2} alignItems="center">
        <Typography variant="natter-display-sm" textAlign="center" fontWeight={500}>
          {name}
        </Typography>
        <Typography variant="natter-text-sm" color="text.secondary" textAlign="center">
          {welcomeMessage}
        </Typography>

        <Button href={generateDynamicPath(AppRoutes.SurveyQuestionnaire, { id })} fullWidth size="large">
          Start Survey
        </Button>
      </Stack>
    </Container>
  );
};
