import React, { FC, createContext, memo, useContext, useEffect, useMemo } from 'react';

import { Loading } from 'common/components';
import { QuestionWSChannels } from 'common/constants';
import { assert, generateDynamicPath } from 'common/utils';
import type { Nullable } from 'domain/Common';
import type { EventRoom, EventRoomAttendee } from 'domain/event';
import type { QuestionAssignmentKey } from 'domain/question';
import { useAppSelector } from 'store';
import { selectIsConnectedToRoom } from 'store/features/socket';

import { useQuestionWS } from './hooks';

export type RoomQuestionWSContextValue = (ReturnType<typeof useQuestionWS> & Pick<EventRoom, 'attendees'>) | undefined;

const RoomQuestionWSContext = createContext<RoomQuestionWSContextValue>(undefined);

type RoomQuestionWSProviderProps = {
  enabled?: boolean;
  attendees?: EventRoomAttendee[];
  questionAssignmentKey?: Nullable<QuestionAssignmentKey>;
};

export const RoomQuestionWSProvider: FC<RoomQuestionWSProviderProps> = memo(
  ({ children, enabled, questionAssignmentKey, attendees }) => {
    const value = useQuestionWS(questionAssignmentKey);
    const channelPath = useMemo(
      () => questionAssignmentKey && generateDynamicPath(QuestionWSChannels.public, questionAssignmentKey),
      [questionAssignmentKey]
    );
    const isQuestionsSubscribed = useAppSelector((state) => selectIsConnectedToRoom(state, channelPath ?? ''));
    const { subscribe, unsubscribe } = value;

    /** Initialize WS subscription */
    useEffect(() => {
      if (!enabled || !questionAssignmentKey) return;

      subscribe();

      return () => {
        unsubscribe();
      };
    }, [enabled, questionAssignmentKey, subscribe, unsubscribe]);

    if (!enabled) return <>{children}</>;

    if (!questionAssignmentKey || !attendees || !isQuestionsSubscribed) return <Loading />;

    return <RoomQuestionWSContext.Provider value={{ ...value, attendees }}>{children}</RoomQuestionWSContext.Provider>;
  }
);

export const useRoomQuestionWS = () => {
  const ctx = useContext(RoomQuestionWSContext);
  assert(ctx, 'useRoomQuestionWS must be used inside RoomQuestionWSProvider');
  return ctx;
};
