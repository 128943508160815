import { Typography, styled } from '@mui/material';

export const AssociatedEventDescription = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  lineHeight: '20px',
  color: theme.palette.text.secondary,
}));

export const AssociatedEventTitle = styled(AssociatedEventDescription)(({ theme }) => ({
  fontWeight: 'bold',
  color: theme.palette.text.primary,
}));
