import React, { FC } from 'react';

import InsightsReportPage from 'pages/InsightsReport';
import { InsightsReportPinGuard } from 'pages/InsightsReport/hocs/InsightsReportPinGuard';

import { PublicEventReportProvider } from './PublicEventReportProvider';

export const PublicEventReport: FC = () => (
  <InsightsReportPinGuard>
    <PublicEventReportProvider>
      <InsightsReportPage />
    </PublicEventReportProvider>
  </InsightsReportPinGuard>
);
