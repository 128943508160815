import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TextInput } from 'common/components/_legacy/Form';
import { uuid } from 'common/utils';
import { CreateAnswerVariants, type TextQuestion } from 'domain/question';

import { AnswerItem } from '../components/AnswerItem';

interface TextAnswerProps {
  name: string;
  question: TextQuestion;
  isObligatory: boolean;
}

export const TextAnswer: FC<TextAnswerProps> = ({ name, question, isObligatory }) => {
  const id = uuid();
  const type = CreateAnswerVariants.TextQuestion;
  const { register } = useFormContext();

  useEffect(() => {
    register(`${name}.id`, { value: id });
    register(`${name}.type`, { value: type });
  }, [id, name, register, type]);

  return (
    <AnswerItem title={question.title} isObligatory={isObligatory}>
      <TextInput
        name={`${name}.text`}
        label="Answer"
        placeholder="Your answer"
        required={isObligatory ? 'Please write your answer' : isObligatory}
        rows={5}
        multiline={true}
        autoWidth
        data-testid={`text-answer-${id}`}
      />
    </AnswerItem>
  );
};
