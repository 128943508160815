import Clear from '@mui/icons-material/Clear';
import { alpha, type Components, type Theme } from '@mui/material';
import React from 'react';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    deleteIcon: (
      <Clear
        sx={({ palette }) => ({
          height: 16,
          width: 16,
          color: `${palette.custom.darkGrey} !important`,
        })}
      />
    ),
  },
  styleOverrides: {
    root: ({ theme: { spacing, typography } }) => ({
      ...typography.button,
      borderRadius: spacing(0.5),
      padding: spacing(1.25, 2),
      height: 'initial',
    }),
    sizeSmall: ({ theme: { spacing } }) => ({
      padding: spacing(0.5, 1),
    }),
    filled: ({ theme: { palette } }) => ({
      border: `1px solid ${palette.primary.main}`,
      backgroundColor: alpha(palette.primary.main, 0.1),
      '&:hover': {
        backgroundColor: alpha(palette.primary.main, 0.2),
      },
    }),
  },
};
