import React, { FC, useEffect } from 'react';

import { Dialog } from 'common/components';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { TextAnswerTheme, useUnmergeThemesMutation } from 'store/apis/insightsReport';

import { UnmergeThemesForm } from './UnmergeThemesForm';
import { UnmergeThemesFormValues } from './schema';

export const UnmergeThemesDialog: FC<{
  isOpen: boolean;
  onClose(): void;
  themes: TextAnswerTheme[];
}> = ({ isOpen, onClose, themes }) => {
  const { reportId } = useCurrentReportId();
  const [unmergeThemes, { isLoading, isSuccess, reset }] = useUnmergeThemesMutation();

  const handleSubmit = (values: UnmergeThemesFormValues) =>
    unmergeThemes({
      reportId,
      themeIds: values.themes.filter((theme) => theme.isSelected).map((theme) => theme.themeId),
    });

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  useEffect(() => {
    if (!isSuccess) return;

    onClose();
    reset();
  }, [isSuccess, onClose, reset]);

  return (
    <Dialog
      description="You can unmerge Themes by selecting them below."
      isOpen={isOpen}
      onClose={handleClose}
      title="Unmerge Themes"
      showCancelButton={false}
      maxWidth="sm"
      fullWidth
    >
      <UnmergeThemesForm themes={themes} onClose={handleClose} isLoading={isLoading} onSubmit={handleSubmit} />
    </Dialog>
  );
};
