import React, { FC } from 'react';

import { DateISOString } from 'domain/Common';
import { END_TIME_FORMAT, START_TIME_FORMAT } from 'event/management/constants';

import { formatScheduleTime } from '../../utils';
import { EventPreviewScheduledTimeLabel } from './EventScheduledTime.styled';

type Props = {
  startDate: DateISOString | undefined;
  duration: number | undefined;
  startTimeFormat?: string;
  endTimeFormat?: string;
};

export const EventScheduledTime: FC<Props> = ({
  startDate,
  duration,
  startTimeFormat = START_TIME_FORMAT,
  endTimeFormat = END_TIME_FORMAT,
}) => {
  if (!startDate || duration === null || duration === undefined) return null;

  return (
    <EventPreviewScheduledTimeLabel>
      {formatScheduleTime({ startTimeFormat, endTimeFormat })(startDate, duration)}
    </EventPreviewScheduledTimeLabel>
  );
};
