import { filter, map, some } from 'lodash';
import { REQUIRED_QUESTION_MESSAGE } from 'modules/question/constants';
import { array, boolean, number, object } from 'yup';

const rankingChoice = object({
  rank: number()
    .transform((value, originalValue) => (isNaN(Number(originalValue)) ? undefined : Number(originalValue)))
    .test('isRankRequired', 'Please rank all answers in order to proceed.', (value, ctx) => {
      if (!ctx.from) return true;

      const [, question] = ctx.from;
      const { choices, isObligatory } = question.value;
      const hasAnswers = some(choices, ({ rank }) => Boolean(rank));

      if (!isObligatory && !hasAnswers) return true;

      return Boolean(value);
    })
    .test('isRankUnique', 'Rank must be unique.', (value, ctx) => {
      if (!ctx.from) return true;

      const [, question] = ctx.from;
      const { choices, isObligatory } = question.value;
      const hasAnswers = some(choices, ({ rank }) => Boolean(rank));

      if (!isObligatory && !hasAnswers) return true;

      return filter(choices, { rank: value?.toString() }).length === 1;
    }),
});

export const rankingAnswer = object({
  choices: array(rankingChoice),
  isObligatory: boolean().test('isObligatoryRankingQuestion', REQUIRED_QUESTION_MESSAGE, (isObligatory, ctx) => {
    if (!ctx.from || !isObligatory) return true;

    const [question] = ctx.from;
    const { choices } = question.value;

    return map(choices, 'rank').every(Boolean);
  }),
});
