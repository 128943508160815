import { makeStyles } from '@mui/styles';

export const useReadableTextFieldStyles = makeStyles(
  ({ palette }) => ({
    input: {
      '&:hover': {
        backgroundColor: palette.grey[200],
        transition: 'background-color 250ms',
      },
      '& input, .MuiInputBase-root': {
        cursor: 'pointer',
      },
      '& input:disabled': {
        backgroundColor: 'unset',
      },
      '& .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root svg': {
        marginRight: 0,
        color: palette.custom.darkGrey,
      },
    },
    inputAdornment: {
      cursor: 'pointer',
      '&.MuiInputAdornment-root svg': {
        color: palette.primary.main,
        fontSize: '18px',
      },
    },
  }),
  { name: 'readableTextField' }
);
