import React, { ComponentType, FC, useMemo } from 'react';

import { ThemeProvider } from '@mui/material';
import { theme as createTheme } from 'common/theme';
import { useAppSelector } from 'store';
import { selectThemeColor } from 'store/features/app';

export const NatterThemeProvider: FC = ({ children }) => {
  const primaryColor = useAppSelector(selectThemeColor);

  const theme = useMemo(() => createTheme(primaryColor), [primaryColor]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const withTheme =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props) => (
    <NatterThemeProvider>
      <WrappedComponent {...props} />
    </NatterThemeProvider>
  );
