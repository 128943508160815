import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import { SxProps, Theme } from '@mui/material';

import { Checkbox as NatterCheckbox, type CheckboxProps as NatterCheckboxProps } from '../../Inputs';
import { StyledFormControlLabel, StyledFormControlLabelProps } from './Checkbox.styled';

export type CheckboxProps = {
  name: string;
  label?: React.ReactNode;
  labelSx?: SxProps<Theme>;
  size?: NatterCheckboxProps['size'];
  onChange?(checked: boolean): void;
} & StyledFormControlLabelProps;

export const Checkbox: FC<CheckboxProps> = ({
  name,
  label,
  labelSx,
  size = 'medium',
  variant = 'default',
  onChange,
}) => {
  const { field } = useController({ name });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    field.onChange(event, checked);
    onChange?.(checked);
  };

  return (
    <StyledFormControlLabel
      sx={labelSx}
      control={<NatterCheckbox {...field} onChange={handleChange} size={size} checked={field.value || false} />}
      label={label}
      variant={variant}
      checked={field.value || false}
    />
  );
};
