import React, { ComponentType, FC, ReactElement } from 'react';

import { Loading } from 'common/components';
import { useGetAvailableTagsCategoriesQuery } from 'store/apis/community';

export const withAvailableTagsCategories =
  <P extends object>(WrappedComponent: ComponentType<P>): FC<P> =>
  (props): ReactElement => {
    const { data = [], isLoading } = useGetAvailableTagsCategoriesQuery();

    if (isLoading) return <Loading />;

    return <WrappedComponent {...props} tagsCategories={data} />;
  };
