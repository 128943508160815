import React, { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { Stack } from '@mui/material';
import { Button } from 'common/components';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { HideThemeItem } from './HideThemeItem/HideThemeItem';
import { ThemeFormField } from './schema';

export const HideThemesFormContent: FC<{
  themes: TextAnswerTheme[];
  onClose(): void;
  onApply(): void;
  isLoading: boolean;
}> = ({ themes, onClose, onApply, isLoading }) => {
  const { formState } = useFormContext();
  const themesValue: ThemeFormField[] = useWatch({ name: 'themes' });
  const hiddenThemes = themesValue.filter((theme) => theme.isHidden);
  const isHideLimitReached = hiddenThemes.length > themes.length - 2;
  const isApplyDisabled = !(formState.isDirty && formState.isValid) || isLoading;

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        {themes.map(({ themeId, theme }, index) => (
          <HideThemeItem
            name={`themes.${index}.isHidden`}
            label={theme}
            key={themeId}
            checkDisabled={isHideLimitReached}
          />
        ))}
      </Stack>
      <Stack direction="row" gap={1} justifyContent="end">
        <Button variant="text" onClick={onClose} disabled={isLoading}>
          Close
        </Button>
        <Button onClick={onApply} disabled={isApplyDisabled} isLoading={isLoading}>
          Apply
        </Button>
      </Stack>
    </Stack>
  );
};
