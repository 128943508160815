import { makeStyles } from '@mui/styles';

export const useGuestsCounterStyles = makeStyles(
  (theme) => ({
    guestsCounterIcon: {
      color: theme.palette.primary.main,
      marginRight: 10,
    },
    guestsCounterCounter: {
      display: 'flex',
      alignItems: 'center',
    },
    guestsCounterLabel: {
      fontSize: 14,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.43,
    },
  }),
  { name: 'guestsCounter' }
);
