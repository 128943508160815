import React, { FC } from 'react';
import { useController } from 'react-hook-form';

import {
  DatePicker as NatterDatePicker,
  type DatePickerProps as NatterDatePickerProps,
} from 'common/components/Inputs';

type DatePickerProps = Omit<NatterDatePickerProps, 'value' | 'onChange'> & {
  defaultValue?: Date | null;
  name: string;
  onChange?: (value: Date | null) => void;
};

export const DatePicker: FC<DatePickerProps> = ({ defaultValue = null, name, onChange, ...datePickerProps }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    defaultValue,
  });

  const handleChange = (date: Date | null) => {
    field.onChange(date);
    field.onBlur();
    onChange?.(date);
  };

  return (
    <NatterDatePicker
      {...datePickerProps}
      {...field}
      error={!!error?.message}
      hint={error?.message}
      onChange={handleChange}
    />
  );
};
