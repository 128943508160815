import { Answer, CreateAnswerVariants } from 'domain/question';
import { REQUIRED_QUESTION_MESSAGE } from 'modules/question/constants';
import { array, boolean, lazy, number, object, string } from 'yup';

import { rankingAnswer } from './rankingAnswer.schema';

const MAX_ANSWER_LENGTH = 1000;
const maxAnswerLengthValidation = string().max(MAX_ANSWER_LENGTH, `Maximum of ${MAX_ANSWER_LENGTH} characters allowed`);
const selectValidation = number()
  .transform((value) => (Number.isNaN(value) ? null : value))
  .nullable();

const answerValidation = lazy((answer: Answer) => {
  if (!answer.type) return object({});

  switch (answer.type) {
    case CreateAnswerVariants.TextQuestion:
      return object({
        text: answer.isObligatory
          ? maxAnswerLengthValidation.required(REQUIRED_QUESTION_MESSAGE)
          : maxAnswerLengthValidation,
      });
    case CreateAnswerVariants.MultipleChoiceSingleSelectQuestion:
      return object({
        choiceId: answer.isObligatory ? selectValidation.required(REQUIRED_QUESTION_MESSAGE) : selectValidation,
      });
    case CreateAnswerVariants.MultipleChoiceMultipleSelectQuestion:
      return object({
        choices: answer.isObligatory
          ? array(
              object({
                value: boolean(),
              })
            ).test(
              'isAtLeastOneSelected',
              REQUIRED_QUESTION_MESSAGE,
              async (value) => !!value?.some((v) => v.value === true)
            )
          : array(),
      });
    case CreateAnswerVariants.NPSQuestion:
      return object({
        npsValue: answer.isObligatory
          ? number().min(0, REQUIRED_QUESTION_MESSAGE).required(REQUIRED_QUESTION_MESSAGE)
          : number(),
      });
    case CreateAnswerVariants.Slider:
      return answer.isObligatory
        ? object({
            sliderValue: number().required(REQUIRED_QUESTION_MESSAGE),
          })
        : object();
    case CreateAnswerVariants.RankingQuestion:
      return rankingAnswer;
  }
});

export const answersValidation = array(answerValidation).default([]);
