import React, { FC } from 'react';

import { useInsightsReportContext } from 'pages/InsightsReport/hooks';

import { KeyMetricsGrid } from './KeyMetrics.styled';
import { KeyMetricCard } from './components';

export const KeyMetricsContainer: FC = () => {
  const { keyMetricsData, isKeyMetricsDataLoading } = useInsightsReportContext();

  return (
    <KeyMetricsGrid>
      {keyMetricsData.map((metric) => (
        <KeyMetricCard key={metric.key} metric={metric} isLoading={isKeyMetricsDataLoading} />
      ))}
    </KeyMetricsGrid>
  );
};
