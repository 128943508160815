import React, { FC } from 'react';

import { Typography } from '@mui/material';
import { TooltipContentProps } from 'common/components/Charts';
import { toPercentageString } from 'common/utils';

type RankingTooltipProps = TooltipContentProps & { totalCount: number };

export const RankingTooltip: FC<RankingTooltipProps> = ({ dataKey, name, value, totalCount }) => (
  <Typography>
    <b>{`${value} (${toPercentageString(value, totalCount)})`}</b>
    {' participants ranked option '}
    <b>{name}</b>
    {' at position '}
    <b>{dataKey}</b>
  </Typography>
);
