import React, { ChangeEventHandler, FC, useRef } from 'react';

import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Typography } from '@mui/material';
import { useDropContainer } from 'common/hooks/useDropContainer';

import { DropzoneContainer, FileInput, IconContainer, TextContainer } from './UploadDropzone.styled';
import { ALLOWED_EXTENSIONS, TYPE_PLACEHOLDER_MAP, UploadDropzoneType } from './constants';

export type UploadDropzoneProps = {
  helperText?: string;
  name?: string;
  type?: UploadDropzoneType;
  onFileSelect: (file: File) => void;
};

export const UploadDropzone: FC<UploadDropzoneProps> = ({
  helperText,
  name,
  type = 'image',
  onFileSelect: handleFileSelect,
}) => {
  const dropzoneRef = useRef<HTMLDivElement>(null);

  const { dragging: isDragging } = useDropContainer({
    container: dropzoneRef,
    onDrop: (e) => {
      if (!e.dataTransfer) {
        return;
      }
      const { files } = e.dataTransfer;
      if (files?.length) {
        handleFileSelect(files[0]);
      }
    },
  });

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.target?.files?.[0];

    if (file) {
      handleFileSelect(file);
    }
  };

  return (
    <DropzoneContainer isDragging={isDragging} ref={dropzoneRef}>
      <IconContainer isDragging={isDragging}>
        <FileUploadOutlinedIcon color="inherit" />
      </IconContainer>
      <TextContainer gap={0.5}>
        <Typography variant="natter-text-sm" color="text.secondary">
          <Typography component="span" variant="natter-text-sm" color="primary.main" fontWeight={600}>
            Click to upload
          </Typography>{' '}
          or drag and drop {TYPE_PLACEHOLDER_MAP[type]} or click to select one.
        </Typography>
        {helperText && (
          <Typography variant="natter-text-xs" color="text.secondary">
            {helperText}
          </Typography>
        )}
      </TextContainer>

      <FileInput
        data-testid={`${type}-input`}
        aria-label="Image upload"
        type="file"
        name={name}
        accept={ALLOWED_EXTENSIONS[type]}
        onChange={handleFileChange}
        onClick={(event) => {
          // to allow selecting the same file multiple times
          // @ts-expect-error Property 'value' does not exist on type 'EventTarget'
          event.target.value = null;
        }}
      />
    </DropzoneContainer>
  );
};
