import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { selectMyCommunityBrandingColor } from 'store/apis/community';
import { eventApi } from 'store/apis/event';
import { selectUserAccountId } from 'store/apis/user';

import { appSetThemeColor } from '../app';
import { setActiveCommunityId } from './communitySlice';

export const communityListener = createListenerMiddleware<RootState>();

communityListener.startListening({
  actionCreator: setActiveCommunityId,
  effect: ({ payload }, { getState }) => {
    const accountId = selectUserAccountId(getState());
    if (accountId && payload) {
      localStorage.setItem(`preferredCommunityId-user${accountId}`, payload.toString());
    }
  },
});

communityListener.startListening({
  actionCreator: setActiveCommunityId,
  effect: (_, { dispatch, getState }) => {
    const brandingColor = selectMyCommunityBrandingColor(getState());
    dispatch(appSetThemeColor(brandingColor));
  },
});

communityListener.startListening({
  actionCreator: setActiveCommunityId,
  effect: () => {
    eventApi.util.invalidateTags(['EVENT']);
  },
});
