export const START_TIME_FORMAT = 'EEEE MMM do yyyy, h:mm a';
export const START_TIME_FORMAT_SHORT = 'EEE MMM do yyyy, h:mm a';
export const END_TIME_FORMAT = 'h:mm a (z)';

export const DEFAULT_EVENT_IMAGE = {
  src: '/default-event-image.png',
  name: 'Default image by Natter',
};

export const DEFAULT_EVENT_IMAGE_SMALL = {
  src: '/default-event-image-small.png',
  name: 'Small default image by Natter',
};
