import React, { FC } from 'react';

import ContentCopy from '@mui/icons-material/ContentCopy';
import { Typography } from '@mui/material';
import { IconButtonWithTooltip, Tooltip } from 'common/components';
import { useCopyToClipboard } from 'common/hooks';
import { ApiExceptionMessage } from 'common/utils';

type Props = ApiExceptionMessage & {
  scope: 'stack trace' | 'code';
  onCopySuccess(): void;
  onCopyError(): void;
};

export const ErrorCode: FC<Props> = ({ scope, onCopySuccess, onCopyError, ...apiExceptionMessage }) => {
  const { stackTrace, errorId, message } = apiExceptionMessage;

  const { copyToClipboard: handleCopyErrorCode } = useCopyToClipboard({
    value: stackTrace ?? errorId,
    onSuccess: onCopySuccess,
    onError: onCopyError,
  });

  return (
    <>
      <Tooltip title={message}>
        <Typography variant="inherit" component="span" noWrap mr={1}>{`Error code: ${errorId}`}</Typography>
      </Tooltip>

      <IconButtonWithTooltip
        title={`Copy error ${scope}`}
        size="small"
        color="error"
        onClick={handleCopyErrorCode}
        aria-label={`Copy error ${scope}`}
      >
        <ContentCopy fontSize="small" />
      </IconButtonWithTooltip>
    </>
  );
};
