import React, { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { DateRange, Range, RangeKeyDict } from 'react-date-range';
import { useFormContext } from 'react-hook-form';

import { faCalendarWeek } from '@fortawesome/pro-light-svg-icons';
import { Box, ClickAwayListener, Fade, Paper, Popper, Stack, Typography, useTheme } from '@mui/material';
import { fullDayFormat } from 'common/utils';
import { addDays, addYears, endOfDay, format, startOfDay } from 'date-fns';

import { ReadableTextField } from '../../../Inputs';
import { Button } from '../../Button';
import { useDateRangeInputStyles } from './DateRangeInput.styles';

const DEFAULT_MIN_DATE = new Date();
const DEFAULT_MAX_DATE = addYears(DEFAULT_MIN_DATE, 1);

export const DateRangeInput: FC<{
  name: string;
  minRange: number;
  months?: number;
  minDate?: Date;
  maxDate?: Date;
  ignoredErrorTypes?: string[];
}> = ({
  name,
  months = 2,
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  minRange,
  ignoredErrorTypes = [],
}) => {
  const theme = useTheme();
  const classes = useDateRangeInputStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { register, setValue, watch, getValues, getFieldState, clearErrors } = useFormContext();
  const value: Range[] | undefined = watch(name);
  const [previousValue, setPreviousValue] = useState<Range[] | undefined>();

  const getError = (path: string) => {
    const { error } = getFieldState(path);
    if (error?.type && typeof error.type === 'string' && !ignoredErrorTypes.includes(error.type)) {
      return error;
    }
  };

  const error = getError(`${name}.0.startDate`) ?? getError(`${name}.0.endDate`) ?? getError(name);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleConfirm = () => {
    clearErrors(name);
    setAnchorEl(null);
  };

  const handleClearValue = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
    clearErrors(name);
    setValue(name, undefined);
  };

  const handleCancel = () => {
    if (!anchorEl) {
      return;
    }
    setValue(name, previousValue);
    setAnchorEl(null);
  };

  const handleChange = useCallback(
    (date: RangeKeyDict) => {
      setValue(name, [
        {
          ...date.range1,
          startDate: date.range1.startDate && startOfDay(date.range1.startDate),
          endDate: date.range1.endDate && endOfDay(date.range1.endDate),
        } as Range,
      ]);
    },
    [setValue, name]
  );

  useEffect(() => {
    register(name);
  }, [register, name]);

  useEffect(() => {
    const date = getValues()[name];
    setPreviousValue(date);

    if (anchorEl && !date?.[0]?.startDate) {
      setValue(name, [
        {
          startDate: startOfDay(new Date()),
          endDate: endOfDay(addDays(new Date(), minRange)),
        },
      ] as Range[]);
    }
  }, [anchorEl, getValues, minRange, name, setValue]);

  return (
    <ClickAwayListener onClickAway={handleCancel}>
      <Box>
        <ReadableTextField
          label="Date range"
          value={
            value?.[0]?.startDate && value?.[0]?.endDate
              ? `${format(value?.[0]?.startDate, fullDayFormat)} - ${format(value?.[0]?.endDate, fullDayFormat)}`
              : 'Select date range'
          }
          icon={faCalendarWeek}
          isError={!!error}
          handleClearValue={handleClearValue}
          handleClick={handleClick}
        />
        {!!error && (
          <Typography variant="caption" color="error">
            {error.message}
          </Typography>
        )}
        <Popper open={!!anchorEl} anchorEl={anchorEl} transition sx={{ zIndex: 1301 }}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={200}>
              <Paper
                sx={({ shadows }) => ({
                  boxShadow: shadows[4],
                  borderRadius: 3,
                  overflow: 'hidden',
                  marginTop: 2,
                  padding: 1,
                })}
              >
                <DateRange
                  onChange={handleChange}
                  moveRangeOnFirstSelection={false}
                  months={months}
                  ranges={value}
                  direction="horizontal"
                  showDateDisplay={false}
                  className={classes.calendar}
                  minDate={minDate}
                  maxDate={maxDate}
                  rangeColors={[theme.palette.primary.main]}
                />
                <Stack alignItems="end" p={3} pt={0}>
                  <Button onClick={handleConfirm}>Select</Button>
                </Stack>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
