import React, { FC } from 'react';

import InsightsReportPage from 'pages/InsightsReport';
import { InsightsReportPinGuard } from 'pages/InsightsReport/hocs/InsightsReportPinGuard';

import { PublicSurveyReportProvider } from './PublicSurveyReportProvider';

export const PublicSurveyReport: FC = () => (
  <InsightsReportPinGuard>
    <PublicSurveyReportProvider>
      <InsightsReportPage />
    </PublicSurveyReportProvider>
  </InsightsReportPinGuard>
);
