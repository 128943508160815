import React, { FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { RadioGroup } from 'common/components/ReactHookForm';
import { uuid } from 'common/utils';
import { CreateAnswerVariants, MultipleChoiceSingleSelectQuestion } from 'domain/question';

import { AnswerItem } from '../components/AnswerItem';

interface MultipleChoiceSingleSelectAnswerProps {
  name: string;
  question: MultipleChoiceSingleSelectQuestion;
  isObligatory: boolean;
}

export const MultipleChoiceSingleSelectAnswer: FC<MultipleChoiceSingleSelectAnswerProps> = ({
  name,
  isObligatory,
  question,
}) => {
  const id = uuid();
  const type = CreateAnswerVariants.MultipleChoiceSingleSelectQuestion;
  const { register } = useFormContext();
  const optionsList = useMemo(
    () =>
      question.choices.map((option) => {
        const label = typeof option === 'string' ? option : option.text;
        const value = typeof option === 'string' ? option : option.id;
        return { label, value };
      }),

    [question.choices]
  );

  useEffect(() => {
    register(`${name}.id`, { value: id });
    register(`${name}.type`, { value: type });
  }, [id, name, optionsList, register, type]);

  return (
    <AnswerItem title={question.title} isObligatory={isObligatory}>
      <RadioGroup name={`${name}.choiceId`} options={optionsList} autoWidth />
    </AnswerItem>
  );
};
