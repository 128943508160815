import React, { FC, PropsWithChildren } from 'react';

import { Box, Paper, Stack } from '@mui/material';
import { BackButton } from 'common/components';
import { SectionHeader } from 'common/layout';

type Props = PropsWithChildren<{
  title: string;
  description?: string;
  showBackButton?: boolean;
  inset?: boolean;
}>;

export const Section: FC<Props> = ({ title, description, showBackButton, inset = true, children }) => (
  <Paper component="section" sx={[{ borderRadius: 2 }, inset ? { p: 3 } : { border: 'none' }]}>
    <Stack gap={3}>
      {showBackButton && (
        <Box>
          <BackButton />
        </Box>
      )}

      <SectionHeader variant="natter-text-lg" title={title} description={description} />

      {children}
    </Stack>
  </Paper>
);
