import { useActiveEventParams } from 'event/hooks';
import { useGetPublicEventDetailsQuery } from 'store/apis/event';

export const usePublicEventDetails = () => {
  const { id } = useActiveEventParams();
  const { data: eventDetails, isLoading, isError, error } = useGetPublicEventDetailsQuery({ id });

  return {
    eventDetails,
    isLoading,
    isError,
    error,
  };
};
