import React from 'react';

import { EventQuestionAssignmentType } from 'domain/event';
import { SurveyInsights } from 'pages/InsightsReport';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { useAppSelector } from 'store';
import { useGetEventsReportAnswersQuery } from 'store/apis/eventsReport';
import { selectDemographicFilters } from 'store/features/insightsReports';

export const createEventSurveyInsightsReportPage =
  (assignmentType: EventQuestionAssignmentType, surveyName: string) => () => {
    const { reportId, isInvalidReportId } = useCurrentReportId();
    const demographicFilters = useAppSelector(selectDemographicFilters);
    const { data } = useGetEventsReportAnswersQuery(
      { reportId, communityTagIds: demographicFilters, assignmentType },
      { skip: isInvalidReportId }
    );

    return <SurveyInsights surveyName={surveyName} data={data} />;
  };
