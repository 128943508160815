import React, { FC, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Loading } from 'common/components';
import { useKeycloakContext } from 'store/context/KeycloakContext';

export const PublicPageGuard: FC<{ getRedirectUrl(params: {}): string }> = ({ getRedirectUrl, children }) => {
  const params = useParams();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const keycloak = useKeycloakContext();
  const [checkingAuthorization, setCheckingAuthorization] = useState(true);

  useEffect(() => {
    if (keycloak.authenticated) {
      setShouldRedirect(true);
      return;
    }

    keycloak.init({ onLoad: 'check-sso', checkLoginIframe: false }).then((authenticated) => {
      if (authenticated) {
        setShouldRedirect(true);
        return;
      }

      setCheckingAuthorization(false);
    });
  }, [keycloak]);

  if (shouldRedirect) {
    return <Redirect to={getRedirectUrl(params)} />;
  }

  if (checkingAuthorization) {
    return <Loading />;
  }

  return <>{children}</>;
};
