import React, { FC } from 'react';

import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';

import { ActionButton } from './ActionButton';
import type { ActionButtonProps } from './ActionButton/ActionButton';

type ToggleMicrophoneProps = Pick<ActionButtonProps, 'onClick' | 'isActive' | 'isDisabled' | 'isLoading'>;

export const ToggleMicrophone: FC<ToggleMicrophoneProps> = (props) => (
  <ActionButton tooltip={props.isActive ? 'Turn off microphone' : 'Turn on microphone'} {...props}>
    {props.isActive ? <MicNoneOutlinedIcon /> : <MicOffOutlinedIcon />}
  </ActionButton>
);
