import React from 'react';

export const DeleteIcon = () => (
  <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.83325 15.5C2.37492 15.5 1.9827 15.3369 1.65659 15.0108C1.32992 14.6842 1.16659 14.2917 1.16659 13.8333V3C0.930474 3 0.732419 2.92028 0.572419 2.76083C0.412974 2.60083 0.333252 2.40278 0.333252 2.16667C0.333252 1.93056 0.412974 1.7325 0.572419 1.5725C0.732419 1.41306 0.930474 1.33333 1.16659 1.33333H4.49992C4.49992 1.09722 4.57992 0.899167 4.73992 0.739167C4.89936 0.579722 5.09714 0.5 5.33325 0.5H8.66658C8.9027 0.5 9.10075 0.579722 9.26075 0.739167C9.4202 0.899167 9.49992 1.09722 9.49992 1.33333H12.8333C13.0694 1.33333 13.2671 1.41306 13.4266 1.5725C13.5866 1.7325 13.6666 1.93056 13.6666 2.16667C13.6666 2.40278 13.5866 2.60083 13.4266 2.76083C13.2671 2.92028 13.0694 3 12.8333 3V13.8333C12.8333 14.2917 12.6702 14.6842 12.3441 15.0108C12.0174 15.3369 11.6249 15.5 11.1666 15.5H2.83325ZM2.83325 3V13.8333H11.1666V3H2.83325ZM4.49992 11.3333C4.49992 11.5694 4.57992 11.7672 4.73992 11.9267C4.89936 12.0867 5.09714 12.1667 5.33325 12.1667C5.56936 12.1667 5.76742 12.0867 5.92742 11.9267C6.08686 11.7672 6.16658 11.5694 6.16658 11.3333V5.5C6.16658 5.26389 6.08686 5.06583 5.92742 4.90583C5.76742 4.74639 5.56936 4.66667 5.33325 4.66667C5.09714 4.66667 4.89936 4.74639 4.73992 4.90583C4.57992 5.06583 4.49992 5.26389 4.49992 5.5V11.3333ZM7.83325 11.3333C7.83325 11.5694 7.91325 11.7672 8.07325 11.9267C8.2327 12.0867 8.43047 12.1667 8.66658 12.1667C8.9027 12.1667 9.10075 12.0867 9.26075 11.9267C9.4202 11.7672 9.49992 11.5694 9.49992 11.3333V5.5C9.49992 5.26389 9.4202 5.06583 9.26075 4.90583C9.10075 4.74639 8.9027 4.66667 8.66658 4.66667C8.43047 4.66667 8.2327 4.74639 8.07325 4.90583C7.91325 5.06583 7.83325 5.26389 7.83325 5.5V11.3333Z"
      fill="currentColor"
    />
  </svg>
);
