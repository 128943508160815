import React, { FC, useMemo } from 'react';

import { useElementSize } from 'common/hooks';
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { BarChartLabel, Tick, TooltipContentWrapper } from '../../components';
import { useChartAxis, useChartColors, useChartStyles, useDynamicChartDimensions, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateTicks } from '../../utils';

export const HorizontalBarChart: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  height,
  dataKeys = ['value'],
  TooltipContent,
}) => {
  const classes = useChartStyles();
  const { getColor } = useChartColors();
  const { tickStyles, tickLineStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const ticks = useMemo(() => calculateTicks({ data, dataKeys }), [data, dataKeys]);
  const { activeIndex, handleMouseEnter } = useTooltip();
  const [ref, size] = useElementSize();
  const { yAxisWidth, chartHeight } = useDynamicChartDimensions({
    data,
    chartWidth: size.width,
    chartHeight: height,
  });

  return (
    <ResponsiveContainer debounce={300} width="100%" height={chartHeight} ref={ref}>
      <BarChart data={data} margin={{ right: 50, left: 50, bottom: 40 }} layout="vertical" className={classes.chart}>
        <CartesianGrid strokeDasharray="1 1" horizontal={false} />
        <YAxis
          dataKey="name"
          type="category"
          tickLine={false}
          axisLine={false}
          tick={(props) => <Tick {...props} />}
          interval={0}
          width={yAxisWidth}
          label={renderAxisYLabel(labelNames)}
        />
        <XAxis
          tick={tickStyles}
          tickLine={tickLineStyles}
          tickSize={10}
          axisLine={axisLineStyles}
          tickMargin={16}
          interval={0}
          type="number"
          ticks={ticks}
          label={renderAxisXLabel(labelValues)}
        />

        <Tooltip
          cursor={false}
          content={<TooltipContentWrapper activeDataIndex={activeIndex} TooltipContent={TooltipContent} />}
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && <Legend verticalAlign="top" align="right" />}
        {dataKeys.map((key, index) => {
          const color = getColor(index);
          return (
            <Bar key={key} dataKey={key} maxBarSize={30} fill={color} onMouseEnter={handleMouseEnter(index)}>
              <LabelList dataKey={key} fill={color} content={BarChartLabel} />
            </Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
};
