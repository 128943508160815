import React, { FC } from 'react';

import { Dialog } from 'common/components';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { RenameThemesForm } from './RenameThemesForm';
import { useRenameThemes } from './useRenameThemes';

export const RenameThemesDialog: FC<{
  isOpen: boolean;
  onClose(): void;
  onOpen(): void;
  themes: TextAnswerTheme[];
}> = ({ isOpen, onClose, onOpen, themes }) => {
  const { handleClose, handleSubmit, RenameFailureModal, isLoading } = useRenameThemes({
    onClose,
    onOpen,
    themes,
  });

  return (
    <>
      <Dialog
        description="Rename the Dominant Themes. Existing Themes can be renamed at anytime."
        isOpen={isOpen}
        onClose={handleClose}
        title="Rename Themes"
        showCancelButton={false}
        maxWidth="sm"
        fullWidth
      >
        <RenameThemesForm themes={themes} onClose={handleClose} isLoading={isLoading} onSubmit={handleSubmit} />
      </Dialog>
      {RenameFailureModal}
    </>
  );
};
