import React, { ComponentProps, FC, useCallback, useMemo } from 'react';
import { useDrop } from 'react-dnd';
import { useFieldArray, useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';
import { DragProvider, SortableItem, generateNumberOptions, withDragProvider } from 'common/components';
import { CustomDragLayer } from 'common/components/DragAndDrop';
import { FormErrors } from 'common/components/ReactHookForm';
import { RankingAnswer as RankingAnswerType, RankingQuestion } from 'domain/question';
import { get } from 'lodash';

import { AnswerItem } from '../../components';
import { RankingAnswerItem } from './components/RankingAnswerItem';

interface RankingAnswerProps {
  name: string;
  question: RankingQuestion;
  isObligatory: boolean;
}

const RankingAnswerComponent: FC<RankingAnswerProps> = ({ name: rootName, question, isObligatory }) => {
  const name = `${rootName}.choices` as const;
  const {
    control,
    trigger,
    formState: { errors, dirtyFields },
  } = useFormContext<{
    [key: string]: { choices: RankingAnswerType['choices'] };
  }>();
  const droppableType = `RankingAnswer-${name}`;

  const {
    fields: choices,
    move,
    update,
  } = useFieldArray({
    name,
    control,
    keyName: 'key',
  });

  const [, drop] = useDrop(() => ({ accept: droppableType }));

  const options = useMemo(() => generateNumberOptions(choices.length), [choices]);

  const triggerValidation = useCallback(async () => {
    const rankedFields = Object.keys(get(dirtyFields, name) || []).map((index) => `${name}.${index}.rank`);

    await trigger(rankedFields);

    const isAllRanked = choices.length === rankedFields.length;
    if (isAllRanked) {
      trigger(`${rootName}.isObligatory`);
    }
  }, [choices.length, dirtyFields, name, rootName, trigger]);

  const updateRank: ComponentProps<typeof SortableItem>['onDrop'] = useCallback(() => {
    choices.forEach((choice, index) => {
      update(index, { ...choice, rank: (++index).toString() });
    });
    triggerValidation();
  }, [choices, triggerValidation, update]);

  return (
    <AnswerItem
      title={question.title}
      isObligatory={isObligatory}
      description="Rank your answers according to your preferences by selecting the value on the left or dragging the options into your preferred order."
    >
      <DragProvider>
        <Stack gap={1} mb={2}>
          {choices?.map((choice, index) => (
            <Stack key={choice.key} direction="row" gap={1}>
              <SortableItem
                droppableType={droppableType}
                index={index}
                moveItem={move}
                onDrop={updateRank}
                sx={{ flex: 1 }}
                customPreview
                itemData={choice}
                ref={drop}
              >
                {({ handleRef, isDragging }) => (
                  <RankingAnswerItem
                    name={`${name}.${index}.rank`}
                    item={choice}
                    options={options}
                    onSelectRank={triggerValidation}
                    ref={handleRef}
                    isDragging={isDragging}
                  />
                )}
              </SortableItem>
            </Stack>
          ))}
          <CustomDragLayer droppableType={droppableType} Preview={RankingAnswerItem} />
        </Stack>
      </DragProvider>

      <FormErrors variant="hint" name={name} errors={errors} />
    </AnswerItem>
  );
};

export const RankingAnswer = withDragProvider(RankingAnswerComponent);
