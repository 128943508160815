import { makeStyles } from '@mui/styles';

export const useButtonIconStyles = makeStyles(
  ({ palette }) => ({
    icon: {
      margin: 3,
    },
    contrastColor: {
      transition: 'color 200ms ease-out',
      color: palette.getContrastText(palette.primary.main),
    },
  }),
  { name: 'buttonIcon' }
);
