import React, { FC } from 'react';
import { FormProvider } from 'react-hook-form';

import { Stack, Typography } from '@mui/material';
import { Button, PinInput, PinInputProps, usePinInputForm } from 'common/components';
import { Page } from 'common/layout';
import { grey } from 'common/theme/palette';

import { PinGuardContext } from './PinGuardContext';

export type PinGuardProps = Pick<PinInputProps, 'isPinInvalid' | 'isSubmitting' | 'length' | 'onChange'> & {
  title?: string;
  description?: string;
  children: JSX.Element;
  onSubmit(pin: string): void;
  validPin: string | undefined;
};

export const PinGuard: FC<PinGuardProps> = ({ title, description, validPin, children, onSubmit, ...pinInputProps }) => {
  const { methods, handleSubmit } = usePinInputForm({ onSubmit });
  const isSubmitDisabled = !methods.formState.isValid;

  if (validPin) {
    return <PinGuardContext.Provider value={validPin}>{children}</PinGuardContext.Provider>;
  }

  return (
    <Page sx={{ height: '100%', backgroundColor: grey[25] }}>
      <Stack alignItems="center" gap={2}>
        {title && (
          <Typography variant="natter-text-xl" color="text.primary">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="natter-text-sm" color="text.secondary" pb={1}>
            {description}
          </Typography>
        )}

        <Stack gap={3} component="form" onSubmit={handleSubmit}>
          <FormProvider {...methods}>
            <PinInput {...pinInputProps} />
          </FormProvider>

          <Button type="submit" isLoading={pinInputProps.isSubmitting} disabled={isSubmitDisabled}>
            Access
          </Button>
        </Stack>
      </Stack>
    </Page>
  );
};
