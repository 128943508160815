import { makeStyles } from '@mui/styles';

export const useDateRangeInputStyles = makeStyles(
  () => ({
    calendar: {
      display: 'block',
    },
  }),
  { name: 'dateRangeInput' }
);
