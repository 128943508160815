import { Typography, styled } from '@mui/material';

export const AnswerLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  lineHeight: '18px',
  fontSize: '12px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '9px',
  },
}));
