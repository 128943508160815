import React, { FC, memo } from 'react';

import { AppBar, Box, Divider, Stack, Toolbar, styled } from '@mui/material';
import { LogoContainer } from 'common/components';
import { NatterThemeProvider } from 'common/hocs';
import { useBreakpoints, useElementSize } from 'common/hooks';
import { useAppSelector } from 'store';
import { selectIsHeaderDisplayed } from 'store/features/app';

import { CurrentUserContainer, GoToHelpCenter, MobileMenuButton, TabletMenuButton } from './components';

const SectionWrapper = styled(Box)({
  flex: '1 1 33%',
  display: 'flex',
  alignItems: 'center',
  '& > a': { width: '100%' },
});

type TopBarProps = {
  center?: boolean;
  disableTabletMenuButton?: boolean;
};

export const TopBar: FC<TopBarProps> = memo(({ center = false, disableTabletMenuButton = false, children }) => {
  const [ref, size] = useElementSize();
  const displayHeader = useAppSelector(selectIsHeaderDisplayed);
  const { isMobile, isTablet } = useBreakpoints();
  const showTabletMenuButton = !disableTabletMenuButton && isTablet;

  if (!displayHeader) return null;

  return (
    <>
      <AppBar component="header" position="fixed" color="inherit" data-testid="ContentHeader" ref={ref}>
        <Toolbar>
          {center ? (
            <SectionWrapper justifyContent="center">
              <LogoContainer />
            </SectionWrapper>
          ) : (
            <>
              <SectionWrapper>
                {showTabletMenuButton && (
                  <Box mr={3}>
                    <TabletMenuButton />
                  </Box>
                )}
                <Stack justifyContent="center">
                  <LogoContainer />
                </Stack>
              </SectionWrapper>

              <NatterThemeProvider>
                <Stack direction="row" justifyContent="end" alignItems="center" gap={0.5}>
                  <GoToHelpCenter />
                  {isMobile ? (
                    <MobileMenuButton />
                  ) : (
                    <>
                      <Divider orientation="vertical" variant="middle" flexItem />
                      <CurrentUserContainer />
                    </>
                  )}
                </Stack>
              </NatterThemeProvider>
            </>
          )}
        </Toolbar>
        {children && (
          <Box p={2} pt={0}>
            {children}
          </Box>
        )}
      </AppBar>
      {/**
       * This is needed so we don't have anything behind the fixed position app bar
       * https://mui.com/material-ui/react-app-bar/#fixed-placement
       * */}
      <Toolbar sx={{ height: size.height }} disableGutters />
    </>
  );
});
