import { usePinContext } from 'common/guards/PinGuard/PinGuardContext';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { useAppSelector } from 'store';
import { useGetPublicSurveysReportAnswersQuery } from 'store/apis/publicSurveysReport';
import { selectDemographicFilters } from 'store/features/insightsReports';

export const useCurrentPublicSurveyReportAnswers = () => {
  const { reportId } = useCurrentReportId();
  const demographicFilters = useAppSelector(selectDemographicFilters);
  const pin = usePinContext();
  const shouldSkip = !pin;

  const { data } = useGetPublicSurveysReportAnswersQuery(
    { reportId, communityTagIds: demographicFilters, pin },
    { skip: shouldSkip }
  );

  return {
    data,
  };
};
