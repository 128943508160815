import React, { FC, useMemo } from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';

import { usePinContext } from 'common/guards/PinGuard/PinGuardContext';
import { InsightsReportContext, InsightsReportContextValue } from 'pages/InsightsReport';
import { BasicInsightsReportRoutes } from 'pages/InsightsReport/constants';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { surveyKeyMetrics } from 'pages/SurveyReport/constants';
import {
  useGetPublicSurveysReportByIdQuery,
  useGetPublicSurveysReportDemographicsQuery,
  useGetPublicSurveysReportKeyMetricsQuery,
} from 'store/apis/publicSurveysReport';

import { PublicSurveyReportRoutes } from './constants';

export const PublicSurveyReportProvider: FC = ({ children }) => {
  const { reportId, isInvalidReportId } = useCurrentReportId();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const pin = usePinContext();
  const shouldSkip = !pin;

  const {
    data: reportData,
    isError,
    isLoading,
  } = useGetPublicSurveysReportByIdQuery({ reportId, pin }, { skip: shouldSkip });

  const { data: keyMetricsApiData, isLoading: isKeyMetricsDataLoading } = useGetPublicSurveysReportKeyMetricsQuery(
    { reportId, pin },
    { skip: shouldSkip }
  );

  const { data: demographicData } = useGetPublicSurveysReportDemographicsQuery({ reportId, pin }, { skip: shouldSkip });

  const shouldShowFilters = useMemo(
    () => !matchPath(pathname, path + BasicInsightsReportRoutes.keyMetricsAndParticipantDemographics.path),
    [pathname, path]
  );

  const keyMetricsData = useMemo(
    () =>
      surveyKeyMetrics.map((metric) => ({
        ...metric,
        value: keyMetricsApiData?.[metric.key],
      })),
    [keyMetricsApiData]
  );

  const value: InsightsReportContextValue = useMemo(
    () => ({
      reportType: 'Survey',
      isPublicReport: true,
      demographicData,
      isError: isInvalidReportId || isError,
      isLoading,
      reportData,
      routes: PublicSurveyReportRoutes,
      isDeleting: false,
      isReportExported: false,
      keyMetricsData,
      isKeyMetricsDataLoading,
      shouldShowFilters,
      shouldShowMoreMenu: false,
    }),
    [
      isError,
      isInvalidReportId,
      isLoading,
      demographicData,
      reportData,
      shouldShowFilters,
      keyMetricsData,
      isKeyMetricsDataLoading,
    ]
  );

  return <InsightsReportContext.Provider value={value}>{children}</InsightsReportContext.Provider>;
};
