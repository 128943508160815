import React, { FC, useCallback, useMemo } from 'react';

import { ButtonMenu, MenuItemType } from 'common/components';

import { ChartSelectIcon } from './ChartSelectIcon';

export enum ChartType {
  Bar = 'Bar Chart',
  HorizontalBar = 'Horizontal Bar Chart',
  VerticalStackedBar = 'Vertical Stacked Bar Chart',
  HorizontalStackedBar = 'Horizontal Stacked Bar Chart',
  Donut = 'Donut Chart',
  Pie = 'Pie Chart',
  Histogram = 'Histogram',
  DensityPlot = 'Density Plot',
}

export const ChartSelect: FC<{
  availableChartTypes: ChartType[];
  value: ChartType;
  onChange(chartType: ChartType): void;
}> = ({ value, availableChartTypes, onChange }) => {
  const handleItemClick = useCallback(
    (chartType: ChartType) => () => {
      onChange(chartType);
    },
    [onChange]
  );

  const menuItems = useMemo(
    () =>
      availableChartTypes.map<MenuItemType>((chartType) => ({
        text: chartType,
        onClick: handleItemClick(chartType),
      })),
    [availableChartTypes, handleItemClick]
  );

  return (
    <ButtonMenu
      Icon={<ChartSelectIcon sx={{ '&.MuiSvgIcon-root': { fontSize: '24px' } }} />}
      variant="text"
      size="medium"
      items={menuItems}
      activeIndex={availableChartTypes.indexOf(value)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    />
  );
};
