import type { ImageFile } from 'common/components/_legacy/Form';

import { assert } from './assert';

export const getFileNameFromFilePath = (filePath: string): string => {
  const pathElements = filePath.split('\\');
  const lastIndex = pathElements.length - 1;
  return pathElements[lastIndex];
};

export const dataURItoFile = (dataURI: string, fileName: string): File => {
  const type = dataURI.split(';base64,')[0].split(':')[1];
  const bytes = atob(dataURI.split(';base64,')[1]);
  const data = new Uint8Array(new ArrayBuffer(bytes.length));

  for (let i = 0; i < bytes.length; i++) {
    data[i] = bytes.charCodeAt(i);
  }

  return new File([data.buffer], fileName, { type });
};

export const dataToCsvFile = (data: BlobPart, fileName: string): void => {
  try {
    dataToFile(data, fileName, 'text/csv;charset=utf-8;');
  } catch {
    console.error('Unable to convert data to CSV file');
  }
};

const dataToFile = (data: BlobPart, fileName: string, type: string): void => {
  const fileData = new Blob([data], {
    type,
  });
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style.display = 'none';
  const url = window.URL.createObjectURL(fileData);
  a.href = url;
  a.download = fileName;
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

export const blobToData = (blob: Blob): Promise<string> =>
  new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(`${reader.result}`);
    reader.readAsDataURL(blob);
  });

export const openFileInNewTab = (url: string): void => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export const getImageFormData = <I extends { data?: string; name?: string } = ImageFile>(
  imageData: I,
  name = 'image'
) => {
  assert(imageData.data && imageData.name);

  const imageFileName = getFileNameFromFilePath(imageData.name);
  const imageFile = dataURItoFile(imageData.data, imageFileName);
  const imageFormData = new FormData();
  imageFormData.append(name, imageFile);

  return imageFormData;
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};
