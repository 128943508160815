import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { IconButton } from 'common/components/Buttons/IconButton';
import { DeleteIcon } from 'common/components/Icons';

import { TryAgainButton, UploadPreviewContainer } from './UploadPreview.styled';
import type { Status as StatusType } from './types';
import { calculateStatus } from './utils';

export type UploadPreviewProps = {
  progress?: number;
  size?: number;
  status?: StatusType;
  name: string | undefined;
  onCancelClick?(): void;
  onRemoveClick?(): void;
  onTryAgainClick?(): void;
};

export const UploadPreview: FC<UploadPreviewProps> = ({
  children,
  name = '',
  progress,
  size,
  status = 'idle',
  onCancelClick,
  onRemoveClick,
  onTryAgainClick,
}) => {
  const statusLabel = calculateStatus({ progress, size, status });

  return (
    <UploadPreviewContainer
      flexDirection="row"
      gap={1.25}
      color={status === 'error' ? 'error' : undefined}
      data-testid="uploadPreview"
    >
      {status === 'compressing' || status === 'uploading' ? (
        <Box flexShrink={0}>
          <CircularProgress color="inherit" size={16} />
        </Box>
      ) : (
        children && <Box flexShrink={0}>{children}</Box>
      )}

      <Box flexGrow={1}>
        <Stack flexDirection="column" justifyContent="center" height="100%">
          {status === 'error' ? (
            <>
              <Typography variant="natter-text-sm">Upload failed</Typography>

              <Typography variant="natter-text-sm" color="error.main">
                {name}
              </Typography>

              <Box mt={1}>
                <TryAgainButton onClick={onTryAgainClick}>Try again</TryAgainButton>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="natter-text-sm" fontWeight="bold">
                {name}
              </Typography>

              {statusLabel && (
                <Typography variant="natter-text-sm" color="text.secondary">
                  {statusLabel}
                </Typography>
              )}
            </>
          )}
        </Stack>
      </Box>

      <IconButton
        variant="text"
        size="small"
        sx={({ spacing }) => ({ padding: spacing(0), height: spacing(2.5), width: spacing(2.5) })}
        {...(status === 'uploading'
          ? { 'aria-label': 'Cancel', onClick: onCancelClick }
          : { 'aria-label': 'Remove', onClick: onRemoveClick })}
      >
        {status === 'uploading' ? <CloseIcon /> : <DeleteIcon />}
      </IconButton>
    </UploadPreviewContainer>
  );
};
