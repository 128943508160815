import { RefObject, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import type { Identifier, XYCoord } from 'dnd-core';

interface DragItem {
  index: number;
}

const getKeepItem = ({
  clientRect,
  clientOffset,
  dragIndex,
  hoverIndex,
}: {
  clientRect: DOMRect;
  clientOffset: XYCoord;
  dragIndex: number;
  hoverIndex: number;
}) => {
  const { top, bottom } = clientRect;
  const hoverMiddleY = (bottom - top) / 2;
  const hoverClientY = clientOffset.y - top;

  const draggingDownwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
  const draggingUpwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;

  return draggingUpwards || draggingDownwards;
};

interface UseDragAndDropProps {
  droppableType: string;
  index: number;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  previewRef: RefObject<HTMLElement>;
  canDrag?: boolean;
  itemData?: object;
  handleRef?: RefObject<HTMLElement>;
  customPreview?: boolean;
}

export const useDragAndDrop = ({
  index,
  canDrag,
  itemData,
  previewRef,
  handleRef,
  droppableType,
  moveItem,
  customPreview,
}: UseDragAndDropProps) => {
  const dragResult = useDrag({
    item: () => ({
      index,
      ...itemData,
      width: previewRef.current?.getBoundingClientRect().width,
    }),
    type: droppableType,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag,
  });

  const [{ isDragging }, drag, preview] = dragResult;

  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: droppableType,
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
    }),
    hover: (item, monitor) => {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const keepItem = getKeepItem({
        dragIndex,
        hoverIndex,
        clientRect: previewRef.current.getBoundingClientRect(),
        clientOffset: monitor.getClientOffset() as XYCoord,
      });
      if (keepItem) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  if (handleRef?.current) {
    drag(handleRef);
    if (!customPreview) {
      preview(previewRef);
    }
    drop(previewRef);
  } else {
    drag(drop(previewRef));
  }

  return {
    isDragging,
    handlerId,
    drag,
    drop,
    preview,
  };
};

export const useSortableItem = ({
  onDrop,
  onDragStart,
  isDragging,
  startIndexRef,
  index,
}: {
  onDrop?: (endIndex: number) => void;
  onDragStart?: () => void;
  isDragging: boolean;
  startIndexRef: React.MutableRefObject<number | undefined>;
  index: number;
}) => {
  useEffect(() => {
    if (!onDrop) return;

    if (isDragging && startIndexRef.current === undefined) {
      startIndexRef.current = index;
    }
    if (isDragging || startIndexRef.current === undefined) {
      return;
    }
    if (startIndexRef.current !== index) {
      onDrop(index);
    }
    startIndexRef.current = undefined;
  }, [isDragging, onDrop, index, startIndexRef]);

  useEffect(() => {
    if (onDragStart && isDragging) {
      onDragStart();
    }
  }, [isDragging, onDragStart]);
};
