import React from 'react';

import HelpIcon from '@mui/icons-material/Help';
import { IconButton } from 'common/components';

export const GoToHelpCenter = () => (
  <a title="Visit Natter Help Center" href="https://intercom.help/natter/en" target="_blank" rel="noreferrer">
    <IconButton size="large" variant="plain">
      <HelpIcon />
    </IconButton>
  </a>
);
