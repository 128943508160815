import React from 'react';
import { FieldValues, useController } from 'react-hook-form';

import { Stack } from '@mui/material';
import { BoxWithLabel } from 'common/components/BoxWithLabel';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';

import { TextFieldProps } from '../TextField';
import { StyledTextField } from './TextFieldWithLabel.styled';

export const TextFieldWithLabel = <T extends FieldValues>({
  label,
  name,
  onChange,
  disabled,
  ...textFieldProps
}: TextFieldProps<T>) => {
  const {
    field,
    fieldState: { error },
  } = useController<T>({
    name,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(field.value);
    field.onChange(event);
  };

  return (
    <Stack>
      <BoxWithLabel label={label} height={48.13} disabled={disabled}>
        <StyledTextField
          {...textFieldProps}
          {...field}
          disabled={disabled}
          variant="standard"
          value={field.value || ''}
          onChange={handleChange}
        />
      </BoxWithLabel>
      <FormFieldHint error>{error?.message}</FormFieldHint>
    </Stack>
  );
};
