import React, { FC } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { DemographicFiltersToolbarRoot } from './DemographicFiltersToolbar.styled';

export const DemographicFiltersToolbar: FC<{
  open: boolean;
}> = ({ open, children }) => (
  <AnimatePresence>
    {open && (
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{
          height: 'auto',
          opacity: 1,
          transition: {
            height: {
              duration: 0.2,
            },
            opacity: {
              duration: 0.1,
              delay: 0.1,
            },
          },
        }}
        exit={{
          height: 0,
          opacity: 0,
          transition: {
            height: {
              duration: 0.2,
            },
            opacity: {
              duration: 0.1,
            },
          },
        }}
      >
        <DemographicFiltersToolbarRoot>{children}</DemographicFiltersToolbarRoot>
      </motion.div>
    )}
  </AnimatePresence>
);
