import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

import { IAgoraRTCClient, ICameraVideoTrack, IMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';
import { logger } from 'common/services';
import { getErrorMessage } from 'common/utils';
import { MediaTrackState } from 'domain/Broadcast';

import { CreateTrackError } from '.';
import { useUnmountingRef } from '../../useUnmountingRef';

export type ILocalMediaTrack = ICameraVideoTrack | IMicrophoneAudioTrack;

export type UseLocalMediaTrackProps<T extends ILocalMediaTrack> = {
  mediaType: 'video' | 'audio';
  activeDeviceId?: string;
  client: IAgoraRTCClient;
  isEnabled: boolean;
  createMediaTrack(): Promise<T>;
  onTrackPublished?(mediaTrack: T): void;
  onInitTrackFailed?(error: unknown): void;
  onCleanupTrackFailed?(error: unknown): void;
};

export const useLocalMediaTrack = <T extends ILocalMediaTrack>({
  mediaType,
  activeDeviceId,
  client,
  isEnabled,
  createMediaTrack,
  onTrackPublished,
  onInitTrackFailed,
  onCleanupTrackFailed,
}: UseLocalMediaTrackProps<T>) => {
  const localTrackRef = useRef<T | undefined>(undefined);
  const [localTrackState, setLocalTrackState] = useState<MediaTrackState>(
    isEnabled ? MediaTrackState.Enabled : MediaTrackState.Disabled
  );
  const [doesLocalTrackExists, setDoesLocalTrackExists] = useState<boolean>(false);
  const isUnmountingRef = useUnmountingRef();

  useEffect(() => {
    logger.addBreadcrumb(`[useLocalMediaTrack] Active ${mediaType} device changed`, {
      data: {
        activeDeviceId,
        doesTrackExists: !!localTrackRef.current,
      },
    });

    if (!(activeDeviceId && localTrackRef.current)) {
      return;
    }

    localTrackRef.current.setDevice(activeDeviceId);
  }, [activeDeviceId, mediaType]);

  useEffect(() => {
    const toggleMedia = async (value: boolean) => {
      if (!localTrackRef.current) {
        setLocalTrackState(value ? MediaTrackState.Enabled : MediaTrackState.Disabled);
        return;
      }

      logger.addBreadcrumb(`[useLocalMediaTrack] Toggle ${mediaType}`, {
        data: { isEnabled: value },
      });

      try {
        setLocalTrackState(value ? MediaTrackState.Enabling : MediaTrackState.Disabling);
        await localTrackRef.current.setMuted(!value);
        setLocalTrackState(value ? MediaTrackState.Enabled : MediaTrackState.Disabled);
      } catch (error) {
        logger.error(`[useLocalMediaTrack] Error while toggling ${mediaType}`, { extra: { error } });
        setLocalTrackState(value ? MediaTrackState.Disabled : MediaTrackState.Enabled);
      }
    };

    toggleMedia(isEnabled);
  }, [isEnabled, mediaType]);

  const initTrack = useCallback(async () => {
    if (!activeDeviceId) {
      logger.addBreadcrumb(
        `[useLocalMediaTrack] Init ${mediaType} track skipped - active ${mediaType} device ID missing`
      );
      return;
    }

    logger.addBreadcrumb(`[useLocalMediaTrack] Init ${mediaType} track`, {
      data: {
        activeDeviceId,
      },
    });

    try {
      localTrackRef.current = await createMediaTrack();
      logger.addBreadcrumb(`[useLocalMediaTrack] ${mediaType} track created`);

      await localTrackRef.current.setMuted(localTrackState === MediaTrackState.Disabled);

      if (client.connectionState !== 'CONNECTED') {
        throw new CreateTrackError(mediaType, 'Agora client not connected');
      }
      if (isUnmountingRef.current) {
        throw new CreateTrackError(mediaType, 'Component already unmounting');
      }
      if (client.role === 'audience') {
        throw new CreateTrackError(mediaType, 'User is not a speaker');
      }

      await client.publish(localTrackRef.current);
      logger.addBreadcrumb(`[useLocalMediaTrack] ${mediaType} track published`);

      if (isUnmountingRef.current) {
        throw new CreateTrackError(mediaType, 'Component already unmounting');
      }

      onTrackPublished?.(localTrackRef.current);

      setDoesLocalTrackExists(true);
    } catch (error) {
      logger.addBreadcrumb(`[useLocalMediaTrack] Error while initializing ${mediaType} track`, {
        data: { error: getErrorMessage(error) },
      });

      if (isUnmountingRef.current) return;

      setLocalTrackState(MediaTrackState.Disabled);
      onInitTrackFailed?.(error);
      // eslint-disable-next-line require-atomic-updates
      localTrackRef.current = undefined;
      setDoesLocalTrackExists(false);
    }
  }, [
    activeDeviceId,
    localTrackState,
    client,
    isUnmountingRef,
    onInitTrackFailed,
    onTrackPublished,
    createMediaTrack,
    mediaType,
  ]);

  const cleanupTrack = useCallback(async () => {
    logger.addBreadcrumb(`[useLocalMediaTrack] Cleaning up ${mediaType} track`, {
      data: { doesTrackExists: !!localTrackRef.current },
    });

    try {
      await client.unpublish(localTrackRef.current);
      logger.addBreadcrumb(`[useLocalMediaTrack] ${mediaType} track removed`);

      // eslint-disable-next-line require-atomic-updates
      localTrackRef.current = undefined;
      setDoesLocalTrackExists(false);
    } catch (error: unknown) {
      logger.error(`[useLocalMediaTrack] Error while removing ${mediaType} track`, { extra: { error } });
      onCleanupTrackFailed?.(error);
    }
  }, [client, onCleanupTrackFailed, mediaType]);

  useEffect(
    () => () => {
      logger.addBreadcrumb('[useLocalMediaTrack] Cleaning up', {
        data: { doesTrackExists: !!localTrackRef.current },
      });
      localTrackRef.current?.getMediaStreamTrack().stop();
      localTrackRef.current?.stop();
      localTrackRef.current?.close();
    },
    []
  );

  return {
    localTrackRef: localTrackRef as MutableRefObject<T>,
    doesLocalTrackExists,
    localTrackState,
    initTrack,
    cleanupTrack,
  };
};
