import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Stack } from '@mui/material';
import { FormFieldHint } from 'common/components/Inputs/FormFieldHint';
import { Checkbox } from 'common/components/ReactHookForm';
import {
  MultipleChoiceMultipleSelectAnswer as MultipleChoiceMultipleSelectAnswerType,
  MultipleChoiceMultipleSelectQuestion,
} from 'domain/question';

import { AnswerItem } from '../../components';

export const MultipleChoiceMultipleSelectAnswer: FC<{
  name: string;
  question: MultipleChoiceMultipleSelectQuestion;
  isObligatory: boolean;
}> = ({ name: parentName, question, isObligatory }) => {
  const name = `${parentName}.${'choices' as keyof MultipleChoiceMultipleSelectAnswerType}`;
  const { trigger } = useFormContext();

  const validate = () => trigger(name);

  return (
    <AnswerItem title={question.title} isObligatory={isObligatory} description="Select one or more answers below.">
      <Controller
        name={name}
        render={({ fieldState: { error } }) => (
          <Stack gap={1.5}>
            <Stack>
              {question.choices.map(({ id, text }, index) => (
                <Checkbox key={id} label={text} name={`${name}.${index}.value`} onChange={validate} />
              ))}
            </Stack>
            <FormFieldHint error>{error?.message}</FormFieldHint>
          </Stack>
        )}
      />
    </AnswerItem>
  );
};
