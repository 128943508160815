import React, { FC, MouseEventHandler, ReactNode, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Stack, SxProps, Theme } from '@mui/material';

import { Loadable } from '../../Loadable';
import { Menu, MenuProps } from '../../Menu';
import type { ButtonProps } from '../Button';
import { IconButton, IconButtonProps } from '../IconButton';
import type { IconButtonVariant } from '../IconButton/types';

const DefaultButton: FC<
  {
    active: boolean;
    variant?: IconButtonVariant;
  } & Pick<IconButtonProps, 'size' | 'color'>
> = ({ active, size = 'small', variant, color = 'primary', children }) => (
  <IconButton
    variant={variant}
    size={size}
    className={active ? 'active' : undefined}
    color={color}
    aria-label="More actions"
  >
    {children}
  </IconButton>
);

type Props = Omit<ButtonProps, 'color'> &
  Pick<IconButtonProps, 'color'> &
  Pick<MenuProps, 'anchorOrigin' | 'transformOrigin' | 'items' | 'activeIndex'> & {
    'data-testid'?: string;
    menuSx?: SxProps<Theme> | undefined;
    variant?: IconButtonVariant;
    Icon?: ReactNode;
  };

export const ButtonMenu: FC<Props> = ({
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  children,
  menuSx,
  isLoading = false,
  size,
  variant = 'outlined',
  Icon = <MoreVertIcon />,
  color = variant === 'text' ? 'text' : undefined,
  ...props
}) => {
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);

  const handleClick: MouseEventHandler<HTMLDivElement> = (event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  };

  const handleClose = () => setAnchor(null);

  return (
    <>
      <Stack data-testid={props['data-testid']} alignItems="center" position="relative">
        {isLoading && <Loadable isLoading transitionDelay={false} size={20} sx={{ position: 'absolute' }} />}
        <Box onClick={handleClick} sx={{ visibility: isLoading ? 'hidden' : 'visible' }}>
          {children ?? (
            <DefaultButton size={size} active={Boolean(anchor)} variant={variant} color={color}>
              {Icon}
            </DefaultButton>
          )}
        </Box>
      </Stack>
      <Menu
        sx={menuSx}
        anchor={anchor}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        {...props}
      />
    </>
  );
};
