import React from 'react';

import { Clear } from '@mui/icons-material';
import { type Components, type Theme } from '@mui/material';

import { grey } from '../palette';

export const MuiChip: Components<Theme>['MuiChip'] = {
  defaultProps: {
    deleteIcon: (
      <Clear
        sx={{
          height: 16,
          width: 16,
          '&.MuiChip-deleteIcon': {
            color: grey[800],
          },
        }}
      />
    ),
  },
  styleOverrides: {
    root: {
      borderRadius: 3,
      height: 20,
    },
    filled: ({ theme: { palette } }) => ({
      '&:not(.MuiChip-filledError)': {
        backgroundColor: palette.grey[200],
        color: palette.grey[800],
      },
    }),
    sizeSmall: ({ theme: { typography } }) => ({
      ...typography['natter-text-sm'],
    }),
  },
};
