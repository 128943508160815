import React, { useMemo } from 'react';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import { find, omit } from 'lodash';

import { TextField } from '../TextField';

export type AutocompleteOptionItem<T = string> = {
  value: T;
  label: string;
  Icon?: JSX.Element;
};

export type AutocompleteProps<T> = Pick<
  MuiAutocompleteProps<AutocompleteOptionItem<T> | null, false, false, false>,
  'disabled' | 'defaultValue' | 'placeholder' | 'options' | 'value' | 'isOptionEqualToValue'
> & {
  error?: boolean;
  hint?: string;
  label?: string;
  onChange?: (selected: AutocompleteOptionItem<T> | null, event?: React.SyntheticEvent) => void;
  innerRef?: React.Ref<unknown>;
};

export const Autocomplete = <T,>({
  error,
  hint,
  label,
  options,
  placeholder,
  onChange,
  innerRef,
  ...autocompleteProps
}: AutocompleteProps<T>) => {
  const handleChange = (event: React.SyntheticEvent, value: AutocompleteOptionItem<T> | null) => {
    onChange?.(omit(value, 'Icon'), event);
  };
  const value = useMemo(
    () => find(options, { value: autocompleteProps.value?.value }) as AutocompleteOptionItem<T> | undefined,
    [autocompleteProps.value, options]
  );

  return (
    <MuiAutocomplete<AutocompleteOptionItem<T> | null, false, false, false>
      clearOnEscape
      renderInput={(params) => (
        <TextField label={label} placeholder={placeholder} error={error} hint={hint} {...params} prefix={value?.Icon} />
      )}
      renderOption={(props, option, state, ownerState) => (
        <MenuItem {...props}>
          {option?.Icon && <ListItemIcon sx={{ color: 'inherit' }}>{option?.Icon}</ListItemIcon>}
          <ListItemText>{ownerState.getOptionLabel(option)}</ListItemText>
        </MenuItem>
      )}
      fullWidth
      options={options}
      onChange={handleChange}
      popupIcon={<KeyboardArrowDownIcon sx={({ palette }) => ({ color: palette.grey[600] })} />}
      slotProps={{
        paper: {
          sx: ({ spacing }) => ({
            marginTop: spacing(1),
          }),
        },
      }}
      {...autocompleteProps}
      ref={innerRef}
    />
  );
};
