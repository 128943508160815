import React, { FC } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Divider } from '@mui/material';
import { ButtonMenu, IconButton, Tooltip } from 'common/components';
import { DeleteIcon } from 'common/components/Icons';
import { useConfirmationModal } from 'common/hooks/useConfirmationModal';
import { useInsightsReportContext } from 'pages/InsightsReport/hooks';

import { ShareInsightsReport } from './components';

export const InsightsReportMoreMenuLayout: FC<{
  isLoading: boolean;
  onDelete?(): void;
}> = ({ isLoading, onDelete }) => {
  const [openDeleteConfirmation, DeleteConfirmationModal] = useConfirmationModal(
    {
      title: 'Delete Report',
      content:
        'Are you sure you want to delete this Report? By doing so, you will lose the data associated with this Report and you will need to create a new one from scratch.',
      cancelLabel: 'No, keep it',
      confirmLabel: 'Yes, delete',
      severity: 'danger',
      maxWidth: 'xs',
    },
    onDelete
  );
  const { exportReport, isReportExported, reportData, reportType } = useInsightsReportContext();
  const exportTooltip = isReportExported ? 'Report is currently exporting' : 'Export as CSV';

  return (
    <>
      {exportReport && (
        <>
          <Tooltip title={exportTooltip} placement="bottom-end">
            <IconButton variant="text" onClick={exportReport} disabled={isReportExported}>
              <DownloadIcon />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
        </>
      )}
      {reportData && <ShareInsightsReport reportData={reportData} reportType={reportType} />}
      {!!onDelete && (
        <ButtonMenu
          isLoading={isLoading}
          size="medium"
          items={[
            {
              text: 'Delete Report',
              icon: <DeleteIcon />,
              severity: 'danger',
              onClick: openDeleteConfirmation,
            },
          ]}
        />
      )}
      {DeleteConfirmationModal}
    </>
  );
};
