import { Typography, styled } from '@mui/material';
import type { TypographyWithComponent } from 'common/theme/typography';

export const EventPreviewScheduledTimeLabel = styled(Typography, {
  name: 'EventPreviewScheduledTime',
})<TypographyWithComponent>(({ theme: { breakpoints, palette, typography } }) => ({
  ...typography['natter-text-xs'],

  color: palette.text.secondary,

  [breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

EventPreviewScheduledTimeLabel.defaultProps = {
  component: 'span',
};
