import { uuid } from 'common/utils';
import {
  type Answer,
  type AnswerBase,
  type CreateAnswerRequest,
  CreateAnswerVariants,
  type CreateMultipleChoiceMultipleSelectAnswerRequest,
  type CreateMultipleChoiceSingleSelectAnswerRequest,
  type CreateNPSAnswerRequest,
  type CreateRankingAnswerRequest,
  type CreateSliderAnswerRequest,
  type CreateTextAnswerRequest,
  type MultipleChoiceMultipleSelectAnswer,
  type MultipleChoiceMultipleSelectQuestion,
  type MultipleChoiceSingleSelectAnswer,
  type NPSAnswer,
  type QuestionDefinition,
  QuestionVariants,
  type RankingAnswer,
  type RankingQuestion,
  type SliderAnswer,
  type TextAnswer,
  isMultipleChoiceMultipleSelectAnswer,
  isMultipleChoiceSingleSelectAnswer,
  isNPSAnswer,
  isRankingAnswer,
  isSliderAnswer,
  isTextAnswer,
} from 'domain/question';
import { shuffle } from 'lodash';
import { flow, map, orderBy } from 'lodash/fp';

const QuestionTypeAnswerTypeMap = {
  [QuestionVariants.TextQuestion]: CreateAnswerVariants.TextQuestion,
  [QuestionVariants.MultipleChoiceSingleSelectQuestion]: CreateAnswerVariants.MultipleChoiceSingleSelectQuestion,
  [QuestionVariants.MultipleChoiceMultipleSelectQuestion]: CreateAnswerVariants.MultipleChoiceMultipleSelectQuestion,
  [QuestionVariants.RankingQuestion]: CreateAnswerVariants.RankingQuestion,
  [QuestionVariants.NPSQuestion]: CreateAnswerVariants.NPSQuestion,
  [QuestionVariants.SliderQuestion]: CreateAnswerVariants.Slider,
};

export const mapQuestionToFormAnswer = ({ question, questionAssignment }: QuestionDefinition): Answer => {
  const baseAnswer: AnswerBase = {
    answerId: uuid(),
    assignmentId: questionAssignment.assignmentId,
    questionId: question.questionId,
    type: QuestionTypeAnswerTypeMap[question.type],
    isObligatory: questionAssignment.isObligatory ?? false,
  };
  switch (baseAnswer.type) {
    case CreateAnswerVariants.TextQuestion: {
      const answer: TextAnswer = { ...baseAnswer, text: '' };
      return answer;
    }
    case CreateAnswerVariants.MultipleChoiceSingleSelectQuestion: {
      const answer: MultipleChoiceSingleSelectAnswer = { ...baseAnswer, choiceId: undefined };
      return answer;
    }
    case CreateAnswerVariants.MultipleChoiceMultipleSelectQuestion: {
      const answer: MultipleChoiceMultipleSelectAnswer = {
        ...baseAnswer,
        choices: (question as MultipleChoiceMultipleSelectQuestion).choices.map((c) => ({
          ...c,
          value: false,
        })),
      };
      return answer;
    }
    case CreateAnswerVariants.RankingQuestion: {
      const { choices, randomizeChoices } = question as RankingQuestion;
      const mappedChoices = choices.map((c) => ({ ...c, rank: '' as const }));
      const answer: RankingAnswer = {
        ...baseAnswer,
        choices: randomizeChoices ? shuffle(mappedChoices) : mappedChoices,
      };
      return answer;
    }
    case CreateAnswerVariants.NPSQuestion: {
      const answer: NPSAnswer = { ...baseAnswer, npsValue: -1 };
      return answer;
    }
    case CreateAnswerVariants.Slider: {
      const sliderValue = null;
      const answer: SliderAnswer = { ...baseAnswer, sliderValue };
      return answer;
    }
  }
};

const getAnswerBase = (answer: Answer): AnswerBase => ({
  answerId: answer.answerId,
  assignmentId: answer.assignmentId,
  questionId: answer.questionId,
  type: answer.type,
  isObligatory: answer.isObligatory,
});

const isEmptyAnswer = (answer: Answer) => {
  if (isTextAnswer(answer)) return !answer.text;
  if (isMultipleChoiceSingleSelectAnswer(answer)) return answer.choiceId === undefined || answer.choiceId === null;
  if (isMultipleChoiceMultipleSelectAnswer(answer)) return !answer.choices.some((c) => c.value);
  if (isRankingAnswer(answer)) return !answer.choices.length || !answer.choices.every(({ rank }) => rank);
  if (isNPSAnswer(answer)) return answer.npsValue < 0;
  if (isSliderAnswer(answer)) return answer.sliderValue === null || answer.sliderValue < 0;
  return false;
};

const filterOutEmptyOptionalAnswers = (answer: Answer) => !isEmptyAnswer(answer) || answer.isObligatory;

export const mapEventAnswersToApiCall = (answers: Answer[]): CreateAnswerRequest[] =>
  answers.filter(filterOutEmptyOptionalAnswers).map((answer) => {
    switch (answer.type) {
      case CreateAnswerVariants.MultipleChoiceSingleSelectQuestion: {
        return answer as MultipleChoiceSingleSelectAnswer as CreateMultipleChoiceSingleSelectAnswerRequest;
      }
      case CreateAnswerVariants.MultipleChoiceMultipleSelectQuestion: {
        const multipleChoiceMultipleSelectAnswer = answer as MultipleChoiceMultipleSelectAnswer;
        return {
          ...getAnswerBase(answer),
          choiceIds: multipleChoiceMultipleSelectAnswer.choices.filter((c) => c.value).map((c) => c.id),
        } as CreateMultipleChoiceMultipleSelectAnswerRequest;
      }
      case CreateAnswerVariants.RankingQuestion: {
        const rankingAnswer = answer as RankingAnswer;
        return {
          ...getAnswerBase(answer),
          choiceIds: flow(orderBy('rank', 'asc'), map('id'))(rankingAnswer.choices),
        } as CreateRankingAnswerRequest;
      }
      case CreateAnswerVariants.NPSQuestion: {
        const npsAnswer = answer as NPSAnswer;
        return {
          ...getAnswerBase(answer),
          npsValue: npsAnswer.npsValue,
        } as CreateNPSAnswerRequest;
      }
      case CreateAnswerVariants.Slider: {
        const sliderAnswer = answer as SliderAnswer;
        return {
          ...getAnswerBase(answer),
          sliderValue: sliderAnswer.sliderValue,
        } as CreateSliderAnswerRequest;
      }
      default:
        return answer as TextAnswer as CreateTextAnswerRequest;
    }
  });
