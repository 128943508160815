import React, { FC, useMemo } from 'react';

import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { TooltipContentWrapper } from '../../components';
import { CHART_MIN_HEIGHT } from '../../constants';
import { useChartAxis, useChartColors, useTooltip } from '../../hooks';
import { BasicChartProps } from '../../types';
import { calculateTicks } from '../../utils';
import { calculateDensityPlotData } from './utils';

export const DensityPlot: FC<BasicChartProps> = ({
  labelValues,
  labelNames,
  data,
  dataKeys = ['value'],
  height = CHART_MIN_HEIGHT,
  TooltipContent,
}) => {
  const { getColor } = useChartColors();
  const { tickStyles, axisLineStyles, renderAxisYLabel, renderAxisXLabel } = useChartAxis();
  const densityPlotData = useMemo(() => calculateDensityPlotData({ data, dataKeys }), [data, dataKeys]);
  const yTicks = useMemo(() => calculateTicks({ data: densityPlotData, dataKeys }), [densityPlotData, dataKeys]);
  const xTicks = useMemo(
    () => densityPlotData.filter(({ x0 }) => x0 !== undefined).map(({ x0 }) => Number(x0)),
    [densityPlotData]
  );
  const { activeIndex, handleMouseEnter } = useTooltip();

  return (
    <ResponsiveContainer debounce={300} width="100%" height={height}>
      <AreaChart data={densityPlotData} margin={{ top: 10, right: 50, left: 50, bottom: 40 }}>
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey="name"
          tickLine={false}
          axisLine={false}
          tickMargin={16}
          interval={0}
          allowDataOverflow
          tick={tickStyles}
          ticks={xTicks}
          type="number"
          label={renderAxisXLabel(labelNames)}
          domain={[xTicks[0], xTicks[xTicks.length - 1]]}
        />
        <YAxis
          tickLine={false}
          axisLine={axisLineStyles}
          interval={0}
          ticks={yTicks}
          tick={tickStyles}
          label={renderAxisYLabel(labelValues)}
          width={40}
        />
        <Tooltip
          cursor={false}
          content={
            <TooltipContentWrapper
              shouldDisplay={({ x0 }) => x0 !== undefined}
              activeDataIndex={activeIndex}
              TooltipContent={TooltipContent}
            />
          }
          allowEscapeViewBox={{ x: true, y: true }}
        />
        {dataKeys.length > 1 && <Legend verticalAlign="top" align="right" />}
        {dataKeys.map((key, dataKeyIndex) => (
          <Area
            key={key}
            dataKey={key}
            type="monotone"
            fill={getColor(dataKeyIndex)}
            onMouseEnter={handleMouseEnter(dataKeyIndex)}
            fillOpacity={1}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};
