import { styled } from '@mui/material';

export const EventSponsorsWrapper = styled('div', { name: 'EventSponsorsWrapper' })(
  ({ theme: { spacing, breakpoints } }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: spacing(2),

    [breakpoints.down('sm')]: {
      gap: spacing(1),
    },
  })
);

export const EventSponsorLink = styled('a', { name: 'EventSponsorLink' })(
  ({ theme: { palette, spacing, breakpoints } }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    border: '1px solid',
    borderColor: palette.divider,
    borderRadius: spacing(1),
    padding: spacing(0.5),
    width: spacing(10),
    height: spacing(10),
    transition: 'all 0.1s linear',

    [breakpoints.down('sm')]: {
      width: spacing(5),
      height: spacing(5),
      padding: spacing(0.5),
    },
  })
);

export const EventSponsorImage = styled('img', { name: 'EventSponsorImage' })({
  flex: 1,
  minWidth: 0,
  minHeight: 0,
});
