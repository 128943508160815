import React, { FC } from 'react';

import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, IconButtonProps, useTheme } from '@mui/material';

import { useButtonIconStyles } from './ButtonIcon.styles';

interface ButtonIconProps extends IconButtonProps {
  icon: IconProp;
  iconSize?: SizeProp;
  fullWidth?: boolean;
  contrastColor?: boolean;
  tooltip?: string;
}

export const ButtonIcon: FC<ButtonIconProps> = ({
  icon,
  color = 'primary',
  size = 'small',
  iconSize = 'sm',
  hidden,
  fullWidth,
  sx,
  contrastColor,
  ...props
}) => {
  const classes = useButtonIconStyles();
  const { palette } = useTheme();
  if (hidden) {
    return null;
  }

  return (
    <IconButton
      size={size}
      color={color}
      hidden={hidden}
      {...props}
      sx={(theme) => ({
        width: fullWidth ? '100%' : undefined,
        ...(typeof sx === 'function' ? (sx as Function)(theme) : sx),
      })}
    >
      <FontAwesomeIcon
        fill={palette.common.black}
        icon={icon}
        size={iconSize}
        className={contrastColor ? classes.contrastColor : undefined}
      />
    </IconButton>
  );
};
