import { useEffect } from 'react';

import { useConfirmationModal } from 'common/hooks';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { useMergeThemesMutation } from 'store/apis/insightsReport';

import { MergeThemesFormValues } from './schema';

export const useMergeThemes = ({ onClose, onOpen }: { onClose(): void; onOpen(): void }) => {
  const { reportId } = useCurrentReportId();
  const [mergeThemes, { isLoading, isSuccess, isError, reset }] = useMergeThemesMutation();
  const [openRenameFailureModal, RenameFailureModal] = useConfirmationModal(
    {
      cancelLabel: 'Back to Report',
      confirmLabel: 'Try again',
      title: 'Merging unsuccessful',
      description: `The Theme you have selected couldn’t be Merged. Try again or contact support@natter.co if the issue persists.`,
      severity: 'danger',
      variant: 'confirmation',
      maxWidth: 'xs',
    },
    () => {
      onOpen();
    }
  );

  const handleSubmit = (values: MergeThemesFormValues) =>
    mergeThemes({
      reportId,
      themeIds: values.themes.filter((theme) => theme.isSelected).map((theme) => theme.themeId),
      themeName: values.mergedThemesName,
    });

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  useEffect(() => {
    if (!isSuccess) return;

    onClose();
    reset();
  }, [isSuccess, onClose, reset]);

  useEffect(() => {
    if (!isError) return;

    onClose();
    openRenameFailureModal();
    reset();
  }, [isError, onClose, openRenameFailureModal, reset]);

  return {
    handleClose,
    handleSubmit,
    RenameFailureModal,
    isLoading,
  };
};
