import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { Button, ErrorBox } from 'common/components';
import { TextField } from 'common/components/ReactHookForm';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { RenameThemesFormValues, renameThemesFormSchema } from './schema';

export const RenameThemesForm: FC<{
  themes: TextAnswerTheme[];
  onClose(): void;
  onSubmit(values: RenameThemesFormValues): void;
  isLoading: boolean;
}> = ({ themes, onClose, onSubmit, isLoading }) => {
  const methods = useForm<RenameThemesFormValues>({
    defaultValues: {
      themes,
    },
    mode: 'onChange',
    resolver: yupResolver(renameThemesFormSchema),
  });
  const isUpdateDisabled = !(methods.formState.isDirty && methods.formState.isValid) || isLoading;
  const error = methods.formState.isValid ? undefined : 'Please fill out the required fields in order to proceed';

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit(values);
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      methods.trigger();
    }
  }, [methods, methods.formState.isDirty]);

  return (
    <Stack gap={3}>
      <ErrorBox error={error} />
      <FormProvider {...methods}>
        <Stack gap={2}>
          {themes.map(({ themeId }, index) => (
            <TextField name={`themes.${index}.theme`} label="Theme name" key={themeId} />
          ))}
        </Stack>
      </FormProvider>
      <Stack direction="row" gap={1} justifyContent="end">
        <Button variant="text" onClick={onClose} disabled={isLoading}>
          Close
        </Button>
        <Button onClick={handleSubmit} disabled={isUpdateDisabled}>
          Update
        </Button>
      </Stack>
    </Stack>
  );
};
