import React, { ComponentProps, forwardRef } from 'react';

import MoreHoriz from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';
import { ButtonMenu } from 'common/components/Buttons';

type Props = ComponentProps<typeof ButtonMenu>;

export const TableCellMoreActions = forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <ButtonMenu {...props} ref={ref}>
    <IconButton color="inherit" aria-label="More actions">
      <MoreHoriz />
    </IconButton>
  </ButtonMenu>
));
