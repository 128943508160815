import React, { FC, MouseEvent, MouseEventHandler } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import { Typography } from '@mui/material';
import { textEllipsis } from 'common/theme/typography';

import {
  FilterBaseArrowIcon,
  FilterBaseButtonContent,
  FilterBaseButtonWrapper,
  FilterBaseClearButton,
} from './FilterBase.styled';

type Props = {
  isOpen: boolean;
  buttonLabel: string;
  isValueSelected: boolean;
  fullWidth?: boolean;
  onOpen(event: MouseEvent<HTMLButtonElement>): void;
  onReset(): void;
};

export const FilterBaseButton: FC<Props> = ({
  isOpen,
  buttonLabel,
  isValueSelected,
  fullWidth = false,
  onReset,
  onOpen,
}) => {
  const preventPropagation = (e: { stopPropagation: Function }) => e.stopPropagation();

  const handleClearIconClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    preventPropagation(e);
    onReset();
  };

  return (
    <>
      <FilterBaseButtonWrapper onClick={onOpen} isValueSelected={isValueSelected} isOpen={isOpen} fullWidth={fullWidth}>
        <FilterBaseButtonContent>
          <Typography sx={{ ...textEllipsis }} title={buttonLabel} variant="natter-text-sm">
            {buttonLabel}
          </Typography>
          {isValueSelected ? (
            <FilterBaseClearButton
              onClick={handleClearIconClick}
              onMouseDown={preventPropagation}
              data-testid="FilterBaseButton-ClearButton"
            >
              <ClearIcon fontSize="small" color="inherit" />
            </FilterBaseClearButton>
          ) : (
            <FilterBaseArrowIcon isOpen={isOpen} />
          )}
        </FilterBaseButtonContent>
      </FilterBaseButtonWrapper>
    </>
  );
};
