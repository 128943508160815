import React, { ChangeEventHandler, FC, MouseEventHandler, useCallback, useEffect, useRef } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { faFileUpload, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { useDropContainer, useImageCompression } from 'common/hooks';
import { useAppDispatch } from 'store';
import { addAppErrorMessage } from 'store/features/alerts';

import { Button } from '../../Button';
import { ButtonIcon } from '../../ButtonIcon';
import { InputWrapper, InputWrapperProps } from '../InputWrapper';
import { FileInput, InputContainer, SelectedImage, SelectedImageName } from './ImageInput.styled';

export type ImageFile = {
  data: string;
  name: string;
  url?: string;
  type?: string;
  isLoading?: boolean;
};

type FormValue = Record<string, ImageFile>;

interface ImageInputProps extends InputWrapperProps {
  name: string;
  hint?: string;
  label?: string;
  buttonText?: string;
  placeholder?: string;
  accept?: string;
  recommendedFormat?: string;
  onDelete?: MouseEventHandler<HTMLButtonElement>;
}

const ALLOWED_EXTENSIONS = '.webp,.jpeg,.apng,.avif,.gif,.png,.svg,.jpg,.jfif,.pjpeg,.pjp';

export const ImageInput: FC<ImageInputProps> = ({
  name,
  hint,
  label,
  recommendedFormat,
  onDelete,
  buttonText = 'Select file',
  placeholder = 'Select or drag and drop file here',
  accept = ALLOWED_EXTENSIONS,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<FormValue>();
  const value = useWatch<FormValue>({ name });
  const filedError = errors[name];
  const isImageLoaded = !!value?.data;
  const drop = useRef<HTMLDivElement>(null);

  const handleImageLoaded = useCallback(
    (image) => {
      setValue(name, { ...getValues()[name], ...image }, { shouldValidate: true });
    },
    [getValues, name, setValue]
  );

  const handleImageError = useCallback(
    (error: unknown) => {
      if (error instanceof Error) {
        dispatch(addAppErrorMessage(error.message));
      }
    },
    [dispatch]
  );

  const { compressImage, progress } = useImageCompression({
    onImageLoaded: handleImageLoaded,
    onError: handleImageError,
  });

  const { dragging } = useDropContainer({
    container: drop,
    onDrop: (e) => {
      if (!e.dataTransfer) {
        return;
      }
      const { files } = e.dataTransfer;
      if (files?.length) {
        compressImage(files[0]);
      }
    },
  });

  useEffect(() => {
    register(name);
  }, [name, register]);

  useEffect(() => {
    setValue(`${name}.isLoading`, progress > 0 && progress < 100, { shouldValidate: true });
  }, [name, progress, setValue]);

  const handleDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onDelete) {
      onDelete(e);
      return;
    }
    setValue(name, { data: '', name: '', url: '' });
  };

  const handleImageSelected: ChangeEventHandler<HTMLInputElement> = (event) => {
    const file = event?.target?.files?.[0];

    if (file) {
      compressImage(file);
    }
  };

  return (
    <InputWrapper label={label} hint={hint} name={`${name}.data`} {...props} autoWidth>
      <InputContainer
        dragging={dragging}
        selected={isImageLoaded}
        withError={!!filedError}
        justifyContent={isImageLoaded ? 'flex-start' : 'space-between'}
        direction={isImageLoaded ? 'row' : 'column'}
        data-testid="ImageInput-InputContainer"
        ref={drop}
      >
        {isImageLoaded ? (
          <>
            <SelectedImage alt={value?.name} src={value?.data} data-testid={`image-input-${name}-image`} />
            <SelectedImageName>{value?.name}</SelectedImageName>
            <ButtonIcon
              color="default"
              size="medium"
              icon={faTrashAlt}
              onClick={handleDelete}
              data-testid={`image-input-${name}-delete`}
            />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faFileUpload} size="2x" color="primary" />
            <Stack alignItems="center" gap={0.5}>
              <Typography variant="body1">{placeholder}</Typography>
              {recommendedFormat ? (
                <Typography variant="bodySmall" color="text.secondary">
                  {`Recommended format: ${recommendedFormat}`}
                </Typography>
              ) : null}
            </Stack>
            {progress ? (
              <Stack alignItems="center" width="100%" gap={1}>
                <Typography>Compressing...</Typography>
                <Box width="100%">
                  <LinearProgress value={progress} variant="determinate" />
                </Box>
              </Stack>
            ) : (
              <Button variant="contained-light" size="small">
                {buttonText}
              </Button>
            )}
          </>
        )}
        <FileInput
          data-testid="image-input"
          aria-label="Image upload"
          type="file"
          name={name}
          accept={accept}
          sx={{ visibility: isImageLoaded ? 'hidden' : 'visible' }}
          onChange={handleImageSelected}
          onClick={(event) => {
            // to allow selecting the same file multiple times
            // @ts-expect-error Property 'value' does not exist on type 'EventTarget'
            event.target.value = null;
          }}
        />
      </InputContainer>
    </InputWrapper>
  );
};
