import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';

export const useHover = (): [MutableRefObject<HTMLDivElement | null>, boolean, null | HTMLElement] => {
  const [value, setValue] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const ref = useRef<HTMLDivElement | null>(null);
  const handleMouseOver = useCallback((event) => {
    setAnchorEl(event.currentTarget);
    setValue(true);
  }, []);
  const handleMouseOut = useCallback(() => setValue(false), []);

  useEffect(() => {
    const node = ref.current;
    if (!node) {
      return;
    }
    node.addEventListener('mouseenter', handleMouseOver);
    node.addEventListener('mouseleave', handleMouseOut);
    return () => {
      node.removeEventListener('mouseenter', handleMouseOver);
      node.removeEventListener('mouseleave', handleMouseOut);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, handleMouseOut, handleMouseOver]);

  return [ref, value, anchorEl];
};
