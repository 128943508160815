import React, { FC } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Chip, SxProps, Theme, styled } from '@mui/material';

const StyledChip = styled(Chip)(({ theme }) => ({
  height: 32,
  padding: 0,
  cursor: 'grab',
  fontSize: '12px',
  backgroundColor: theme.palette.common.white,
}));

const ChipTagDragIcon = styled(DragIndicatorIcon)({
  height: 16,
  width: 16,
  opacity: 0.5,
});

export const TagChip: FC<{
  label: string;
  onDelete(): void;
  sx?: SxProps<Theme> | undefined;
  ['data-testid']?: string;
}> = ({ label, onDelete, sx, ...props }) => (
  <StyledChip
    tabIndex={-1}
    label={label}
    icon={<ChipTagDragIcon />}
    variant="outlined"
    onDelete={onDelete}
    sx={sx}
    data-testid={props['data-testid']}
  />
);

export const TagChipPreview: FC<{
  item: { label: string };
}> = ({ item }) => (
  <TagChip
    label={item.label}
    onDelete={() => {
      // do nothing
    }}
    sx={({ shadows }) => ({
      boxShadow: shadows[6],
    })}
  />
);
