import React, { ComponentProps, FC } from 'react';

import { assert } from 'common/utils';
import { SurveyStepper } from 'modules/question/components/SurveyStepper';
import { useCreateSurveyAnswersMutation } from 'store/apis/survey';

import { SurveyGuard } from '../components/SurveyGuard';
import { useSurveyData } from '../hooks/useSurveyData';

export const SurveyQuestionnaire: FC = () => {
  const { id, survey, questions, answers, isLoading, isError } = useSurveyData();
  const [submitAnswers, { isSuccess, isLoading: isSubmitting }] = useCreateSurveyAnswersMutation();

  const handleSubmitClick: ComponentProps<typeof SurveyStepper>['onSubmit'] = (_, serializedData) => {
    assert(id);
    submitAnswers({ id, answers: serializedData });
  };

  return (
    <SurveyGuard
      id={id}
      survey={survey}
      answers={answers}
      isLoading={isLoading}
      isError={isError}
      isCompleted={isSuccess || !!answers?.length}
    >
      {survey && questions && answers && (
        <SurveyStepper
          name={survey.name}
          questions={questions}
          answers={answers}
          onSubmit={handleSubmitClick}
          isSubmitting={isSubmitting}
        />
      )}
    </SurveyGuard>
  );
};
