import React, { FC } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { useInsightsReportContext } from 'pages/InsightsReport/hooks';

import { LinkItem, LinkList } from './SideNav.styled';

export const SideNav: FC = () => {
  const { url } = useRouteMatch();
  const { routes } = useInsightsReportContext();

  return (
    <LinkList>
      {routes.map(({ path, title }) => (
        <LinkItem key={path} to={`${url}${path}`}>
          {title}
        </LinkItem>
      ))}
    </LinkList>
  );
};
