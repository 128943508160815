import React, { FC } from 'react';

import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from '@mui/material';

import { Button, type ButtonProps } from '../../_legacy/Button';

interface ButtonWithIconProps extends Omit<ButtonProps, 'variant'> {
  icon: IconProp;
  iconColor?: string;
  // @ts-expect-error no type
  variant?;
  iconSize?: SizeProp;
}

export const ButtonWithIcon: FC<ButtonWithIconProps> = ({
  icon,
  children,
  iconColor,
  iconSize = 'lg',
  variant = 'flat',
  ...props
}) => {
  const { palette } = useTheme();

  if (props.disabled) {
    iconColor = 'inherit';
  }

  return (
    <Button
      variant={variant}
      {...props}
      startIcon={<FontAwesomeIcon icon={icon} size={iconSize} color={iconColor || palette.primary.main} />}
    >
      {children}
    </Button>
  );
};
