import React, { ComponentProps, forwardRef } from 'react';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box } from '@mui/material';

import { DragIconWrapper, DraggableItemWrapper } from './DraggableBox.styled';

type Props = ComponentProps<typeof DraggableItemWrapper>;

export const DraggableBox = forwardRef<HTMLDivElement, Props>(({ children, ...props }, ref) => (
  <DraggableItemWrapper {...props}>
    <Box flex={1}>{children}</Box>
    <DragIconWrapper ref={ref} indicatorPosition={props.indicatorPosition}>
      <DragIndicatorIcon />
    </DragIconWrapper>
  </DraggableItemWrapper>
));
