import React, { FC, Fragment } from 'react';

import { Alert } from '@mui/material';
import { ProgressStepper } from 'common/components/ProgressStepper';
import { FormErrors } from 'common/components/ReactHookForm';
import { useFieldsValidation } from 'common/hooks';
import { QuestionDefinition } from 'domain/question';
import { find, flow, get } from 'lodash/fp';
import { REQUIRED_QUESTION_MESSAGE } from 'modules/question/constants';

import { QuestionAnswer } from '../../QuestionsForm';

type ContentProps = Parameters<typeof ProgressStepper>[0] & {
  questions: QuestionDefinition[];
  onSubmit?(): void;
  isSubmitting?: boolean;
};

export const SurveyContent: FC<ContentProps> = ({
  activeStep,
  onNextClick,
  onSubmit,
  questions,
  isSubmitting,
  ...props
}) => {
  const isLastAnswer = activeStep === questions.length - 1;
  const { triggerFieldValidation, errors } = useFieldsValidation(`answers.${activeStep}`, () => {
    if (activeStep === questions.length - 1) {
      onSubmit?.();
    } else {
      onNextClick?.();
    }
  });

  // TODO Refactor answer validation to have global mandatory error `src/validators/questions.ts`
  // as currently for different question types we have different validation types like 'required', 'optionality' or 'isAtLeastOneSelected'
  const isRequiredError = flow(get(`answers.${activeStep}`), find({ message: REQUIRED_QUESTION_MESSAGE }))(errors);

  return (
    <ProgressStepper
      {...props}
      activeStep={activeStep}
      nextButtonLabel={isLastAnswer ? 'Submit' : 'Next'}
      onNextClick={triggerFieldValidation}
      isLoading={isSubmitting}
    >
      {questions.map((question, index) => (
        <Fragment key={question.question.questionId}>
          {isRequiredError ? (
            <Alert severity="error">Please complete the required question to go next.</Alert>
          ) : (
            <FormErrors name={`answers.${index}`} errors={errors} />
          )}

          <QuestionAnswer name={`answers.${index}`} questionDefinition={question} />
        </Fragment>
      ))}
    </ProgressStepper>
  );
};
