import React, { FC } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { Box, Stack } from '@mui/material';
import { Checkbox } from 'common/components/ReactHookForm';
import { AnimatePresence, motion } from 'framer-motion';
import { TextAnswerTheme } from 'store/apis/insightsReport';

export const MergedThemeItem: FC<{
  theme: TextAnswerTheme;
  index: number;
}> = ({ theme, index }) => {
  const { trigger } = useFormContext();
  const { field } = useController<{ [key: string]: boolean }>({ name: `themes.${index}.isSelected` });
  const isSelected = field.value;

  const handleCheckboxChange = () => {
    field.onBlur();
    trigger('themes');
  };

  return (
    <Stack>
      <Checkbox name={`themes.${index}.isSelected`} label={theme.theme} onChange={handleCheckboxChange} />
      <AnimatePresence>
        {isSelected && (
          <motion.div
            style={{ overflowY: 'hidden' }}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
          >
            <Box component="ul" my={0.5} pl={6} fontSize="12px">
              {theme.mergedThemes.map(({ id, name }) => (
                <li key={`merged-theme-${id}`}>{name}</li>
              ))}
            </Box>
          </motion.div>
        )}
      </AnimatePresence>
    </Stack>
  );
};
