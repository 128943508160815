import React, { FC } from 'react';

import { SurveyInsights } from 'pages/InsightsReport';
import { useCurrentPublicSurveyReportAnswers } from 'pages/PublicSurveyReport/hooks';

export const ResultsAnalysis: FC = () => {
  const { data } = useCurrentPublicSurveyReportAnswers();

  return <SurveyInsights data={data} surveyName="Results Analysis" />;
};
