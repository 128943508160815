import React, { FC, forwardRef } from 'react';
import { Link, LinkProps, useHistory, useLocation } from 'react-router-dom';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ButtonBase } from '@mui/material';

type BackButtonProps =
  | {
      onClick?(): void;
      href?: never;
    }
  | {
      onClick?: never;
      href: string;
    };

const LinkComponent = forwardRef<HTMLAnchorElement, LinkProps>(({ to, ...props }, ref) => (
  <Link innerRef={ref} to={to} {...props} />
));

export const BackButton: FC<BackButtonProps> = ({ onClick, href }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const pathnames = pathname.split('/').filter(Boolean);

  const handleBackButtonClick = () => {
    history.push(`/${pathnames.slice(0, -1).join('/')}`);
  };

  return (
    <ButtonBase
      disableRipple
      sx={({ typography }) => ({ ...typography['natter-text-sm'], fontWeight: 500, gap: 0.25 })}
      {...(href ? { LinkComponent, to: href } : { onClick: onClick ?? handleBackButtonClick })}
    >
      <ChevronLeftIcon sx={{ width: 16 }} />
      Back
    </ButtonBase>
  );
};
