import type { Components, Theme } from '@mui/material';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  defaultProps: {
    component: 'div',
  },
  styleOverrides: {
    root: ({ theme: { palette, spacing } }) => ({
      alignItems: 'center',
      color: palette.grey[500],
      display: 'flex',
      flexDirection: 'row',
      gap: spacing(0.75),
      margin: spacing(1, 0, 0, 0),

      '&.Mui-error.Mui-disabled': {
        color: palette.grey[500],
      },
    }),
  },
};
