import { startCase, toLower } from 'lodash';
import { flow } from 'lodash/fp';

import { OptionItem } from '../Select';

export const enumToOptionItems = (enumObject: Record<string, string | number>): OptionItem[] =>
  Object.entries(enumObject).map(([key, value]) => {
    const label = typeof value === 'string' ? flow(toLower, startCase)(value) : startCase(key);
    return { value, label };
  });
