import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { useConfirmationModal } from 'common/hooks';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { UnmergeThemesFormStep1 } from './UnmergeThemesFormStep1';
import { UnmergeThemesFormValues, unmergeThemesFormSchema } from './schema';

export const UnmergeThemesForm: FC<{
  themes: TextAnswerTheme[];
  onClose(): void;
  onSubmit(values: UnmergeThemesFormValues): void;
  isLoading: boolean;
}> = ({ themes, onClose, onSubmit, isLoading }) => {
  const methods = useForm<UnmergeThemesFormValues>({
    defaultValues: {
      themes,
    },
    resolver: yupResolver(unmergeThemesFormSchema),
    mode: 'onChange',
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit(values);
  });

  const handleUnmerge = () => {
    const themesNames = methods
      .getValues()
      .themes.filter((theme) => theme.isSelected)
      .map(({ theme }) => `"${theme}"`)
      .join(', ');
    openUnmergeConfirmationDialog({ themesNames });
  };

  const [openUnmergeConfirmationDialog, ConfirmUnmergeDialog] = useConfirmationModal<{ themesNames: string }>(
    (data) => ({
      severity: 'danger',
      maxWidth: 'xs',
      cancelLabel: 'No, go back',
      confirmLabel: 'Yes, unmerge',
      title: 'Unmerge Themes',
      content: `Are you sure you want to unmerge the Theme ${data?.themesNames}?`,
    }),
    () => handleSubmit()
  );

  return (
    <>
      <FormProvider {...methods}>
        <UnmergeThemesFormStep1 themes={themes} onClose={onClose} onSubmit={handleUnmerge} isLoading={isLoading} />
      </FormProvider>
      {ConfirmUnmergeDialog}
    </>
  );
};
