import React, { FC, useCallback } from 'react';

import { faBars, faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, IconButtonProps } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store';
import {
  appDrawerHide,
  appDrawerShow,
  appToggleMobileMenu,
  selectIsDrawerDisplayed,
  selectMobileMenuOpen,
} from 'store/features/app';

interface MenuButtonProps extends IconButtonProps {
  open: boolean;
}

const MenuButton: FC<MenuButtonProps> = ({ open, onClick, ...props }) => (
  <Box ml="auto">
    <IconButton
      sx={{ width: 32, height: 32 }}
      color="inherit"
      aria-label="Main menu"
      onClick={onClick}
      data-testid="menu-button"
      disableRipple
      {...props}
    >
      <FontAwesomeIcon icon={open ? faClose : faBars} />
    </IconButton>
  </Box>
);

export const MobileMenuButton: FC = () => {
  const isMobileMenuOpen = useAppSelector(selectMobileMenuOpen);
  const dispatch = useAppDispatch();

  const handleNavigationToggle = useCallback(() => {
    dispatch(appToggleMobileMenu());
    if (isMobileMenuOpen) {
      dispatch(appDrawerHide());
    } else {
      dispatch(appDrawerShow());
    }
  }, [dispatch, isMobileMenuOpen]);

  return <MenuButton open={isMobileMenuOpen} onClick={handleNavigationToggle} />;
};

export const TabletMenuButton: FC = () => {
  const isDrawerOpen = useAppSelector(selectIsDrawerDisplayed);
  const dispatch = useAppDispatch();

  const handleNavigationToggle = useCallback(() => {
    if (isDrawerOpen) {
      dispatch(appDrawerHide());
    } else {
      dispatch(appDrawerShow());
    }
  }, [dispatch, isDrawerOpen]);

  return <MenuButton open={isDrawerOpen} onClick={handleNavigationToggle} />;
};
