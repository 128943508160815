import React, { FC } from 'react';

import { faTags } from '@fortawesome/pro-light-svg-icons';
import { Tooltip, TooltipProps } from '@mui/material';

import { ButtonIcon } from '../_legacy/ButtonIcon';
import { Tags } from './Tags';
import { useTagsStyles } from './Tags.styles';

export const TagsTooltip: FC<{
  tags: string[] | undefined;
  disabled?: boolean;
  placement?: TooltipProps['placement'];
}> = ({ disabled, tags, placement = 'bottom-end', children }) => {
  const classes = useTagsStyles();

  if (!tags) {
    return <>{children}</>;
  }

  return (
    <Tooltip classes={{ tooltip: classes.tags }} title={<Tags tags={tags} />} placement={placement}>
      <span>
        {children || (
          <ButtonIcon icon={faTags} disabled={disabled} aria-label="Show tags" className={classes.tagsButton} />
        )}
      </span>
    </Tooltip>
  );
};
