import { createAction } from '@reduxjs/toolkit';
import { RoomWSActions, RoomWSMessages } from 'common/constants';
import { EventRoom, EventRoomApiPayload, EventRoomAttendee, EventRoomAttendeeApiPayload } from 'domain/event';
import { createMeetingWSAsyncAction, createMeetingWSMessage } from 'store/utils';

import { MeetingWSRoomParams, NatteringStartedPayload, RoomSupportJoinedPayload } from '../types';

export const roomExit_WS = createMeetingWSMessage(RoomWSMessages.RoomExit);
export const joinRoom_WS = createMeetingWSMessage<EventRoomApiPayload>(RoomWSMessages.RoomJoin);
export const addNewRoom_WS = createMeetingWSMessage<EventRoomApiPayload>(RoomWSMessages.RoomOpened);
export const guestJoinedRoom_WS = createMeetingWSMessage<EventRoomAttendeeApiPayload>(RoomWSMessages.GuestJoinedRoom);
export const roomsCountUpdated_WS = createMeetingWSMessage<{ roomsCount: number }>(RoomWSMessages.RoomsCountUpdated);
export const roomSupportRequested_WS = createMeetingWSMessage<{ room: EventRoomApiPayload }>(
  RoomWSMessages.RoomSupportRequested
);
export const roomSupportRequestedList_WS = createMeetingWSMessage<{ rooms: EventRoomApiPayload[] }>(
  RoomWSMessages.RoomSupportRequestedList
);
export const roomSupportCancelled_WS = createMeetingWSMessage<{ roomId: string }>(RoomWSMessages.RoomSupportCancelled);
export const roomSupportJoined_WS = createMeetingWSMessage<RoomSupportJoinedPayload>(RoomWSMessages.RoomSupportJoined);
export const roomSupportLeft_WS = createMeetingWSMessage<RoomSupportJoinedPayload>(RoomWSMessages.RoomSupportLeft);
export const updateRoomAttendee = createMeetingWSMessage<{ roomId: string; attendee: EventRoomAttendee }>(
  RoomWSMessages.UpdateRoomAttendee
);
export const guestDisconnected_WS = createMeetingWSMessage<{ roomId: string; userId: number }>(
  RoomWSMessages.GuestDisconnected
);
export const userDisconnectedList_WS = createMeetingWSMessage<{ userId: number }[]>(
  RoomWSMessages.UserDisconnectedList
);
export const meetingRoomClosed_WS = createMeetingWSMessage<{ id: string }>(RoomWSMessages.RoomClosed);
export const natteringStarted_WS = createMeetingWSMessage<NatteringStartedPayload>(RoomWSMessages.NatteringStarted);
export const meetingBroadcastCountdownStarted_WS = createMeetingWSMessage(RoomWSMessages.BroadcastCountdown);
export const transcriptionStarted_WS = createMeetingWSMessage(RoomWSMessages.TranscriptionStarted);
export const transcriptionStopped_WS = createMeetingWSMessage(RoomWSMessages.TranscriptionStopped);

export const requestNatterSupport = createMeetingWSAsyncAction<MeetingWSRoomParams>(RoomWSActions.RequestSupport);
export const cancelNatterSupport = createMeetingWSAsyncAction<MeetingWSRoomParams>(RoomWSActions.CancelSupport);
export const leaveRoomAsSupport = createMeetingWSAsyncAction<MeetingWSRoomParams>(RoomWSActions.LeaveAsSupport);
export const joinRoomAsSupport = createMeetingWSAsyncAction<MeetingWSRoomParams<{ room: EventRoom }>>(
  RoomWSActions.JoinAsSupport
);

// [NAT-4608] Create simple Redux action instead of MeetingWS to not send action `room_connection_error` to WS
export const meetingRoomConnectionError = createAction<MeetingWSRoomParams>(RoomWSActions.RoomConnectionError);
export const submitCallFeedback = createMeetingWSAsyncAction<MeetingWSRoomParams>(RoomWSActions.RoomFeedback);
export const startTranscription = createMeetingWSAsyncAction<MeetingWSRoomParams>(RoomWSActions.StartTranscription);
export const stopTranscription = createMeetingWSAsyncAction<MeetingWSRoomParams>(RoomWSActions.StopTranscription);
