import { type Components, type Theme } from '@mui/material';

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme: { palette, typography } }) => ({
      borderRadius: '8px',
      backgroundColor: palette.grey[900],
      color: palette.grey[200],

      ...typography['natter-text-sm'],

      marginX: 0,
      maxWidth: 300,

      '& .MuiTypography-root': {
        color: palette.grey[200],
      },
    }),
    tooltipPlacementBottom: {
      marginTop: '5px !important',
    },
    tooltipArrow: ({ theme: { palette } }) => ({
      '& .MuiTooltip-arrow': {
        color: palette.grey[900],
      },
    }),
  },
};
