import React, { FC, useState } from 'react';

import { Skeleton, Typography } from '@mui/material';
import { toPluralForm } from 'common/utils';
import { format } from 'date-fns';
import { useInsightsReportContext } from 'pages/InsightsReport/hooks';
import { InsightsReportAssociatedEventsDrawer } from 'pages/InsightsReports/components/InsightsReportsTable/components/InsightsReportAssociatedEventsDrawer/InsightsReportAssociatedEventsDrawer';

import { SummaryCard } from './Summary.styled';

export const Summary: FC = () => {
  const { isLoading, reportData, reportType } = useInsightsReportContext();
  const [isAssociatedEventsOpen, setIsAssociatedEventsOpen] = useState(false);

  return (
    <SummaryCard>
      <Typography component="div" variant="inherit">
        <Typography component="span" variant="inherit">
          Created on:{' '}
        </Typography>
        {isLoading ? (
          <Skeleton width={100} sx={{ display: 'inline-block' }} />
        ) : (
          <Typography component="span" variant="inherit" color="text.primary">
            {reportData && format(new Date(reportData.createdAt), 'MMM dd, yyyy')}
          </Typography>
        )}
      </Typography>

      <Typography
        variant="inherit"
        color="primary.main"
        sx={{ cursor: 'pointer' }}
        onClick={() => setIsAssociatedEventsOpen(true)}
      >
        Associated {toPluralForm(reportType)} (
        {isLoading ? <Skeleton width={16} sx={{ display: 'inline-block' }} /> : reportData?.associatedItems.length})
      </Typography>

      <InsightsReportAssociatedEventsDrawer
        data={isAssociatedEventsOpen ? reportData : undefined}
        reportType={reportType}
        onClose={() => setIsAssociatedEventsOpen(false)}
      />
    </SummaryCard>
  );
};
