import React, { FC } from 'react';

import { Box } from '@mui/material';
import { PageInProgress } from 'common/components';

export const ActionableInsights: FC = () => (
  <Box height={600}>
    <PageInProgress />
  </Box>
);
