import React, { FC, useMemo } from 'react';

import {
  AnswerStatistics,
  isMultipleChoiceSingleSelectAnswerStatistics,
  isTextAnswerStatistics,
} from 'store/apis/insightsReport';

import { ChartCard, SingleChoiceQuestionChartCard, ThemesChartCard } from '../ChartCard';
import { ReportCard } from '../layout';
import { mapAnswerStatisticsToChartCardProps } from './chartsMapper';

export const AnswerStatisticsCard: FC<{
  answerStatistics: AnswerStatistics;
}> = ({ answerStatistics }) => {
  const { title } = answerStatistics;
  const {
    availableChartTypes,
    chartProps,
    title: chartTitle,
    chartId,
  } = useMemo(() => mapAnswerStatisticsToChartCardProps(answerStatistics), [answerStatistics]);

  const MatchingChartCard = useMemo(() => {
    if (isTextAnswerStatistics(answerStatistics)) {
      return (
        <ThemesChartCard
          title={chartTitle}
          chartProps={chartProps}
          themes={answerStatistics.themes}
          chartId={chartId}
        />
      );
    }
    if (isMultipleChoiceSingleSelectAnswerStatistics(answerStatistics)) {
      return <SingleChoiceQuestionChartCard title={chartTitle} chartProps={chartProps} chartId={chartId} />;
    }
    return (
      <ChartCard
        title={chartTitle}
        chartProps={chartProps}
        availableChartTypes={availableChartTypes}
        chartId={chartId}
      />
    );
  }, [answerStatistics, chartTitle, chartProps, availableChartTypes, chartId]);

  return (
    <ReportCard title={title} id={chartId}>
      {MatchingChartCard}
    </ReportCard>
  );
};
