import { useCallback } from 'react';

import { assert } from 'common/utils';
import type { Nullable } from 'domain/Common';
import { CreateAnswerVariants, QuestionAssignmentKey, UpdateAnswerVariants } from 'domain/question';
import { useAppDispatch } from 'store';
import {
  type UpdateAnswerRequest,
  anonymiseAnswer_WS,
  assignToUserIds_WS,
  createAnswer_WS,
  deleteAnswer_WS,
  subscribeToQuestions,
  unsubscribeFromQuestions,
  updateAnswer_WS,
} from 'store/features/questions';

export const useQuestionWS = (questionAssignmentKey?: Nullable<QuestionAssignmentKey>) => {
  const dispatch = useAppDispatch();

  const subscribe = useCallback(() => {
    assert(questionAssignmentKey);
    dispatch(subscribeToQuestions(questionAssignmentKey));
  }, [dispatch, questionAssignmentKey]);

  const unsubscribe = useCallback(() => {
    assert(questionAssignmentKey);
    dispatch(unsubscribeFromQuestions(questionAssignmentKey));
  }, [dispatch, questionAssignmentKey]);

  const createAnswer = useCallback(
    (answerId: string, value: string, type = CreateAnswerVariants.TextQuestion, isObligatory = false) => {
      assert(questionAssignmentKey);
      dispatch(createAnswer_WS({ ...questionAssignmentKey, answerId, type, text: value, isObligatory }));
    },
    [dispatch, questionAssignmentKey]
  );

  const assignToUserIds = useCallback(
    (answerId: string, userIds: number[] = []) => {
      assert(questionAssignmentKey);
      dispatch(assignToUserIds_WS({ ...questionAssignmentKey, answerId, userIds }));
    },
    [dispatch, questionAssignmentKey]
  );

  const anonymiseAnswer = useCallback(
    (answerId: string) => {
      assert(questionAssignmentKey);
      dispatch(anonymiseAnswer_WS({ ...questionAssignmentKey, answerId }));
    },
    [dispatch, questionAssignmentKey]
  );

  const updateAnswer = useCallback(
    (answerId: string, value: string, type = UpdateAnswerVariants.TextQuestion) => {
      assert(questionAssignmentKey);
      dispatch(updateAnswer_WS({ ...questionAssignmentKey, answerId, text: value, type } as UpdateAnswerRequest));
    },
    [dispatch, questionAssignmentKey]
  );

  const deleteAnswer = useCallback(
    (answerId: string) => {
      assert(questionAssignmentKey);
      dispatch(deleteAnswer_WS({ ...questionAssignmentKey, answerId }));
    },
    [dispatch, questionAssignmentKey]
  );

  return {
    subscribe,
    unsubscribe,
    createAnswer,
    assignToUserIds,
    anonymiseAnswer,
    updateAnswer,
    deleteAnswer,
  };
};
