import React, { FC } from 'react';

import Repeat from '@mui/icons-material/ReplayOutlined';
import { Button, IconButtonWithTooltip } from 'common/components';

export type EventRepeatButtonProps = {
  onRepeat(): void;
  variant: 'icon' | 'text';
};

export const EventRepeatButton: FC<EventRepeatButtonProps> = ({ variant, onRepeat }) => {
  if (variant === 'icon') {
    return (
      <IconButtonWithTooltip
        title="Repeat Event"
        size="medium"
        color="secondary"
        onClick={onRepeat}
        aria-label="Repeat Event"
      >
        <Repeat fontSize="small" />
      </IconButtonWithTooltip>
    );
  }

  return (
    <Button fullWidth onClick={onRepeat} size="large" aria-label="Repeat Event">
      Repeat Event
    </Button>
  );
};
