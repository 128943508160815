import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { Box, type SxProps, type Theme } from '@mui/material';

import { ButtonWithIcon } from '../Buttons';

type NavigateBackProps = {
  label?: string;
  sx?: SxProps<Theme>;
  onClick?(): void;
};

// TODO Replace with hierarchical routes and ".."" notation
export const NavigateBack: FC<NavigateBackProps> = ({ label = 'Back', sx, onClick, ...props }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const pathnames = pathname.split('/').filter(Boolean);

  const handleBackButtonClick = () => {
    history.push(`/${pathnames.slice(0, -1).join('/')}`);
  };

  if (pathnames.length <= 1) return null;

  return (
    <Box sx={sx} {...props}>
      <ButtonWithIcon
        type="button"
        icon={faChevronLeft}
        iconColor="inherit"
        iconSize="1x"
        size="small"
        onClick={onClick || handleBackButtonClick}
        sx={{ marginLeft: -1, padding: '0 12px !important' }}
        data-testid="navigate-back-button"
      >
        {label}
      </ButtonWithIcon>
    </Box>
  );
};
