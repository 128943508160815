import { Stack, styled } from '@mui/material';
import { IconButton } from 'common/components';

export const HideThemeItemWrapper = styled(Stack)<{ disabled: boolean }>(({ theme, disabled }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: disabled ? undefined : 'pointer',
  paddingLeft: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.grey[50],
    borderRadius: '4px',
  },
}));

export const StyledIconButton = styled(IconButton)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
  color: isSelected ? undefined : theme.palette.grey[500],
}));
