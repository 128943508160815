import React, { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/material';
import { TextAnswerTheme } from 'store/apis/insightsReport';

import { HideThemesFormContent } from './HideThemesFormContent';
import { HideThemesFormValues, hideThemesFormSchema } from './schema';

export const HideThemesForm: FC<{
  themes: TextAnswerTheme[];
  onClose(): void;
  onSubmit(values: HideThemesFormValues): void;
  isLoading: boolean;
}> = ({ themes, onClose, onSubmit, isLoading }) => {
  const methods = useForm<HideThemesFormValues>({
    defaultValues: {
      themes,
    },
    mode: 'onChange',
    resolver: yupResolver(hideThemesFormSchema),
  });

  const handleSubmit = methods.handleSubmit((values) => {
    onSubmit(values);
  });

  useEffect(() => {
    if (methods.formState.isDirty) {
      methods.trigger();
    }
  }, [methods, methods.formState.isDirty]);

  return (
    <Stack gap={3}>
      <FormProvider {...methods}>
        <HideThemesFormContent isLoading={isLoading} onApply={handleSubmit} onClose={onClose} themes={themes} />
      </FormProvider>
    </Stack>
  );
};
