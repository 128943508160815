import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface AppState {
  displayDrawer: boolean;
  darkTheme: boolean;
  displayHeader: boolean;
  simpleHeader: boolean;
  displayMainPadding: boolean;
  scrollToTop: boolean;
  themeColor: string | undefined;
  mobileMenuOpen: boolean;
  mobileBroadcastDrawerOpen: boolean;
}

const initialState: AppState = {
  displayDrawer: true,
  simpleHeader: false,
  displayHeader: true,
  displayMainPadding: true,
  darkTheme: false,
  scrollToTop: false,
  themeColor: undefined,
  mobileMenuOpen: false,
  mobileBroadcastDrawerOpen: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    appScrollToTop: (state) => {
      state.scrollToTop = true;
    },
    appScrollToTopDone: (state) => {
      state.scrollToTop = false;
    },
    appSetThemeDark: (state) => {
      state.darkTheme = true;
    },
    appSetThemeLight: (state) => {
      state.darkTheme = false;
    },
    appDrawerHide: (state) => {
      state.displayDrawer = false;
    },
    appDrawerShow: (state) => {
      state.displayDrawer = true;
    },
    appHeaderSimpleHide: (state) => {
      state.simpleHeader = false;
    },
    appHeaderSimpleShow: (state) => {
      state.simpleHeader = true;
    },
    appHeaderHide: (state) => {
      state.displayHeader = false;
    },
    appHeaderShow: (state) => {
      state.displayHeader = true;
    },
    appMainPaddingHide: (state) => {
      state.displayMainPadding = false;
    },
    appMainPaddingShow: (state) => {
      state.displayMainPadding = true;
    },
    appSetThemeColor: (state, { payload }: PayloadAction<string | undefined>) => {
      state.themeColor = payload;
    },
    appToggleMobileMenu: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.mobileMenuOpen = payload ?? !state.mobileMenuOpen;
    },
    appToggleMobileBroadcastDrawer: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.mobileBroadcastDrawerOpen = payload ?? !state.mobileBroadcastDrawerOpen;
    },
  },
});

export const {
  appScrollToTop,
  appScrollToTopDone,
  appSetThemeDark,
  appSetThemeLight,
  appDrawerHide,
  appDrawerShow,
  appHeaderSimpleHide,
  appHeaderSimpleShow,
  appHeaderHide,
  appHeaderShow,
  appMainPaddingHide,
  appMainPaddingShow,
  appSetThemeColor,
  appToggleMobileMenu,
  appToggleMobileBroadcastDrawer,
} = appSlice.actions;
