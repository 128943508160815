import { useEffect, useState } from 'react';

import { useConfirmationModal } from 'common/hooks';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import { RenameThemesPayload, TextAnswerTheme, useRenameThemesMutation } from 'store/apis/insightsReport';

import { RenameThemesFormValues, ThemeFormField } from './schema';

export const useRenameThemes = ({
  onClose,
  onOpen,
  themes,
}: {
  onClose(): void;
  onOpen(): void;
  themes: TextAnswerTheme[];
}) => {
  const { reportId } = useCurrentReportId();
  const [mergeThemes, { isLoading, isSuccess, isError, reset }] = useRenameThemesMutation();
  const [updatedThemes, setUpdatedThemes] = useState<ThemeFormField[]>([]);
  const [openRenameFailureModal, RenameFailureModal] = useConfirmationModal(
    {
      cancelLabel: 'Back to Report',
      confirmLabel: 'Try again',
      title: 'Renaming unsuccessful',
      description: `The Theme you have selected couldn’t be Renamed. Try again or contact support@natter.co if the issue persists.`,
      severity: 'danger',
      variant: 'confirmation',
      maxWidth: 'xs',
    },
    () => {
      onOpen();
    }
  );

  const handleSubmit = (values: RenameThemesFormValues) => {
    const newUpdatedThemes = values.themes.filter((theme) => {
      const initialTheme = themes.find(({ themeId }) => theme.themeId === themeId);
      return initialTheme && initialTheme.theme !== theme.theme;
    });

    setUpdatedThemes(newUpdatedThemes);
  };

  const handleClose = () => {
    if (isLoading) return;

    onClose();
  };

  useEffect(() => {
    if (updatedThemes.length === 0) return;

    mergeThemes({
      reportId,
      themeIdToNewName: updatedThemes.reduce(
        (themeIdToNewName, theme) => {
          themeIdToNewName[theme.themeId] = theme.theme;
          return themeIdToNewName;
        },
        {} as RenameThemesPayload['themeIdToNewName']
      ),
    });
  }, [reportId, updatedThemes, mergeThemes]);

  useEffect(() => {
    if (!isSuccess) return;

    onClose();
    reset();
  }, [isSuccess, onClose, reset]);

  useEffect(() => {
    if (!isError) return;

    onClose();
    openRenameFailureModal();
    reset();
  }, [isError, onClose, openRenameFailureModal, reset]);

  return {
    handleClose,
    handleSubmit,
    RenameFailureModal,
    isLoading,
  };
};
