import React, { FC } from 'react';
import { isMobile } from 'react-device-detect';

import { Button, Container, Stack, Typography } from '@mui/material';
import { NavigationBar } from 'common/layout';

import { ErrorIcon, SuccessIcon } from '../icons';

type Props = {
  variant: 'success' | 'error';
  name?: string;
  title: string;
  description: string;
};

export const SurveyStatePage: FC<Props> = ({ variant, name, title, description }) => (
  <>
    <NavigationBar title={name} />
    <Container maxWidth="hug" sx={{ paddingTop: '10vh' }} disableGutters={!isMobile}>
      <Stack gap={4} py={6} px={2} alignItems="center">
        {variant === 'success' && <SuccessIcon sx={{ width: 160, height: 160 }} />}
        {variant === 'error' && <ErrorIcon sx={{ width: 160, height: 160 }} />}

        <Typography variant="natter-display-sm" textAlign="center" fontWeight={500}>
          {title}
        </Typography>
        <Typography variant="natter-text-sm" color="text.secondary" textAlign="center">
          {description}
        </Typography>

        <Button href="/" size="medium" sx={{ minWidth: 289 }}>
          Back to platform
        </Button>
      </Stack>
    </Container>
  </>
);
