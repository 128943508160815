import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { generateDynamicPath } from 'common/utils';
import { AppRoutes } from 'router';

import { useActiveEventParams } from './useActiveEventParams';

type EventParams =
  | {
      /** @new */
      eventId: number | undefined;
    }
  | {
      /** @deprecated use eventId instead */
      eventHash: string;
    };

export const useEventRoutes = (eventId?: number) => {
  const { id: activeEventId } = useActiveEventParams();
  const id = eventId ?? activeEventId;

  const getEventParams = useCallback(
    (args?: EventParams) => {
      if (!args) {
        return { id };
      }
      if ('eventHash' in args) {
        return {
          hash: args.eventHash,
          id,
        };
      }
      return {
        id: args.eventId,
      };
    },
    [id]
  );
  const eventDetailsUrl = generateDynamicPath(AppRoutes.EventDetails, getEventParams());
  const history = useHistory();

  const goToEventRegister = useCallback(
    (args: EventParams) => {
      history.push(generateDynamicPath(AppRoutes.EventDetails, getEventParams(args), { register: true }));
    },
    [getEventParams, history]
  );

  const goToRepeatEvent = useCallback(
    (args?: EventParams) => {
      history.push(generateDynamicPath(AppRoutes.EventWizard, getEventParams(args), { repeat: true }));
    },
    [getEventParams, history]
  );

  const goToMyEvents = useCallback(() => {
    history.push(AppRoutes.MyEvents);
  }, [history]);

  const goToEditEvent = useCallback(
    (args?: EventParams) => {
      history.push(generateDynamicPath(AppRoutes.EventWizard, getEventParams(args)));
    },
    [getEventParams, history]
  );

  const redirectToEventDetails = useCallback(() => {
    history.replace(eventDetailsUrl);
  }, [eventDetailsUrl, history]);

  const redirectToUpcomingEvents = useCallback(
    (args: { eventId?: number }) => {
      history.replace(AppRoutes.MyEventsUpcoming, { newEventId: args.eventId });
    },
    [history]
  );

  const goToJoinEvent = useCallback(
    (args?: EventParams) => {
      history.push(generateDynamicPath(AppRoutes.JoinEvent, getEventParams(args)));
    },
    [getEventParams, history]
  );

  const goToPublicEvent = useCallback(() => {
    history.push(generateDynamicPath(AppRoutes.EventPublic, getEventParams()));
  }, [getEventParams, history]);

  const goToEventDetails = useCallback(
    (args?: EventParams) => {
      history.push(generateDynamicPath(AppRoutes.EventDetails, getEventParams(args)));
    },
    [getEventParams, history]
  );

  const goToEventBackstage = useCallback(() => {
    history.push(generateDynamicPath(AppRoutes.EventBackstage, getEventParams()), { allow: true });
  }, [getEventParams, history]);

  const goToEventRoom = useCallback(() => {
    history.push(generateDynamicPath(AppRoutes.EventRoom, getEventParams()), { allow: true });
  }, [getEventParams, history]);

  const goToEventEnd = useCallback(() => {
    history.push(generateDynamicPath(AppRoutes.EventEnd, getEventParams()));
  }, [getEventParams, history]);

  const redirectToBroadcast = useCallback(() => {
    history.replace(generateDynamicPath(AppRoutes.EventBroadcast, getEventParams()));
  }, [getEventParams, history]);

  const redirectToBackstage = useCallback(() => {
    history.replace(generateDynamicPath(AppRoutes.EventBackstage, getEventParams()));
  }, [getEventParams, history]);

  const redirectToOneOnOne = useCallback(() => {
    history.replace(generateDynamicPath(AppRoutes.EventVideoCall, getEventParams()));
  }, [getEventParams, history]);

  const redirectToWaitingRoom = useCallback(() => {
    history.replace(generateDynamicPath(AppRoutes.EventWaitingRoom, getEventParams()));
  }, [getEventParams, history]);

  const redirectToCallFeedback = useCallback(
    ({ roomId }: { roomId: string }) => {
      history.replace(generateDynamicPath(AppRoutes.EventCallFeedback, getEventParams()), { roomId });
    },
    [getEventParams, history]
  );

  return {
    eventDetailsUrl,
    goToEditEvent,
    goToEventBackstage,
    goToEventDetails,
    goToEventEnd,
    goToEventRegister,
    goToEventRoom,
    goToJoinEvent,
    goToMyEvents,
    goToPublicEvent,
    goToRepeatEvent,
    redirectToBackstage,
    redirectToBroadcast,
    redirectToCallFeedback,
    redirectToEventDetails,
    redirectToOneOnOne,
    redirectToUpcomingEvents,
    redirectToWaitingRoom,
  };
};
