import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { generateDynamicPath, getItemsCountLabel, isApiResponse } from 'common/utils';
import { push } from 'redux-first-history';
import { AppRoutes } from 'router';
import type { Effect, RootState } from 'store';
import { addAppConfirmationDialog, addAppSuccessMessage } from 'store/features/alerts';
import { appSetThemeColor } from 'store/features/app';
import { selectPathname } from 'store/features/router';
import { handleQueryError } from 'store/utils';

import { communityActions } from './actions';
import { communityApi } from './communityApi';
import { registerToCommunitySuccessEffect } from './effects';

export const communityApiListener = createListenerMiddleware<RootState>();

const handleApiErrors =
  (defaultMessage = 'Unexpected error occurred'): Effect =>
  ({ type, payload }, { dispatch }) => {
    const response = isApiResponse(payload) ? payload : undefined;
    handleQueryError(payload, dispatch, type, response?.data || defaultMessage);
  };

communityApiListener.startListening({
  matcher: communityApi.endpoints.createCommunity.matchRejected,
  effect: handleApiErrors('Unable to create Community'),
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.removeCommunityMember.matchRejected,
  effect: handleApiErrors('Unable to remove Community Member'),
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.updateCurrentCommunityMember.matchRejected,
  effect: handleApiErrors('Unable to update user data'),
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.updateMySocials.matchRejected,
  effect: handleApiErrors('Unable to update social media'),
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.toggleCommunityAdmin.matchRejected,
  effect: handleApiErrors('Unable to update Community Member role'),
});

communityApiListener.startListening({
  actionCreator: communityActions.communityAccount.memberSetupRequired,
  effect: (_args, { dispatch, getState }) => {
    const redirectUrl = selectPathname(getState());
    dispatch(push(generateDynamicPath(AppRoutes.MemberSetup, undefined, { redirectUrl })));
  },
});

communityApiListener.startListening({
  actionCreator: communityActions.community.registeredToCommunity,
  effect: registerToCommunitySuccessEffect,
});

// TODO: remove below code and uncomment line 308 from communityApi.ts when BE fixed
communityApiListener.startListening({
  matcher: communityApi.endpoints.submitCronofyCode.matchFulfilled,
  effect: (_args, { dispatch, getState }) => {
    const {
      community: { activeCommunityId },
    } = getState();

    if (activeCommunityId) {
      dispatch(
        communityApi.util.updateQueryData('getCurrentCommunityMember', { communityId: activeCommunityId }, (data) => {
          data.hasCronofyCredentials = true;
        })
      );
    }
  },
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.updateMyCommunity.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    dispatch(appSetThemeColor(meta.arg.originalArgs.brandingColor));
    dispatch(addAppSuccessMessage('Your information has been saved'));
  },
});

communityApiListener.startListening({
  matcher: isAnyOf(
    communityApi.endpoints.updateCurrentCommunityMember.matchFulfilled,
    communityApi.endpoints.updateMySocials.matchFulfilled
  ),
  effect: (_, { dispatch }) => {
    dispatch(addAppSuccessMessage('Your information has been saved'));
  },
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.updateMySocials.matchRejected,
  effect: handleApiErrors('Unexpected error when updating socials'),
});

communityApiListener.startListening({
  matcher: isAnyOf(
    communityApi.endpoints.updateMyCommunity.matchRejected,
    communityApi.endpoints.uploadLogo.matchRejected,
    communityApi.endpoints.updateCommunityBranding.matchRejected
  ),
  effect: handleApiErrors('Unable to update Community'),
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.inviteGuestsToCommunity.matchFulfilled,
  effect: (_, { dispatch }) => {
    dispatch(addAppSuccessMessage('Invitation has been sent'));
  },
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.inviteGuestsToCommunity.matchRejected,
  effect: handleApiErrors('Unable to invite to the Community'),
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.toggleCommunityAdmin.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    const { isPromote, displayName } = meta.arg.originalArgs;
    if (isPromote) {
      dispatch(
        addAppConfirmationDialog({
          title: `${displayName || 'User'} has been successfully assigned as a Community Admin`,
          description: '',
          confirmLabel: 'Got it',
        })
      );
    } else {
      dispatch(
        addAppConfirmationDialog({
          title: `${displayName || 'User'} has been removed as a Community Admin`,
          description: '',
          confirmLabel: 'Got it',
        })
      );
    }
  },
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.removeCommunityMember.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    const { displayName } = meta.arg.originalArgs;
    dispatch(
      addAppConfirmationDialog({
        title: `${displayName || 'User'} has been successfully removed from this Community`,
        description: '',
        confirmLabel: 'Got it',
      })
    );
  },
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.updateCommunityBranding.matchFulfilled,
  effect: (_, { dispatch }) => {
    dispatch(push(AppRoutes.Settings));
  },
});

communityApiListener.startListening({
  matcher: communityApi.endpoints.inviteGuestsToCommunity.matchFulfilled,
  effect: ({ meta }, { dispatch }) => {
    const { guests } = meta.arg.originalArgs;
    dispatch(
      addAppConfirmationDialog({
        title: 'Invitation has been sent',
        description: `You have invited ${getItemsCountLabel('new Member', guests.length)} to your Community.`,
        confirmLabel: 'Understood',
        maxWidth: 'sm',
      })
    );
  },
});
