import { createListenerMiddleware } from '@reduxjs/toolkit';

import { addAppConfirmationDialog } from '../alerts';
import { userMuted_WS } from '../event';
import { setAudioEnabled } from './actions';

export const userListener = createListenerMiddleware();

userListener.startListening({
  actionCreator: userMuted_WS,
  effect: ({ payload: { mutedByName } }, { dispatch }) => {
    dispatch(setAudioEnabled(false));
    dispatch(
      addAppConfirmationDialog({
        title: `You have been muted by ${mutedByName}`,
        description: 'You can unmute yourself by clicking the microphone below.',
        variant: 'confirmation',
        confirmLabel: 'Got it',
      })
    );
  },
});
