import type { Nullable, TagsCategory, Timestamp } from 'domain/Common';

import type { QuestionAssignmentKey } from '../question';
import type { MeetingGuest } from './Meeting';

export interface EventRoomAttendeeApiPayload {
  accountId: string;
  avatarLink: string;
  channelId: string;
  role: string;
  roomId: string;
  tagsCategories: TagsCategory[];
  twilioIdentity: string;
  userCategory: string;
  userId: number;
  userName: string;
}

export enum TranscriptionMode {
  OFF = 'OFF',
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
}

export interface EventRoomApiPayload {
  endTime: Timestamp;
  startTime: Timestamp;
  accessToken: string;
  agoraToken: string;
  id: string;
  attendees?: EventRoomAttendeeApiPayload[];
  questionAssignmentKey?: Nullable<QuestionAssignmentKey>;
  endOfRoomQuestionAssignmentKeys?: QuestionAssignmentKey[];
  hasSetTranscriptionPreference?: boolean;
  transcriptionMode?: TranscriptionMode;
  isTranscribing?: boolean;
}

export interface EventRoomAttendee extends MeetingGuest {
  tagsCategories: TagsCategory[];
}

export interface EventRoomBasicInfo {
  id: string;
  orderNumber: number;
  endTime: Timestamp;
  startTime: Timestamp;
  /** Room duration in minutes */
  duration?: number;
  attendees: EventRoomAttendee[];
  accessToken?: string;
}

export interface EventRoom extends EventRoomBasicInfo {
  questionAssignmentKey?: Nullable<QuestionAssignmentKey>;
  endOfRoomQuestionAssignmentKeys?: QuestionAssignmentKey[];
}
