import React, { FC } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { first } from 'lodash/fp';

import { useInsightsReportContext } from './hooks';

export const InsightReportRouter: FC = () => {
  const { path: rootPath } = useRouteMatch();
  const { routes } = useInsightsReportContext();

  return (
    <Switch>
      {routes.map(({ path, render }) => (
        <Route key={path} path={`${rootPath}${path}`} component={render} />
      ))}

      {/* Redirect to first route when "/" */}
      <Redirect strict from={rootPath} to={`${rootPath}${first(routes)?.path}`} />
    </Switch>
  );
};
