import type { IAgoraRTCError } from 'agora-rtc-sdk-ng';

// TODO: isAgoraRTCError should be replaced with AgoraRTCError instance checker but currently agora-rtc-sdk-ng is not exported
// import { AgoraRTCError } from 'agora-rtc-sdk-ng';
// export function isAgoraRTCError(error: unknown): error is AgoraRTCError {
//   return error instanceof AgoraRTCError;
// }

export function isAgoraRTCError(error: unknown): error is IAgoraRTCError {
  if (error && typeof error === 'object' && Object.prototype.hasOwnProperty.call(error, 'code')) {
    return true;
  }
  console.error(error);
  return false;
}
