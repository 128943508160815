import React, { FC, useMemo } from 'react';
import { matchPath, useLocation, useRouteMatch } from 'react-router-dom';

import { usePinContext } from 'common/guards/PinGuard/PinGuardContext';
import { eventKeyMetrics } from 'pages/EventReport';
import { InsightsReportContext, InsightsReportContextValue } from 'pages/InsightsReport';
import { BasicInsightsReportRoutes } from 'pages/InsightsReport/constants';
import { useCurrentReportId } from 'pages/InsightsReport/hooks';
import {
  useGetPublicEventsReportByIdQuery,
  useGetPublicEventsReportDemographicsQuery,
  useGetPublicEventsReportKeyMetricsQuery,
} from 'store/apis/publicEventsReport';

import { PublicEventReportRoutes } from './constants';

export const PublicEventReportProvider: FC = ({ children }) => {
  const { reportId, isInvalidReportId } = useCurrentReportId();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const pin = usePinContext();
  const shouldSkip = !pin;

  const {
    data: reportData,
    isError,
    isLoading,
  } = useGetPublicEventsReportByIdQuery({ reportId, pin }, { skip: shouldSkip });

  const { data: keyMetricsApiData, isLoading: isKeyMetricsDataLoading } = useGetPublicEventsReportKeyMetricsQuery(
    { reportId, pin },
    { skip: shouldSkip }
  );

  const { data: demographicData } = useGetPublicEventsReportDemographicsQuery({ reportId, pin }, { skip: shouldSkip });

  const shouldShowFilters = useMemo(
    () => !matchPath(pathname, path + BasicInsightsReportRoutes.keyMetricsAndParticipantDemographics.path),
    [pathname, path]
  );

  const keyMetricsData = useMemo(
    () =>
      eventKeyMetrics.map((metric) => ({
        ...metric,
        value: keyMetricsApiData?.[metric.key],
      })),
    [keyMetricsApiData]
  );

  const value: InsightsReportContextValue = useMemo(
    () => ({
      reportType: 'Event',
      isPublicReport: true,
      demographicData,
      isError: isInvalidReportId || isError,
      isLoading,
      reportData,
      routes: PublicEventReportRoutes,
      isDeleting: false,
      isReportExported: false,
      keyMetricsData,
      isKeyMetricsDataLoading,
      shouldShowFilters,
      shouldShowMoreMenu: false,
    }),
    [
      isError,
      isInvalidReportId,
      isLoading,
      demographicData,
      reportData,
      shouldShowFilters,
      keyMetricsData,
      isKeyMetricsDataLoading,
    ]
  );

  return <InsightsReportContext.Provider value={value}>{children}</InsightsReportContext.Provider>;
};
