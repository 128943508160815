import React, { FC } from 'react';

import { faUserHardHat } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';

import { usePageInProgressStyles } from './PageInProgress.styles';

type Props = {
  title?: string;
};

export const PageInProgress: FC<Props> = ({ title = 'Page under construction' }) => {
  const classes = usePageInProgressStyles();
  const description = 'This page will be implemented in the future';

  return (
    <div className={classes.container}>
      <FontAwesomeIcon className={classes.icon} icon={faUserHardHat} size="4x" />
      <Typography variant="h4">{title}</Typography>
      <Typography variant="h6">{description}</Typography>
    </div>
  );
};
