import { ERROR_MESSAGES } from 'common/constants';
import { InferType, array, boolean, number, object, string } from 'yup';

const themeSchema = object({
  theme: string().required(ERROR_MESSAGES.noEmpty),
  themeId: number().required(),
  isHidden: boolean().default(false),
});

export const hideThemesFormSchema = object({
  themes: array()
    .of(themeSchema)
    .default([])
    .test('hiddenThemes', 'At least one theme must remain visible', (themes) =>
      themes.some((theme) => !theme.isHidden)
    ),
});

export type ThemeFormField = InferType<typeof themeSchema>;
export type HideThemesFormValues = InferType<typeof hideThemesFormSchema>;
