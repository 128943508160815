import React, { FC } from 'react';

import InsightsReportPage from 'pages/InsightsReport';

import { EventReportProvider } from './EventReportProvider';

export const EventReport: FC = () => (
  <EventReportProvider>
    <InsightsReportPage />
  </EventReportProvider>
);
