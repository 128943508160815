export const darkBlue = '#091E42';

export const grey = {
  25: '#FCFCFD',
  50: '#F9FAFB',
  100: '#F2F4F7',
  200: '#EAECF0',
  300: '#D0D5DD',
  400: '#98A2B3',
  500: '#667085',
  600: '#475467',
  700: '#344054',
  800: '#1D2939',
  900: '#101828',
};

export const blue = {
  25: '#F5FAFF',
  50: '#EFF8FF',
  100: '#D1E9FF',
  200: '#B2DDFF',
  300: '#84CAFF',
  400: '#53B1FD',
  500: '#2E90FA',
  600: '#1570EF',
  700: '#175CD3',
  800: '#1849A9',
  900: '#194185',
};

export const green = {
  50: '#ECFDF3',
  400: '#32D583',
  500: '#12B76A',
  600: '#039855',
  700: '#027A48',
  800: '#05603A',
};

export const red = {
  50: '#FEF3F2',
  300: '#FDA29B',
  500: '#F04438',
  600: '#D92D20',
  700: '#B42318',
  800: '#912018',
};

export const purple = {
  50: '#F3F0FF',
  800: '#5E4DB2',
};

export const orange = {
  50: '#FFFAEB',
  300: '#FEC84B',
  600: '#DC6803',
  800: '#93370D',
};
